import React, {Fragment} from 'react';
import styled from 'styled-components';

import ExtrasList, {StyledButton} from '../ExtrasList/ExtrasList';
import ActionButtons from '../ActionButtons';
import PackagesCard from 'view/venue/NW2BookingPreview/components/Space/components/PackagesCard';
import Packages from 'view/venue/NW2BookingPreview/components/Space/components/Packages';
import {useAppSelector} from 'store/hooks';
import useToggle from 'hooks/useToggle';

import {getPackageExtrasDescriptions} from 'view/venue/NW2BookingPreview/helpers';
import {EResourcesType, IExtrasResponse} from 'types/dto/IExtras.type';
import {IExtraState, ISummaryExtra} from 'types/dto/IBooking.types';
import {IPackageResponse} from 'types/dto/IPackages.type';
import {lgBp, NW2Gray50Color, offsetXLg} from 'styles/configs';
import {ExtrasWrapper, UnitHead} from '../Space.styles';
import {TChangeExtraQuantity} from '../../types';

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: ${offsetXLg} 0;

  @media (min-width: ${lgBp}px) {
    max-width: 90%;
  }

  & > span {
    width: 2px;
    height: 2px;
    margin: 0 6px;
    border-radius: 1px;
    background-color: black;
  }
`;

interface IProps {
  isMultiDay: boolean;
  isSpaceEditMode: boolean;
  chosenExtras: ISummaryExtra[];
  availableEquipments: IExtrasResponse[];
  dayIndex: number;
  unitId: number;
  spaceSaveChangesButtonHandler: () => void;
  spaceCancelEditButtonHandler: () => void;
  isPresetChanged: boolean;
  onEquipmentChangeQuantity: TChangeExtraQuantity;
  equipmentsState: IExtraState[];
  customerBookedPackage?: IPackageResponse;
  participants: number;
}

const Equipments = ({
  isSpaceEditMode,
  chosenExtras,
  availableEquipments,
  spaceSaveChangesButtonHandler,
  spaceCancelEditButtonHandler,
  isPresetChanged,
  onEquipmentChangeQuantity,
  equipmentsState,
  customerBookedPackage,
  participants,
  dayIndex,
  unitId,
}: IProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const [showAdditionalEquipment, setShowAdditionalEquipment] = useToggle();
  const bookedEquipmentList = chosenExtras.filter(
    (extra) => extra.extraType === EResourcesType.EQUIPMENT,
  );
  const packageFoodAndBeverage = getPackageExtrasDescriptions(
    customerBookedPackage,
    'catering',
  );
  const packageEquipment = getPackageExtrasDescriptions(
    customerBookedPackage,
    'equipment',
  );
  const initMaxToShowEquipment = 5;

  const addedAdditionalEquipment = (
    <List>
      {bookedEquipmentList.map((item, index, arr) => (
        <Fragment key={item.accommodationExtraId}>
          <div>{item.extraName}</div>
          {index !== arr.length - 1 && <span />}
        </Fragment>
      ))}
    </List>
  );

  const editableListOfEquipment = (
    <ExtrasList
      extrasState={equipmentsState}
      chosenExtras={chosenExtras}
      availableExtras={availableEquipments}
      initMaxToShow={initMaxToShowEquipment}
      onExtraChangeQuantity={onEquipmentChangeQuantity}
    />
  );

  const editableEquipmentWithPackage = (
    <>
      <UnitHead>
        {showAdditionalEquipment ? (
          editableListOfEquipment
        ) : (
          <>
            {addedAdditionalEquipment}
            <StyledButton
              size='responsiveAdaptive'
              buttonType='tertiary'
              onClick={setShowAdditionalEquipment}
              fullWidth={isMobile}
            >
              ADD ADDITIONAL EQUIPMENT
            </StyledButton>
          </>
        )}
      </UnitHead>
    </>
  );

  const editableEquipment = customerBookedPackage
    ? Boolean(availableEquipments.length) && editableEquipmentWithPackage
    : editableListOfEquipment;

  return (
    <ExtrasWrapper>
      {isSpaceEditMode ? (
        <>
          {customerBookedPackage && (
            <Packages
              unitId={unitId}
              dayIndex={dayIndex}
              participants={participants}
              isSpaceEditMode={isSpaceEditMode}
              customerBookedPackage={customerBookedPackage}
            />
          )}
          {editableEquipment}
          <ActionButtons
            noTopOffset={customerBookedPackage && !showAdditionalEquipment}
            isSaveButtonDisabled={!isPresetChanged}
            onSaveHandler={spaceSaveChangesButtonHandler}
            onCancelHandler={spaceCancelEditButtonHandler}
          />
        </>
      ) : (
        <>
          {customerBookedPackage && (
            <PackagesCard
              equipment={packageEquipment}
              foodAndBeverage={packageFoodAndBeverage}
              backgroundColor={NW2Gray50Color}
              isSupplierView
            />
          )}
          {addedAdditionalEquipment}
        </>
      )}
    </ExtrasWrapper>
  );
};

export default Equipments;
