import React from 'react';
import {DateTime} from 'luxon';

import NW2Button from 'view/components/NW2Button';

import {
  Actions,
  ReceivedOn,
  ReceivedOnRow,
  StyledContainer,
  TimeBox,
  TimeLeft,
  TimeLeftText,
  TimeLeftTitle,
  Wrapper,
} from './OfferReviewFooter.styles';
import {getRespondDaysAndHours} from 'utils/helpers';

interface IProps {
  respondDay: DateTime;
  receivedDate: string;
  respondDate: string;
  isLoading: boolean;
  onConfirm: () => void;
  onDecline: () => void;
  hideButtons?: boolean;
  isSubmitting?: boolean;
}

export const OfferReviewFooter = ({
  respondDay,
  receivedDate,
  respondDate,
  isLoading,
  onConfirm,
  onDecline,
  hideButtons,
  isSubmitting,
}: IProps) => {
  const {showDays, showHours} = getRespondDaysAndHours(respondDay);

  return (
    <Wrapper>
      <StyledContainer>
        <TimeBox>
          <TimeLeft>
            <TimeLeftTitle>Time left to respond:</TimeLeftTitle>
            <TimeLeftText>
              {showDays} {showHours}
            </TimeLeftText>
          </TimeLeft>

          <ReceivedOn>
            <ReceivedOnRow>
              <b>Received on:</b>
              <span>{receivedDate}</span>
            </ReceivedOnRow>
            <ReceivedOnRow>
              <b>Respond by:</b>
              <span>{respondDate}</span>
            </ReceivedOnRow>
          </ReceivedOn>
        </TimeBox>

        {!hideButtons && (
          <Actions>
            <NW2Button onClick={onDecline}>decline</NW2Button>
            <NW2Button
              buttonType='primary'
              disabled={isSubmitting}
              onClick={onConfirm}
              loading={isLoading}
            >
              {isSubmitting
                ? 'sending...'
                : isLoading
                ? 'loading...'
                : 'confirm & book'}
            </NW2Button>
          </Actions>
        )}
      </StyledContainer>
    </Wrapper>
  );
};
