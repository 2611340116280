import styled from 'styled-components';

import {StyledDropdown} from '../NW2SearchDatePicker/NW2SearchDatePicker.styles';

import {mdBp, NW2Gray200Color, offsetDef, offsetXLg} from 'constants/styleVars';

export const GroupsDateContainer = styled.div`
  @media (max-width: ${mdBp - 1}px) {
    width: 100%;
  }
`;

export const GroupsDateBox = styled.div`
  border-bottom: 1px solid ${NW2Gray200Color};
  padding: 0 0 ${offsetDef};

  @media (min-width: ${mdBp}px) {
    padding: 0 ${offsetXLg};
    border-left: 1px solid ${NW2Gray200Color};
    border-right: 1px solid ${NW2Gray200Color};
    border-bottom: 0;
  }
`;

export const DropdownWrapper = styled(StyledDropdown)`
  width: 610px;

  .react-datepicker {
    &__month-container {
      &:first-of-type {
        margin-right: ${offsetDef};
      }
    }
  }
`;
