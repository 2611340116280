import React from 'react';
import styled from 'styled-components';

import {StyledSpan} from 'view/components/Typography';

import {
  fontSizeXXSm,
  fontWeightExtraBold,
  offsetDef,
  offsetSm,
  offsetXXSm,
} from 'constants/styleVars';

const MeetingRoomContainer = styled.div`
  & + & {
    margin-top: ${offsetDef};
  }
`;

const Title = styled((props: any) => (
  <StyledSpan
    fontSize={fontSizeXXSm}
    fontWeight={fontWeightExtraBold}
    inline
    {...props}
  />
))`
  text-transform: uppercase;

  * + & {
    margin-top: ${offsetDef};
  }
`;

const ExtraDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  display: grid;
  grid-gap: ${offsetXXSm};
  margin-top: ${offsetXXSm};
  & + & {
    margin-top: ${offsetSm};
  }
`;

export {Body, ExtraDiv, Title, MeetingRoomContainer};
