import React, {SyntheticEvent} from 'react';

import {MenuItem} from './MenuItem';

import {ISection} from '../SupplierLayout.types';
import {Container, MenuList, Title, Wrapper} from './SidebarMenu.styles';

interface IProps {
  activeSection: string;
  sections: ISection[];
  title: string;
  handleSectionChange: (arg: string) => void;
}

const SidebarMenu = ({
  activeSection,
  sections,
  title,
  handleSectionChange,
}: IProps) => {
  const handleActiveSection = (e: SyntheticEvent, section: string) => {
    e.preventDefault();
    handleSectionChange(section);
  };

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>

        <MenuList>
          {sections
            .filter(({isShown}) => isShown)
            .map(({title, href}) => (
              <MenuItem
                itemName={title}
                itemHref={href}
                key={`sidebar-link-${title}`}
                data-testid={`sidebar-link-${title}`}
                isActive={title === activeSection}
                handleClick={(e) => handleActiveSection(e, title)}
              />
            ))}
        </MenuList>
      </Container>
    </Wrapper>
  );
};

export default SidebarMenu;
