import React, {memo} from 'react';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {
  EPointsOfInterest,
  IPointOfInterest,
  pointsOfInterestMap,
} from 'types/venue';
import {TFormObject} from '../../types';
import {usePointsOfInterestFormItem} from 'view/venue/hooks/usePointsOfInterestFormItem';
import {
  HorizontalDivider,
  StyledInputNumber,
  Wrapper,
} from '../../PointsOfInterest.styles';
import {
  InputGroupContainer,
  InputGroup,
  InputGroupDistance,
  InputGroupSeparator,
} from './PointsOfInterestForm.styles';

interface IItemProps {
  type: EPointsOfInterest;
  data: IPointOfInterest[];
  isLastItem: boolean;
  hasError: boolean;
}

const PointsOfInterestFormItem = memo(
  ({hasError, isLastItem, data, type}: IItemProps) => {
    const {
      kmValue,
      miValue,
      onDistanceChangeKm,
      onDistanceChangeMi,
      handleFormFieldName,
    } = usePointsOfInterestFormItem({data, type});

    const label = pointsOfInterestMap[type];

    return (
      <InputGroupContainer>
        <InputGroup>
          <NW2FormItemInput
            name={`${type}.name`}
            type='text'
            label={label}
            placeholder={label}
            onChange={handleFormFieldName}
            showAllValidationErrors
            data-testid={`poi-input-${type}`}
          />

          <InputGroupDistance hasError={hasError}>
            <StyledInputNumber
              value={kmValue}
              defaultValue={0}
              min={0}
              max={999}
              onChange={onDistanceChangeKm as any}
              data-testid={`poi-input-${type}-km`}
              prefix='Kilometers'
            />

            <InputGroupSeparator>=</InputGroupSeparator>
            <StyledInputNumber
              value={miValue}
              defaultValue={0}
              min={0}
              max={999}
              onChange={onDistanceChangeMi as any}
              data-testid={`poi-input-${type}-mi`}
              prefix='Miles'
            />
          </InputGroupDistance>
        </InputGroup>

        {!isLastItem && <HorizontalDivider />}
      </InputGroupContainer>
    );
  },
);

interface IFormProps {
  values: TFormObject;
  errors: TFormObject | undefined;
  active?: string;
  touched?: {[key: string]: boolean} | undefined;
}

export function PointsOfInterestForm({
  values,
  errors,
  active,
  touched,
}: IFormProps) {
  const poiData = Object.values(values);

  return (
    <Wrapper>
      {Object.values(EPointsOfInterest).map((item, index, array) => {
        const isLastItem = array.length === index + 1;

        const activeWithError = !!errors?.[item] && active === `${item}.name`;
        const touchedWithError = !!errors?.[item] && touched?.[`${item}.name`];
        const hasError = activeWithError || !!touchedWithError;

        return (
          <PointsOfInterestFormItem
            key={item}
            type={item}
            data={poiData}
            hasError={hasError}
            isLastItem={isLastItem}
          />
        );
      })}
    </Wrapper>
  );
}
