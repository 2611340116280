import {EBookAbility} from 'types/venue';

export enum ESpacesPages {
  MEETING_ROOM = 'Meeting rooms',
  BEDROOM = 'Bedrooms',
  WORK_SPACE = 'Work desks',
}

// Should be the same order as in ERoomType
export enum ESpacesHashes {
  WORK_SPACE = 'desk',
  MEETING_ROOM = 'room',
  BEDROOM = 'bedroom',
}

export enum ERoomAmenitiesCategories {
  CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT = 'Equipped with',
  CORPORATE_OFFICE_MEETING_ROOM = 'Conditions',
  CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY = 'Accessibility',
}

export enum EDesksAmenitiesCategories {
  CORPORATE_OFFICE_WORK_DESK_EQUIPMENT = 'Equipped with',
  CORPORATE_OFFICE_WORK_DESK = 'Conditions',
  CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY = 'Accessibility',
}

export const allAmenitiesCategories = {
  ...ERoomAmenitiesCategories,
  ...EDesksAmenitiesCategories,
};

export type TRoomAmenitiesCategories = keyof typeof allAmenitiesCategories;

export const bookabilityLabels = {
  [EBookAbility.INSTANT]: 'Instant booking',
  [EBookAbility.REQUEST]: 'Request-to-book',
};
