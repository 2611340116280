import React from 'react';

import {requiredFieldRules} from 'utils/finalFormFieldRules';
import {SearchWithAutocomplete} from './components';
import {COUNTRIES} from 'constants/countries';
import {IInputFieldProps} from 'view/components/NW2FormItem/types';

import {
  AddressContainer,
  StyledNW2FormItemInput,
  BillingAddressContainer,
  BillingAddressInnerBlock,
  StyledNW2FormItemSelect,
} from '../BillingAddress.styles';

const countryOptions = COUNTRIES.map((item) => ({
  key: item,
  value: item,
  text: item,
}));

const FormItemGroup: IInputFieldProps[] = [
  {
    name: 'companyName',
    type: 'text',
    label: 'Company name',
    placeholder: 'Company name',
  },
  {
    name: 'streetHouseNumber',
    type: 'text',
    label: 'Street and house number*',
    placeholder: 'Street and house number*',
    rules: requiredFieldRules('street and house number'),
    showAllValidationErrors: true,
  },
  {
    name: 'postCode',
    type: 'text',
    label: 'Post code*',
    placeholder: 'Post code*',
    rules: requiredFieldRules('post code'),
    showAllValidationErrors: true,
  },
  {
    name: 'city',
    type: 'text',
    label: 'City*',
    placeholder: 'City*',
    rules: requiredFieldRules('city'),
    showAllValidationErrors: true,
  },
];

type TProps = {
  hideReferenceField?: boolean; // Reference field is needed only when booking a venue
  // By default mobile and tablet layouts are the same, but sometimes (e.g. in modals)
  // we want tablet and desktop layouts be the same.
  useDesktopLayoutOnTablet?: boolean;
};

function BillingAddressForm({
  hideReferenceField = false,
  useDesktopLayoutOnTablet = false,
}: TProps) {
  return (
    <BillingAddressContainer>
      <SearchWithAutocomplete />
      <AddressContainer useDesktopLayoutOnTablet={useDesktopLayoutOnTablet}>
        {FormItemGroup.map(
          ({
            name,
            type,
            label,
            placeholder,
            rules,
            showAllValidationErrors,
          }) => (
            <StyledNW2FormItemInput
              key={name}
              name={name}
              type={type}
              label={label}
              placeholder={placeholder}
              rules={rules}
              showAllValidationErrors={showAllValidationErrors}
            />
          ),
        )}

        <StyledNW2FormItemSelect
          name='country'
          label='Country*'
          placeholder='Country*'
          rules={requiredFieldRules('country')}
          showAllValidationErrors
          options={countryOptions}
          isCapitalLetterInOptions
        />
      </AddressContainer>

      <BillingAddressInnerBlock>
        <StyledNW2FormItemInput
          name='costCenter'
          type='text'
          label='Cost centre'
          placeholder='Cost centre'
        />
        {!hideReferenceField && (
          <StyledNW2FormItemInput
            name='additionalReference'
            type='text'
            label='Additional reference'
            placeholder='Additional reference'
          />
        )}
      </BillingAddressInnerBlock>
    </BillingAddressContainer>
  );
}

export default BillingAddressForm;
