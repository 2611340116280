import React, {useMemo, useRef, useState} from 'react';
import _get from 'lodash/get';
import {Form} from 'react-final-form';
import _isEqual from 'lodash/isEqual';

import PointsOfInterestList from './components/PointsOfInterestList/PointsOfInterestList';
import NMMSection from 'view/components/NMMSection';
import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';
import {PointsOfInterestForm} from './components/PointsOfInterestForm/PointsOfInterestForm';

import {RootState} from 'store/types';
import {IPointOfInterest, IVenue} from 'types/venue';
import {
  PATH_TO_REDUCER_VENUE_DATA,
  PATH_TO_REDUCER_VENUE_POI_DATA,
} from 'constants/venue';
import {
  clearVenuePointsOfInterest,
  setVenuePointsOfInterest,
} from 'store/venue/actions';
import useUpdateVenue from 'view/venue/hooks/useUpdateVenue';
import {EPointsOfInterestMode, TFormObject} from './types';
import {EVenueProfileSections} from '../../types';
import {useAppDispatch, useAppSelector} from 'store/hooks';

interface IProps {
  venue: IVenue;
  isExternalVenue: boolean;
}
const PointsOfInterestWrapper = ({venue, isExternalVenue}: IProps) => {
  const dispatch = useAppDispatch();

  const poiData: IPointOfInterest[] = useAppSelector((state: RootState) =>
    _get(
      state,
      `${PATH_TO_REDUCER_VENUE_DATA}.${PATH_TO_REDUCER_VENUE_POI_DATA}`,
    ),
  );

  const [mode, setMode] = useState<EPointsOfInterestMode>(
    EPointsOfInterestMode.VIEW,
  );

  const changeToEditMode = () => setMode(EPointsOfInterestMode.EDIT);
  const changeToViewMode = () => setMode(EPointsOfInterestMode.VIEW);

  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: changeToViewMode,
  });

  const formPoiData: TFormObject = useMemo(
    () =>
      poiData?.reduce((acc, object) => {
        return {
          ...acc,
          [object.type]: {
            ...object,
          },
        };
      }, {} as TFormObject),
    [poiData],
  );

  const propsPoiData = useRef<TFormObject>(formPoiData).current;

  const disableSave = _isEqual(propsPoiData, formPoiData);

  const handleCancelClick = () => {
    if (propsPoiData) {
      Object.values(propsPoiData).map(({distance, name, type, description}) => {
        dispatch(
          setVenuePointsOfInterest({
            key: type,
            value: {
              distance,
              name,
              type,
              description,
            },
          }),
        );
      });
    }

    dispatch(clearVenuePointsOfInterest());
    changeToViewMode();
  };

  const handleConfirmClick = async (formValues: TFormObject) =>
    await updateVenue({pointsOfInterest: Object.values(formValues)});

  return (
    <NMMSection
      id={EVenueProfileSections.POINTS_OF_INTEREST}
      title='Points of interest'
      isEditOn={mode === EPointsOfInterestMode.EDIT}
      onEdit={changeToEditMode}
    >
      {mode === EPointsOfInterestMode.EDIT ? (
        <Form onSubmit={handleConfirmClick} initialValues={formPoiData}>
          {({handleSubmit, values, errors, touched}) => (
            <form onSubmit={handleSubmit} noValidate>
              <PointsOfInterestForm
                values={values}
                touched={touched}
                errors={errors as TFormObject}
              />

              <NMMSubmitSection
                isLoading={isSending}
                handleCancel={handleCancelClick}
                disabled={disableSave}
                dataTestCancelId='poi-cancel-button'
                dataTestSubmitId='poi-confirm-button'
              />
            </form>
          )}
        </Form>
      ) : (
        <PointsOfInterestList poiFormData={formPoiData} />
      )}
    </NMMSection>
  );
};

export default PointsOfInterestWrapper;
