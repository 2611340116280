import React from 'react';
import styled from 'styled-components';

import {TextValue} from 'view/components/TextValue';
import {Link} from 'view/components/Typography/Typography';
import {venuesContainer} from 'app';

import {
  fontSizeMd,
  lineHeightLg,
  offsetDef,
  offsetXXLg,
} from 'constants/styleVars';
import {notification} from 'antd';
import {
  getCancellationPolicyLinkUrlById,
  getTermsAndConditionLinkUrlById,
} from 'utils/helpers';
import {CANCELLATION_POLICY_BLOCK_ID} from '../../components/CancellationPolicyBlock/CancellationPolicyBlock';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXXLg};
`;

const commonProps = {
  marginTop: '0',
  fontSize: fontSizeMd,
  lineHeight: lineHeightLg,
};

interface IProps {
  termsLinkId?: string | null;
  customerCancellationPolicyId?: string | null;
  isUSA?: boolean;
}

export function BookingTermsAndConditions({
  termsLinkId,
  customerCancellationPolicyId,
  isUSA = false,
}: IProps) {
  //fetches pdf file and opens it in new tab, or shows error without opening new tab
  const handleLinkClick = async (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();

    await venuesContainer.getTermsAndConditions({
      payload: termsLinkId,
      onSuccess: () => {
        window.open(
          getTermsAndConditionLinkUrlById(termsLinkId as string),
          '_blank',
          'noopener,noreferrer',
        );
      },
      onError(error: any) {
        if (error.status !== 404) {
          notification.error({
            message: error?.data?.detail,
            duration: 4,
          });
        }
      },
    });
  };

  return (
    <Wrapper id={CANCELLATION_POLICY_BLOCK_ID}>
      <div>
        <TextValue marginBottom={offsetDef} bold {...commonProps}>
          Modification and Cancellation Conditions
        </TextValue>
        <TextValue {...commonProps}>
          {isUSA || !customerCancellationPolicyId ? (
            `The cancellation policy for this request will be defined within the
            offer.`
          ) : (
            <>
              Please review your{' '}
              <Link
                href={getCancellationPolicyLinkUrlById(
                  customerCancellationPolicyId,
                )}
                target='_blank'
              >
                Company’s Cancellation Policy
              </Link>
              .
            </>
          )}
        </TextValue>
      </div>

      {!!termsLinkId && (
        <div>
          <TextValue marginBottom={offsetDef} bold {...commonProps}>
            Company’s Terms and Conditions
          </TextValue>
          <TextValue {...commonProps}>
            Please review your{' '}
            <Link onClick={handleLinkClick}>
              Company’s Terms and Conditions
            </Link>
            .
          </TextValue>
        </div>
      )}
    </Wrapper>
  );
}
