import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import {IVenuePhotos, VenueTypes} from './types';
import {
  ELegalEntityType,
  ESpaceSelectedTab,
  IAnnouncement,
  IPointOfInterest,
  ISpacesData,
  ISpacesDataItem,
  IVenue,
  IVenueCurrencies,
  TVenueBedroomsResponse,
  TVenueCharacterType,
  TVenueType,
} from 'types/venue';

import {
  ADD_EXTRA_ITEM_SUCCESS,
  CLEAR_SPACE_DELETION_STATUS,
  CLEAR_VENUE_DATA,
  CLEAR_VENUE_POINTS_OF_INTEREST,
  DELETE_SPACE_FAILURE,
  DELETE_SPACE_REQUEST,
  EDIT_SPACE_FAILURE,
  EDIT_SPACE_REQUEST,
  GET_AMENITIES_FAILURE,
  GET_AMENITIES_REQUEST,
  GET_AMENITIES_SUCCESS,
  GET_ANNOUNCEMENT_SUCCESS,
  GET_EXTRAS_OPTION_FAILURE,
  GET_EXTRAS_OPTION_REQUEST,
  GET_EXTRAS_OPTION_SUCCESS,
  GET_SPACE_DELETION_STATUS_FAILURE,
  GET_SPACE_DELETION_STATUS_REQUEST,
  GET_SPACE_DELETION_STATUS_SUCCESS,
  GET_SPACES_FAILURE,
  GET_SPACES_REQUEST,
  GET_SPACES_SUCCESS,
  GET_VENUE_CHARACTERS_FAILURE,
  GET_AVAILABLE_EXTRAS_REQUEST,
  GET_AVAILABLE_EXTRAS_SUCCESS,
  GET_AVAILABLE_EXTRAS_FAILURE,
  GET_VENUE_CHARACTERS_REQUEST,
  GET_VENUE_CHARACTERS_SUCCESS,
  GET_VENUE_CURRENCIES_FAILURE,
  GET_VENUE_CURRENCIES_REQUEST,
  GET_VENUE_CURRENCIES_SUCCESS,
  GET_VENUE_TYPES_FAILURE,
  GET_VENUE_TYPES_REQUEST,
  GET_VENUE_TYPES_SUCCESS,
  GET_WORK_IN_PROGRESS_FAILURE,
  GET_WORK_IN_PROGRESS_REQUEST,
  GET_WORK_IN_PROGRESS_SUCCESS,
  REMOVE_VENUE_PHOTO_ERROR,
  REMOVE_VENUE_PHOTO_FROM_LIST,
  SET_COVER_VENUE_PHOTO,
  SET_CREATE_VENUE_STEP,
  SET_IS_VENUE_INFORMATION_CONFIRMED,
  SET_LOADING,
  SET_SAME_LEGAL_CONTACT_VENUE_FIELDS,
  SET_SPACE_FAILURE,
  SET_SPACE_REQUEST,
  SET_SPACE_SELECTED_TAB,
  SET_SPACE_SUCCESS,
  SET_VENUE,
  SET_VENUE_ALREADY_EXISTS,
  SET_VENUE_AMENITY,
  SET_VENUE_ENTITY_TYPE,
  SET_VENUE_FIELDS,
  SET_VENUE_IMAGES_LIST,
  SET_VENUE_IMAGES_LIST_FROM_RESPONSE,
  SET_VENUE_PHOTO_ERROR,
  SET_VENUE_PHOTO_TO_LIST,
  SET_VENUE_POINTS_OF_INTEREST,
  UPDATE_EXTRA_SUCCESS,
  GET_BEDROOMS_SUCCESS,
  GET_BEDROOMS_LOADING,
} from './constants';
import {IAmenity} from 'types/dto/IAmenity';
import {IExtrasOption, IExtrasResponse} from 'types/dto/IExtras.type';
import {EMPTY_ARRAY} from 'constants/app';
import {PATH_TO_REDUCER_VENUE_POI_DATA} from 'constants/venue';
import {amenityCategoryFromKey} from 'utils/helpers';
import {ESpaceDeletionStatuses} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/NMMSpacesDetails/components/AdditionalActions/types';
import {
  deskAmenitiesSet,
  roomAmenitiesSet,
  venueAmenitiesSet,
} from 'types/amenities';

interface IVenueReducer {
  createVenueStep: number;
  isVenueInformationConfirmed: boolean;
  venueTypes: TVenueType[];
  venueCharacters: TVenueCharacterType[];
  venueCurrencies: IVenueCurrencies | null;
  legalEntityType: ELegalEntityType;
  venue: IVenue | null;
  venuePhotos: IVenuePhotos;
  spaces: ISpacesData[] | null;
  bedrooms: TVenueBedroomsResponse | null;
  bedroomsLoading: boolean;
  workInProgress: ISpacesDataItem[];
  spaceSelectedTab: ESpaceSelectedTab | null;
  amenities: IAmenity[];
  roomAmenities: IAmenity[];
  deskAmenities: IAmenity[];
  error: any;
  extrasError: any;
  loading: boolean;
  amenitiesLoading: boolean;
  setSpaceLoading: boolean;
  resourcesFormLoading: boolean;
  extrasOption: IExtrasOption[];
  isVenueAlreadyExists: boolean;
  spaceDeletionStatus: ESpaceDeletionStatuses | null;
  deleteSpaceLoading: boolean;
  announcements: IAnnouncement[];
}

const initialVenuePhotos = {
  coverPhoto: '',
  photoList: [],
  deletedVenuePhotoIds: [],
  errors: null,
};

const initialState: IVenueReducer = {
  amenities: [], // list of available venue amenities
  roomAmenities: [], // list of available room amenities
  deskAmenities: [], // list of available desk amenities
  venueTypes: [],
  venueCharacters: [],
  venueCurrencies: null,
  createVenueStep: 0,
  loading: false,
  amenitiesLoading: false,
  setSpaceLoading: false,
  error: null,
  extrasError: null,
  isVenueInformationConfirmed: false,
  legalEntityType: ELegalEntityType.BUSINESS,
  venue: null,
  venuePhotos: initialVenuePhotos,
  spaces: null,
  bedrooms: null,
  bedroomsLoading: false,
  workInProgress: [],
  spaceSelectedTab: null,
  resourcesFormLoading: false,
  extrasOption: [],
  isVenueAlreadyExists: false,
  spaceDeletionStatus: null,
  deleteSpaceLoading: false,
  announcements: [],
};

const venueReducer = (
  state: IVenueReducer = initialState,
  action: VenueTypes,
) => {
  switch (action.type) {
    case CLEAR_VENUE_DATA: {
      return initialState;
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case SET_SPACE_SELECTED_TAB: {
      return {
        ...state,
        spaceSelectedTab: action.payload,
      };
    }

    case SET_CREATE_VENUE_STEP: {
      return {
        ...state,
        createVenueStep: action.payload,
      };
    }

    case SET_SPACE_REQUEST:
      return {
        ...state,
        setSpaceLoading: true,
        error: null,
      };

    case SET_SPACE_SUCCESS: {
      const getNewSpaces = (spacesFromStore: ISpacesData[], newSpace: any) => {
        const spacesToUpdate = spacesFromStore.find(
          (item: ISpacesData) => item.floorNumber === newSpace.floor,
        );

        if (!spacesToUpdate) {
          return [
            ...spacesFromStore,
            {
              floorNumber: newSpace.floor,
              units: [newSpace],
            },
          ];
        }

        const spacesIndexToUpdate = spacesFromStore.findIndex(
          (item: ISpacesData) => item.floorNumber === newSpace.floor,
        );

        spacesFromStore.splice(spacesIndexToUpdate, 1, {
          floorNumber: newSpace.floor,
          units: [...spacesToUpdate.units, newSpace],
        });

        return spacesFromStore;
      };

      const newSpaces = getNewSpaces(
        state.spaces as ISpacesData[],
        action.payload,
      );

      return {
        ...state,
        spaces: newSpaces,
        setSpaceLoading: false,
        error: null,
      };
    }

    case SET_SPACE_FAILURE:
      return {
        ...state,
        setSpaceLoading: false,
        error: action.payload,
      };

    case EDIT_SPACE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case EDIT_SPACE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SPACES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SPACES_SUCCESS:
      return {
        ...state,
        spaces: action.payload,
        loading: false,
        error: null,
      };

    case GET_SPACES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_BEDROOMS_SUCCESS:
      return {
        ...state,
        bedrooms: action.payload,
        bedroomsLoading: false,
      };

    case GET_BEDROOMS_LOADING:
      return {
        ...state,
        bedroomsLoading: action.payload,
      };

    // WORK IN PROGRESS
    case GET_WORK_IN_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_WORK_IN_PROGRESS_SUCCESS:
      return {
        ...state,
        workInProgress: action.payload,
        loading: false,
        error: null,
      };

    case GET_WORK_IN_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // SPACE DELETION STATUSES

    case GET_SPACE_DELETION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SPACE_DELETION_STATUS_SUCCESS: {
      const newState = _cloneDeep(state);
      _set(newState, 'spaceDeletionStatus', action.payload);

      return {
        ...newState,
        loading: false,
      };
    }

    case GET_SPACE_DELETION_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_SPACE_DELETION_STATUS: {
      return {
        ...state,
        spaceDeletionStatus: null,
      };
    }

    // SPACE DELETE

    case DELETE_SPACE_REQUEST:
      return {
        ...state,
        deleteSpaceLoading: true,
      };

    case DELETE_SPACE_FAILURE:
      return {
        ...state,
        deleteSpaceLoading: false,
      };

    case GET_AMENITIES_REQUEST:
      return {
        ...state,
        amenitiesLoading: true,
        error: null,
      };

    case GET_AMENITIES_FAILURE:
      return {
        ...state,
        amenitiesLoading: false,
        error: action.payload,
      };

    case SET_IS_VENUE_INFORMATION_CONFIRMED: {
      return {
        ...state,
        isVenueInformationConfirmed: action.payload,
      };
    }

    case SET_COVER_VENUE_PHOTO: {
      const newState = _cloneDeep(state);
      _set(newState, 'venuePhotos.coverPhoto', action.payload);
      return newState;
    }

    case SET_VENUE_PHOTO_TO_LIST: {
      const newState = _cloneDeep(state);
      const value = [...state.venuePhotos.photoList, action.payload];
      const sortedPhotoList = _orderBy(value, ['name'], ['asc']);
      _set(newState, 'venuePhotos.photoList', sortedPhotoList);
      return newState;
    }

    case SET_VENUE_IMAGES_LIST: {
      const newState = _cloneDeep(state);
      const value = [...state.venuePhotos.photoList, action.payload];
      _set(newState, 'venuePhotos.photoList', value);
      return newState;
    }

    case SET_VENUE_IMAGES_LIST_FROM_RESPONSE: {
      const newState = _cloneDeep(state);
      const findCoverImage = action.payload.find((el) => el.isCover);
      _set(newState, 'venuePhotos.coverPhoto', findCoverImage?.uid);
      _set(newState, 'venuePhotos.deletedVenuePhotoIds', []);
      _set(newState, 'venuePhotos.photoList', action.payload);
      _set(newState, 'venue.documents', action.payload);
      return newState;
    }

    case REMOVE_VENUE_PHOTO_FROM_LIST: {
      const newState = _cloneDeep(state);
      const value = newState.venuePhotos.photoList.filter(
        (el) => el?.uid !== action.payload,
      );

      _set(newState, 'venuePhotos.photoList', value);
      _set(newState, 'venuePhotos.deletedVenuePhotoIds', [
        ...newState.venuePhotos.deletedVenuePhotoIds,
        action.payload,
      ]);
      return newState;
    }

    case SET_VENUE_PHOTO_ERROR: {
      const newState = _cloneDeep(state);
      const value = newState.venuePhotos.errors
        ? [...newState.venuePhotos.errors, action.payload]
        : [action.payload];
      _set(newState, 'venuePhotos.errors', value);
      return newState;
    }

    case REMOVE_VENUE_PHOTO_ERROR: {
      const newState = _cloneDeep(state);
      const value =
        newState.venuePhotos.errors &&
        newState.venuePhotos.errors.filter((el) => el.uid !== action.payload);
      _set(newState, 'venuePhotos.errors', value);
      return newState;
    }

    case SET_VENUE_FIELDS: {
      const clonedState = _cloneDeep(state);
      const {path, value} = action.payload;
      _set(clonedState, `venue.${path}`, value);
      return clonedState;
    }

    case SET_SAME_LEGAL_CONTACT_VENUE_FIELDS: {
      const clonedState = _cloneDeep(state);
      const {path, value} = action.payload;
      _set(clonedState, `venue.legalContact.${path}`, value);
      return clonedState;
    }

    case SET_VENUE: {
      return {
        ...state,
        venue: action.payload,
      };
    }

    case GET_AMENITIES_SUCCESS: {
      const {payload} = action;
      const {amenities, deskAmenities, roomAmenities} = state;

      return {
        ...state,
        amenitiesLoading: false,
        amenities: amenities.length
          ? amenities
          : payload.filter((amenity: IAmenity) =>
              venueAmenitiesSet.includes(amenity.type),
            ),
        roomAmenities: roomAmenities.length
          ? roomAmenities
          : payload.filter((amenity: IAmenity) =>
              roomAmenitiesSet.includes(amenity.type),
            ),
        deskAmenities: deskAmenities.length
          ? deskAmenities
          : payload.filter((amenity: IAmenity) =>
              deskAmenitiesSet.includes(amenity.type),
            ),
      };
    }

    case SET_VENUE_AMENITY: {
      const {key, value} = action.payload;

      /* Start concat amenities to be sent to server from two arrays */
      const newState = _cloneDeep(state);

      const otherAmenities = _get(state, 'venue.amenities', []).filter(
        (amenity: IAmenity) => amenity.type !== amenityCategoryFromKey(key),
      );
      const amenities = [...value, ...otherAmenities];

      _set(newState, 'venue.amenities', amenities);
      return newState;
    }

    case SET_VENUE_POINTS_OF_INTEREST: {
      const {key, value} = action.payload;
      const statePath = `venue.${PATH_TO_REDUCER_VENUE_POI_DATA}`;

      const newState = _cloneDeep(state);

      const otherPoints = _get(state, statePath, []).filter(
        (poiItem: IPointOfInterest) => poiItem.type !== key,
      );

      if (!value.name) {
        _set(newState, statePath, otherPoints);
        return newState;
      }

      const pointsToStore = [value, ...otherPoints];

      _set(newState, statePath, pointsToStore);
      return newState;
    }

    case CLEAR_VENUE_POINTS_OF_INTEREST: {
      const newState = _cloneDeep(state);

      _set(newState, 'venue.pointsOfInterest', EMPTY_ARRAY);
      return newState;
    }

    case GET_EXTRAS_OPTION_REQUEST:
      return {
        ...state,
        resourcesFormLoading: true,
        error: null,
      };

    case GET_EXTRAS_OPTION_SUCCESS:
      return {
        ...state,
        resourcesFormLoading: false,
        extrasOption: action.payload,
      };

    case GET_EXTRAS_OPTION_FAILURE:
      return {
        ...state,
        resourcesFormLoading: false,
        extrasError: action.payload,
      };

    case GET_AVAILABLE_EXTRAS_REQUEST:
      return {
        ...state,
        resourcesFormLoading: true,
        error: null,
      };

    case GET_AVAILABLE_EXTRAS_SUCCESS: {
      const newState = _cloneDeep(state);
      _set(newState, 'venue.extras', action.payload);
      return {...newState, resourcesFormLoading: false};
    }

    case GET_AVAILABLE_EXTRAS_FAILURE:
      return {
        ...state,
        resourcesFormLoading: false,
        error: action.payload,
      };

    case ADD_EXTRA_ITEM_SUCCESS: {
      const newState = _cloneDeep(state);
      const allExtras: IExtrasResponse[] = _get(newState, 'venue.extras', []);

      _set(newState, 'venue.extras', [...allExtras, action.payload]);

      return {...newState, resourcesFormLoading: false};
    }

    case UPDATE_EXTRA_SUCCESS: {
      const newState = _cloneDeep(state);
      const allExtras: IExtrasResponse[] = _get(newState, 'venue.extras', []);

      const updatedExtras = allExtras.map((item) => {
        if (item.code === action.payload.code) {
          return action.payload;
        }
        return item;
      });

      _set(newState, 'venue.extras', updatedExtras);

      return {...newState, resourcesFormLoading: false};
    }

    case GET_VENUE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_VENUE_TYPES_SUCCESS: {
      const newState = _cloneDeep(state);
      _set(newState, 'venueTypes', action.payload);

      return {
        ...newState,
        loading: false,
      };
    }

    case GET_VENUE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_VENUE_CHARACTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_VENUE_CHARACTERS_SUCCESS: {
      const newState = _cloneDeep(state);
      _set(newState, 'venueCharacters', action.payload);

      return {
        ...newState,
        loading: false,
      };
    }

    case GET_VENUE_CHARACTERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_VENUE_CURRENCIES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case GET_VENUE_CURRENCIES_SUCCESS: {
      return {
        ...state,
        venueCurrencies: action.payload,
        loading: false,
      };
    }

    case GET_VENUE_CURRENCIES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case SET_VENUE_ALREADY_EXISTS: {
      return {
        ...state,
        isVenueAlreadyExists: action.payload,
      };
    }
    case SET_VENUE_ENTITY_TYPE: {
      return {
        ...state,
        legalEntityType: action.payload,
      };
    }

    case GET_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        announcements: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default venueReducer;
