import React from 'react';
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {EmailShareButton, WhatsappShareButton} from 'react-share';
import {notification} from 'antd';

import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon';

import {
  formMaxWidth,
  offsetXXSm,
  offsetXLg,
  offsetSm,
} from 'constants/styleVars';

const Wrapper = styled.div`
  margin-bottom: ${offsetSm};
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: ${formMaxWidth};
  margin: 0 auto ${offsetXLg};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonText = styled.div`
  margin-top: ${offsetXXSm};
`;

type TProps = {
  subject: string;
  text: string;
  url: string;
  closePopup: () => void;
};

const ShareVenueContent = (props: TProps) => {
  const {subject, text, url, closePopup} = props;

  const onCopy = () => {
    const notificationOptions = {
      message: 'Copied.',
      duration: 1,
    };
    notification.success(notificationOptions);
    closePopup();
  };

  const copyText = `${subject} ${text} ${url}`;

  return (
    <Wrapper>
      <Buttons>
        <ButtonContainer>
          <EmailShareButton subject={subject} body={text} url={url}>
            <Icon icon='NW2_SOCIAL_MAIL' />
          </EmailShareButton>
          <ButtonText>Mail</ButtonText>
        </ButtonContainer>
        <ButtonContainer>
          <WhatsappShareButton title={`${subject} ${text}`} url={url}>
            <Icon icon='NW2_SOCIAL_WHATSAPP' />
          </WhatsappShareButton>
          <ButtonText>WhatsApp</ButtonText>
        </ButtonContainer>
      </Buttons>

      {/* @ts-ignore */}
      <CopyToClipboard text={copyText} onCopy={onCopy}>
        <NW2Button buttonType='secondary' fullWidth>
          copy link
        </NW2Button>
      </CopyToClipboard>
    </Wrapper>
  );
};

export default ShareVenueContent;
