import React from 'react';
import styled from 'styled-components';
import {Checkbox, Form, Input} from 'antd';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button/NW2Button';
import {Link, StyledH7} from 'view/components/Typography';

import {
  emailFieldRules,
  subscriptionFieldRules,
} from 'utils/antdFormFieldRules';
import {
  fontSizeXSm,
  fontWeightBold,
  offsetDef,
  offsetXLg,
  xLgBp,
} from 'constants/styleVars';

const {Item} = Form;

const StyledForm = styled(Form)`
  margin-top: ${offsetDef};
  text-align: right;
`;
const StyledCheckbox = styled(Checkbox)`
  font-size: ${fontSizeXSm} !important;
  text-align: left;
`;
const FormFooter = styled.div`
  display: grid;
  justify-items: end;
  grid-gap: ${offsetXLg};

  @media (min-width: ${xLgBp}px) {
    grid-gap: ${offsetDef};
    grid-template-columns: 1fr auto;
    align-items: center;
  }
`;
const StyledItem = styled(Item)`
  margin-bottom: 0;
`;

function PreFooterSubscribeForm() {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const buttonSize = isMobile ? undefined : 'small';

  return (
    <>
      <StyledH7>subscribe for the latest updates</StyledH7>
      <StyledForm>
        <Item name='email' rules={emailFieldRules}>
          <Input placeholder='* E-mail address' />
        </Item>
        <FormFooter>
          <StyledItem name='subscription' rules={subscriptionFieldRules}>
            <Checkbox.Group>
              <StyledCheckbox>
                Yes, I would like to receive the NewWork newsletter at the email
                address given. I have taken note of further information on data
                usage from the{' '}
                <Link
                  href=''
                  target='_blank'
                  rel='noreferrer noopener'
                  fontWeight={fontWeightBold}
                  style={{textDecoration: 'underline'}}
                >
                  data protection declaration.
                </Link>{' '}
                I can withdraw my consent at any time.
              </StyledCheckbox>
            </Checkbox.Group>
          </StyledItem>
          <NW2Button
            type='submit'
            size={buttonSize}
            fullWidth={isMobile}
            inline
          >
            subscribe now
          </NW2Button>
        </FormFooter>
      </StyledForm>
    </>
  );
}

export default PreFooterSubscribeForm;
