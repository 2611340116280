import React, {memo} from 'react';
import styled from 'styled-components';

import TimeRangeText from 'view/components/TimeRangeText';

import DateUtils, {DateFormats} from 'utils/dateUtils';
import {
  fontSizeDefault,
  fontWeightNormal,
  offsetXSm,
  xLgBp,
} from 'constants/styleVars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXSm};
`;

const Subtitle = styled.div`
  font-size: ${fontSizeDefault};
  font-weight: ${fontWeightNormal};
  text-transform: initial;

  @media (max-width: ${xLgBp - 1}px) {
    display: flex;
    justify-content: space-between;
    gap: ${offsetXSm};
  }
`;

interface IProps {
  title: string;
  checkIn: string;
  checkOut?: string;
  hasTimeRange?: boolean;
  participants?: number;
}
function LeftSideItemTitle({
  title,
  checkIn,
  checkOut,
  hasTimeRange,
  participants,
}: IProps) {
  return (
    <Wrapper>
      <span>{title}</span>
      <Subtitle>
        <div>
          {DateUtils.getDateWithoutHours(checkIn, DateFormats['1 Jan 2023'])}
        </div>

        {!!(hasTimeRange && checkOut) && (
          <TimeRangeText checkIn={checkIn} checkOut={checkOut} hasBrackets />
        )}

        {!!participants && (
          <div>
            Participants: <b>{participants}</b>
          </div>
        )}
      </Subtitle>
    </Wrapper>
  );
}

export default memo(LeftSideItemTitle);
