import {notification} from 'antd';
import {Store} from 'redux';
import {CognitoUserSession} from 'amazon-cognito-identity-js';

import tokenService from 'infra/common/token.service';
import {Routes} from 'constants/routes';
import {getMessageFromResponseError} from '../queryUtils';
import {AppDispatch, RootState} from 'store/types';
import {signOutUser} from 'store/app/apiActions';
import {setExpiresAt, setToken} from 'store/app/appSlice';
import {appContainer} from 'app';
import {ignoreInterceptorCodes, payErrors} from './ignoreInterceptorCodes';
import {ECognitoErrorsCodes} from 'types/errors';

// eslint-disable-next-line
export const errorInterceptor = (dispatch: AppDispatch) => (error: any) => {
  if (!error.response) {
    notification.error({
      message: 'Oops! Something has gone wrong with request to the server!',
    });
    return Promise.reject(error);
  }

  /*
   * Add your interceptors here.
   */

  // Ignore specific backend errors in interceptors.
  // Sometimes backend errors should be handled in a component,
  // rather than on interceptors level.
  // Responses with below reasonCode will be ignored by interceptors,
  // so such errors should be handled on component level.

  const reasonCode = error?.response?.data?.reasonCode;
  if (reasonCode && ignoreInterceptorCodes.includes(reasonCode)) {
    if (payErrors.includes(reasonCode)) {
      // booking errors
      const errorData = error.response.data;
      notification.error({
        message: errorData.errors?.length
          ? errorData.errors.map((errItem: string) => errItem + '\n')
          : errorData.reasonDescription || 'Something went wrong with booking',
        duration: 0,
      });
    }

    return Promise.reject(error.response);
  }
  const status = error.response.status;
  const reasonDescription = error?.response?.data?.reasonDescription;

  if (status === 401) {
    if (
      reasonCode === ECognitoErrorsCodes.BAD_REQUEST_DECODE_JWT_TOKEN &&
      reasonDescription?.includes('Jwt expired')
    ) {
      notification.error({
        message: 'Your session has expired. Please log in again.',
      });
      dispatch(signOutUser());
      window.location.href = Routes.login;

      return Promise.reject(error.response);
    }

    notification.error({
      message: 'Unauthorized. Please check your credentials or log in again.',
    });

    return Promise.reject(error.response);
  }
  if (status >= 500 && status < 527) {
    notification.error({
      message: `Oops! Something went wrong! Please notify support of error ${status}.`,
    });
    return Promise.reject(error.response);
  }

  const errorText = getMessageFromResponseError(error);
  if (errorText) {
    notification.error({
      message: errorText,
    });
  }
  return Promise.reject(error.response);
};

export const responseInterceptor =
  // eslint-disable-next-line
  (dispatch: AppDispatch) => (response: any) => {
    try {
      // handle response here
    } catch (e) {}

    return Promise.resolve(response);
  };

let tokenRefreshPromise: Promise<string> | null = null;

export const requestInterceptor =
  (store: Store<RootState>) => async (config: any) => {
    const state = store.getState();
    const dispatch: AppDispatch = store.dispatch;
    const token = state.app.token;
    const expiresAt = state.app.user.expiresAt;
    const now = Date.now();
    if (expiresAt && expiresAt < now) {
      if (!tokenRefreshPromise) {
        tokenRefreshPromise = new Promise((resolve, reject) => {
          appContainer.getCurrentSession({
            onSuccess: (cognitoUserSession: CognitoUserSession) => {
              const idToken = cognitoUserSession.getIdToken().getJwtToken();
              const {expiresAt} = tokenService.getUserData(idToken);
              dispatch(setToken(idToken));
              dispatch(setExpiresAt(expiresAt));
              resolve(idToken);
            },
            onError: () => {
              notification.error({
                message: 'Oops! Session has expired. Please log in again.',
              });
              dispatch(signOutUser());
              window.location.href = Routes.login;
              reject(new Error('Token refresh failed'));
            },
          });
        });
        tokenRefreshPromise.finally(() => {
          tokenRefreshPromise = null;
        });
      }
      try {
        const newToken = await tokenRefreshPromise;
        if (newToken) {
          config.headers.Authorization = `Bearer ${newToken}`;
        }
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };

export const requestErrorInterceptor =
  // eslint-disable-next-line
  (store: Store<RootState>) => (error: any) => {
    // Do something with request error
    return Promise.reject(error);
  };
