import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {IOfferDay, IOfferUnitExtra, IRequestDay} from 'types/offer';
import {parseStringToNumber} from 'utils/stringUtils';
import {FREE, PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IExtrasOption, IExtrasResponse} from 'types/dto/IExtras.type';
import {TCreateOfferItem} from '../types';

interface IGetMappedItemsToOffer {
  requestRooms: (IRequestDay | IOfferDay)[];
  updatedPricesEquipment: any;
  updatedPricesCatering: any;
  itemsToOffer: TCreateOfferItem[];
}
export function useMappedItemsToOffer() {
  const availableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.extras`),
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const getMappedItemsToOffer = useCallback(
    ({
      requestRooms,
      updatedPricesEquipment,
      updatedPricesCatering,
      itemsToOffer,
    }: IGetMappedItemsToOffer) => {
      const mapBedrooms = (unitExtras: IOfferUnitExtra[]) =>
        unitExtras.map(({accommodationExtraId}) => {
          const extra = availableExtras.find(
            (extra) => extra.accommodationExtraId === accommodationExtraId,
          );

          return {
            extraId: accommodationExtraId as number,
            code: extra?.code,
            price: {
              value: parseStringToNumber(extra?.price ?? 0),
            },
          };
        });

      const mapExtras = (formValues: any) =>
        Object.entries(Object.assign({}, formValues)).map(
          ([itemName, itemPrice]) => {
            const extraItem = extrasOption.find(({name}) => name === itemName);
            const isFree = itemPrice === FREE;

            return {
              extraId: extraItem?.id as number,
              code: extraItem?.code,
              price: {
                value: isFree ? 0 : parseStringToNumber(itemPrice as string),
              },
            };
          },
        );

      const roomsToOffer = requestRooms.flatMap(
        ({bedrooms, items, eventType}) =>
          items?.flatMap(({unitId, id}, idx) => [
            {
              eventType,
              requestItemId: id,
              unitId,
              extras: mapExtras(updatedPricesEquipment),
              bedrooms: idx === 0 ? mapBedrooms(bedrooms) : [],
              foodAndBeverage:
                idx === 0 ? mapExtras(updatedPricesCatering) : [],
            },
          ]),
      );

      const mergedMap = new Map();

      roomsToOffer.forEach((item) =>
        mergedMap.set(item?.requestItemId, {...item}),
      );
      itemsToOffer.forEach((item) =>
        mergedMap.set(item.requestItemId, {
          ...mergedMap.get(item.requestItemId),
          ...item,
        }),
      );

      return Array.from(mergedMap.values());
    },
    [availableExtras, extrasOption],
  );

  return [getMappedItemsToOffer];
}
