import React from 'react';
import styled from 'styled-components';
import {Tag} from 'antd';

import {
  activeColor,
  borderRadiusDef,
  disabledColor,
  errorColor,
  lighterBlueColor,
  lightErrorColor,
  lightTextColor,
  lightWarnColor,
  primaryColor,
  secondaryColor,
  successColor,
  successSecondaryColor,
  warnColor,
} from 'constants/styleVars';

export const NWTag = styled(
  /* antd fires "Received `true` for a non-boolean attribute `primary`" warning.
  Below solution is meant to filter non-standard props. */
  /* eslint-disable */
  ({
    primary,
    active,
    success,
    disabled,
    secondary,
    faded,
    error,
    warning,
    ...props
  }: any) => <Tag {...props} />,
  /* eslint-enable */
)<{
  primary?: boolean;
  secondary?: boolean;
  active?: boolean;
  success?: boolean;
  disabled?: boolean;
  faded?: boolean;
  error?: boolean;
  warning?: boolean;
}>`
  font-weight: 500;
  border-radius: ${borderRadiusDef};

  ${({primary}) =>
    primary
      ? `
    color: #ffffff;
    background-color: ${primaryColor};
    border: 1px solid ${primaryColor};
  `
      : ''}

  ${({secondary}) =>
    secondary &&
    `
    color: #ffffff;
    background-color: ${lightTextColor};
    border: 1px solid ${lightTextColor};
  `}
      
  ${({active}) =>
    active
      ? `
    color: ${primaryColor};
    background-color: ${activeColor};
    border: 1px solid ${primaryColor};
  `
      : ''}

  ${({success}) =>
    success
      ? `
    color: ${successColor};
    background-color: ${successSecondaryColor};
    border: 1px solid ${successColor};
  `
      : ''}
      
  ${({disabled}) =>
    disabled &&
    `
    color: ${disabledColor};
    background-color: ${lighterBlueColor};
    border: 1px solid ${disabledColor};
  `}
  
  ${({faded}) =>
    faded &&
    `
    color: ${secondaryColor};
    background: transparent;
    border: 1px solid ${secondaryColor};
  `}
  
  ${({warning}) =>
    warning &&
    `
    color: ${warnColor};
    background-color: ${lightWarnColor};
    border: 1px solid ${warnColor};
  `}
  
  ${({error}) =>
    error &&
    `
    color: ${errorColor};
    background-color: ${lightErrorColor};
    border: 1px solid ${errorColor};
  `}
`;

export default NWTag;
