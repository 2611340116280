import {useEffect, useRef, useState} from 'react';

import {IExtrasOption} from 'types/dto/IExtras.type';
import {IRequestMeeting} from '../types';
import {useUnitsExtras} from './useUnitsExtras';

interface IProps {
  requestMeetings: IRequestMeeting[];
  requestUnits: IRequestMeeting[];
}
export function useEmptyExtrasList({requestMeetings, requestUnits}: IProps) {
  const emptyEquipmentExtras = useRef<IExtrasOption[] | null>(null);
  const emptyCateringExtras = useRef<IExtrasOption[] | null>(null);

  const [updatedPricesEquipment, setUpdatedPricesEquipment] = useState<any>();
  const [updatedPricesCatering, setUpdatedPricesCatering] = useState<any>();

  const unitsExtras = useUnitsExtras({
    requestMeetings,
  });

  const {allEquipmentExtras, allCateringExtras, filteredAvailableExtras} =
    unitsExtras;

  const isSomePricesMissed =
    !!(
      emptyEquipmentExtras.current?.length ||
      emptyCateringExtras.current?.length
    ) || requestUnits.some(({bedrooms}) => bedrooms?.length);

  useEffect(() => {
    if (!emptyEquipmentExtras.current && allEquipmentExtras.length) {
      emptyEquipmentExtras.current = allEquipmentExtras.filter(
        ({code}) => !filteredAvailableExtras.some((item) => item.code === code),
      );
    }
  }, [allEquipmentExtras, filteredAvailableExtras]);

  useEffect(() => {
    if (!emptyCateringExtras.current && allCateringExtras.length) {
      emptyCateringExtras.current = allCateringExtras.filter(
        ({code}) => !filteredAvailableExtras.some((item) => item.code === code),
      );
    }
  }, [allCateringExtras, filteredAvailableExtras]);

  return {
    emptyEquipmentExtras,
    emptyCateringExtras,
    updatedPricesEquipment,
    setUpdatedPricesEquipment,
    updatedPricesCatering,
    setUpdatedPricesCatering,
    isSomePricesMissed,
    unitsExtras,
  };
}
