import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';
import {useAppDispatch} from 'store/hooks';

import {Routes} from 'constants/routes';
import {signOutUser} from 'store/app/apiActions';

import {borderRadiusLg} from 'styles/configs/variables';
import {borderColor, lighterGrayColor, whiteColor} from 'styles/configs/colors';
import {offsetDef, offsetSm} from 'styles/configs/offset';

const commonCss = css`
  padding: ${offsetDef} ${offsetSm};
  cursor: pointer;
  color: inherit;

  &:hover,
  &.active {
    background: ${lighterGrayColor};
    color: inherit;
  }

  &.active {
    pointer-events: none;
  }
`;

const SignOutContainer = styled.div`
  display: grid;
  grid-gap: ${offsetSm};
  grid-template-columns: auto 1fr;
  align-items: center;

  ${commonCss};
`;

const StyledNavLink = styled((props: any) => <NavLink {...props} end />)`
  display: block;
  ${commonCss};
`;

const StyledMenu = styled.ul`
  padding: 0;
  border-radius: ${borderRadiusLg};
  min-width: 210px;
  overflow: hidden;
  background: ${whiteColor};

  > li + li {
    border-top: 1px solid ${borderColor};
  }
`;

export function OverlayMenu({
  isCustomer,
  setIsMenuVisible,
}: {
  isCustomer: boolean;
  setIsMenuVisible: (value: boolean) => void;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSignOut = () => {
    if (!isCustomer) {
      navigate(Routes.login);
    }
    dispatch(signOutUser());
  };

  const closeMenu = () => {
    setIsMenuVisible(false);
  };

  return (
    <StyledMenu>
      {isCustomer && (
        <>
          <li>
            <StyledNavLink to={Routes.customerBookings} onClick={closeMenu}>
              Bookings overview
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink to={Routes.customerId} onClick={closeMenu}>
              Profile
            </StyledNavLink>
          </li>
        </>
      )}

      <li>
        <SignOutContainer onClick={onSignOut} id='headerUserMenuSignoutButton'>
          <Icon icon='SIGN_OUT' />
          Log out
        </SignOutContainer>
      </li>
    </StyledMenu>
  );
}
