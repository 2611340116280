import React, {useState, useCallback, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {GoogleMap, OverlayView, OverlayViewF} from '@react-google-maps/api';
import _throttle from 'lodash/throttle';

import NW2VenueListGoogleMapMarker from './components/NW2VenueListGoogleMapMarker/NW2VenueListGoogleMapMarker';
import NW2MapRefetchVenues from './components/NW2MapRefetchVenues';
import NW2VenuesListMapItem from './components/NW2VenuesListMapItem/NW2VenuesListMapItem';
import NW2MapExpandedModeToggler from './components/NW2MapExpandedModeToggler';

import LIST_CONFIG from 'view/venue/NW2VenuesList/NW2VenuesList.config';
import useSearchData from 'view/venue/hooks/search/useSearchData';
import {useAppSelector} from 'store/hooks';
import {TMarkerLocation} from 'types/venue';
import {setGoogleMapRefetch} from 'store/venues/venuesSlice';
import {DEFAULT_GOOGLE_MAP_ZOOM, EMPTY_OBJECT, FOOTER_ID} from 'constants/app';
import {
  Container,
  NW2VenuesListMapItemMobileContainer,
  NW2VenuesListMapItemDesktopContainer,
  MapBox,
} from './NW2VenueListGoogleMap.styles';
import {getActiveMarkerPixelPositionOffset} from 'utils/googleMapUtils';
import {StyledSpin} from './NW2VenueListGoogleMap.styles';
import {IMergedVenue} from 'types/search';

type TProps = {
  venuesList: IMergedVenue[];
  getVenuesForGoogleMap: (
    location: TMarkerLocation & {map: google.maps.Map},
  ) => void;
  checkIsShortListItemAdded: (venueId: number) => boolean;
  onRequestForOfferClick: (venue: IMergedVenue) => () => void;
  onVenueItemClick: (venue: IMergedVenue, hasPrice: boolean) => () => void;
  closeActiveVenueItem: () => void;
  isVenuesListLoading: boolean;
};

const NW2VenueListGoogleMap = ({
  venuesList,
  getVenuesForGoogleMap,
  checkIsShortListItemAdded,
  onRequestForOfferClick,
  onVenueItemClick,
  closeActiveVenueItem,
  isVenuesListLoading,
}: TProps) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const navbarHeight: number = useAppSelector(({app}) => app.navbarHeight);
  const isHeaderFixed: boolean = useAppSelector(({app}) => app.headerFixed);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isGoogleMapExpanded = useAppSelector(
    ({venuesReducer}) => venuesReducer.isGoogleMapExpanded,
  );
  const isGoogleMapLoaded = useAppSelector(({app}) => app.isGoogleMapLoaded);
  const shortListItems = useAppSelector(({offers}) => offers.shortList.items);
  const activeMarker = useAppSelector(
    ({venuesReducer}) => venuesReducer.venuesListActiveMarker,
  );

  const {searchData} = useSearchData();

  const [markerLocation, setMarkerLocation] = useState<TMarkerLocation>({
    lat: 0,
    lng: 0,
  });

  const google = (window as any).google;

  const {longitude, latitude, zoom} = searchData || EMPTY_OBJECT;

  useEffect(() => {
    if (longitude && latitude) {
      setMarkerLocation({
        lat: Number(latitude),
        lng: Number(longitude),
      });
    }
  }, [longitude, latitude]);

  const handleDragEnd = useCallback(() => {
    dispatch(setGoogleMapRefetch(true));
  }, [dispatch]);

  const handleZoomChanged = useCallback(() => {
    if (map) {
      dispatch(setGoogleMapRefetch(true));
    }
  }, [map, dispatch]);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const height = window?.innerHeight || 0;

  const getDesktopHeight = useCallback(() => {
    const footer = document.getElementById(FOOTER_ID);
    const container = containerRef?.current;

    if (!footer || !container) {
      return Math.ceil(height);
    }
    const footerTop = footer.getBoundingClientRect().top || 0;
    const footerOffset = Math.ceil(height - footerTop);
    const footerVisibleHeight = footerOffset > 0 ? footerOffset : 0;
    const heightWithBottomOffset = Math.ceil(height - footerVisibleHeight);
    const topOffset = container.getBoundingClientRect().top;

    if (isGoogleMapExpanded || (!isGoogleMapExpanded && footerOffset <= 0)) {
      return Math.ceil(height - topOffset);
    }

    return Math.ceil(heightWithBottomOffset - topOffset);
  }, [height, isGoogleMapExpanded]);

  const getContainerStyle = useCallback(() => {
    const mobileHeight = `calc(100% - ${LIST_CONFIG.drawerControlItemHeight}px)`;
    const height = isMobile ? mobileHeight : `${getDesktopHeight()}px`;

    return {
      width: '100%',
      height,
    };
  }, [isMobile, getDesktopHeight]);

  useEffect(() => {
    const handleScroll = () => {
      if (!isGoogleMapExpanded) {
        setContainerStyle(getContainerStyle());
      }
    };

    const throttledScroll = _throttle(handleScroll, 100);

    window.addEventListener('scroll', throttledScroll);

    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [isGoogleMapExpanded, getContainerStyle]);

  const [containerStyle, setContainerStyle] = useState(getContainerStyle());

  useEffect(() => {
    setContainerStyle(getContainerStyle());
  }, [
    height,
    isHeaderFixed,
    isMobile,
    navbarHeight,
    isGoogleMapExpanded,
    isVenuesListLoading,
    getContainerStyle,
  ]);

  const MAP_OPTIONS = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControlOptions: {
      position: google?.maps.ControlPosition.RIGHT_TOP,
    },
  };

  if (!isGoogleMapLoaded) return null;

  const isShortlisted = checkIsShortListItemAdded?.(
    activeMarker?.accommodationId as number,
  );

  const onMapItemClick = onRequestForOfferClick(activeMarker as IMergedVenue);

  const mapItem = !!activeMarker && (
    <NW2VenuesListMapItem
      onRequestForOfferClick={onMapItemClick}
      closeActiveMarker={closeActiveVenueItem}
      isShortlisted={isShortlisted}
    />
  );

  return (
    <Container ref={containerRef}>
      <MapBox>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerLocation}
          zoom={zoom || DEFAULT_GOOGLE_MAP_ZOOM}
          clickableIcons={false}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={handleDragEnd}
          onBoundsChanged={handleDragEnd}
          onZoomChanged={handleZoomChanged}
          options={MAP_OPTIONS}
        >
          {venuesList.map((venue) => (
            <NW2VenueListGoogleMapMarker
              key={`marker-${venue.id || venue.accommodationId}`}
              venue={venue}
              onVenueItemClick={onVenueItemClick}
              isSelected={checkIsShortListItemAdded?.(
                venue.id || venue.accommodationId,
              )}
              hasPrice={!!venue.totalPrice}
            />
          ))}

          {activeMarker && !isMobile && (
            <OverlayViewF
              position={{
                lat: Number(activeMarker.location.latitude),
                lng: Number(activeMarker.location.longitude),
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getActiveMarkerPixelPositionOffset}
            >
              <NW2VenuesListMapItemDesktopContainer>
                {mapItem}
              </NW2VenuesListMapItemDesktopContainer>
            </OverlayViewF>
          )}
        </GoogleMap>
      </MapBox>

      {!!map && (
        <NW2MapRefetchVenues
          map={map}
          getVenuesForGoogleMap={getVenuesForGoogleMap}
        />
      )}

      {!isMobile && <NW2MapExpandedModeToggler />}

      {activeMarker && isMobile && (
        <NW2VenuesListMapItemMobileContainer
          isShortListEmpty={!shortListItems.length}
        >
          {mapItem}
        </NW2VenuesListMapItemMobileContainer>
      )}

      {isVenuesListLoading && <StyledSpin spinning />}
    </Container>
  );
};

export default NW2VenueListGoogleMap;
