import {useCallback, useEffect, useMemo, useState} from 'react';

import {EResourcesCode, EResourcesType} from 'types/dto/IExtras.type';
import {
  IPreviewPrices,
  IRequestMeeting,
  TCreateOfferFormValues,
  TExtraPriceField,
} from '../types';
import {FREE} from 'constants/venue';
import {getRoomRentalPriceByDateRange} from 'utils/venueUtils';
import {IOfferRequestReviewUnitExtra} from '../../OfferRequestReviewUnitCard/types';
import {parseStringToNumber} from 'utils/stringUtils';

interface IProps {
  activeForm: TCreateOfferFormValues;
  activeUnitId: string;
  activeUnit: IRequestMeeting;
  requestUnits: IRequestMeeting[];
  extrasOptions: IOfferRequestReviewUnitExtra[];
  equipmentPrices?: TExtraPriceField;
  cateringPrices?: TExtraPriceField;
  equipmentFirstUnitPrices?: TExtraPriceField;
  cateringFirstUnitPrices?: TExtraPriceField;
  bedroomsPrices?: TExtraPriceField;
  isMeeting: boolean;
}
export function usePreviewPrices({
  activeForm,
  activeUnitId,
  activeUnit,
  requestUnits = [],
  extrasOptions,
  equipmentPrices = {},
  cateringPrices = {},
  bedroomsPrices = {},
  equipmentFirstUnitPrices,
  cateringFirstUnitPrices,
  isMeeting,
}: IProps): IPreviewPrices {
  const {priceHour, priceHalfDay, priceDay} = activeForm;

  const {
    checkIn,
    checkOut,
    extras = [],
    bedrooms = [],
    foodAndBeverage = [],
  } = activeUnit;

  const allExtras = useMemo(
    () => [...extras, ...bedrooms, ...foodAndBeverage],
    [bedrooms, extras, foodAndBeverage],
  );

  const [unitsSubtotal, setUnitsSubtotal] = useState<Record<string, number>>(
    {},
  );

  const [roomRentalPrices, setRoomRentalPrices] = useState<
    Record<string, number>
  >({});

  const formExtras = Object.assign(
    {},
    equipmentPrices,
    cateringPrices,
    bedroomsPrices,
  );

  const formExtrasFirstUnit = Object.assign(
    {},
    equipmentFirstUnitPrices,
    cateringFirstUnitPrices,
  );

  const isSomeExtrasPriceEmpty = useMemo(() => {
    const allEquipmentPrices = Object.values({
      ...equipmentPrices,
      ...equipmentFirstUnitPrices,
    });

    const allCateringPrices = Object.values({
      ...cateringPrices,
      ...cateringFirstUnitPrices,
    });

    return !!(
      (extras.length && allEquipmentPrices.some((price) => !price)) ||
      (foodAndBeverage.length && allCateringPrices.some((price) => !price))
    );
  }, [
    cateringFirstUnitPrices,
    cateringPrices,
    equipmentFirstUnitPrices,
    equipmentPrices,
    extras.length,
    foodAndBeverage.length,
  ]);

  const roomRentalPrice = useMemo(
    () =>
      getRoomRentalPriceByDateRange({
        priceHour,
        priceHalfDay,
        priceDay,
        checkOut,
        checkIn,
      }),
    [checkIn, checkOut, priceDay, priceHalfDay, priceHour],
  );

  const daySubtotal = useMemo(() => {
    if (isMeeting && !roomRentalPrice) return 0;

    const mappedExtras = allExtras.map((item) => {
      const extraItem = extrasOptions.find(({code}) => code === item.code);
      const isBedroom = extraItem?.type === EResourcesType.BEDROOM;
      const quantity = item.quantity;
      const formItemPrice =
        formExtrasFirstUnit[extraItem?.name || ''] ||
        formExtras[(isBedroom ? extraItem?.code : extraItem?.name) || ''];

      const isFree = formItemPrice === FREE;

      const price = isBedroom
        ? parseStringToNumber(bedroomsPrices[extraItem?.code])
        : formItemPrice
        ? isFree
          ? 0
          : parseStringToNumber(formItemPrice)
        : '';

      return {
        ...extraItem,
        quantity,
        price,
      };
    });

    if (mappedExtras.some(({price}) => !price && price !== 0)) {
      // not all extras prices filled
      return 0;
    }

    // calculation
    return mappedExtras.reduce(
      (acc, curr) =>
        acc +
        (curr.code === EResourcesCode.WIFI
          ? parseStringToNumber(curr.price)
          : parseStringToNumber(curr.price) * curr.quantity),
      isMeeting ? roomRentalPrice : 0,
    );
  }, [
    isMeeting,
    roomRentalPrice,
    allExtras,
    extrasOptions,
    formExtrasFirstUnit,
    formExtras,
    bedroomsPrices,
  ]);

  const setRoomPrice = useCallback((id: string, price: number) => {
    setRoomRentalPrices((prev) => ({
      ...prev,
      [id]: price,
    }));
  }, []);

  useEffect(() => {
    if (!activeUnitId) return;

    setRoomPrice(activeUnitId, roomRentalPrice);
  }, [activeUnitId, roomRentalPrice, setRoomPrice]);

  useEffect(() => {
    if (!activeUnitId) return;

    if (allExtras.length) {
      setUnitsSubtotal((prev) => ({
        ...prev,
        [activeUnitId]: isSomeExtrasPriceEmpty ? 0 : daySubtotal,
      }));
    } else {
      setUnitsSubtotal((prev) => ({...prev, [activeUnitId]: roomRentalPrice}));
    }
  }, [
    activeUnitId,
    allExtras.length,
    daySubtotal,
    isSomeExtrasPriceEmpty,
    roomRentalPrice,
  ]);

  const removeUnitSubtotalPrice = useCallback((unitId: string) => {
    setUnitsSubtotal((prev) => ({...prev, [unitId]: 0}));
  }, []);

  const totalPrice = useMemo(() => {
    const subTotalValues = Object.values(unitsSubtotal);

    if (
      requestUnits?.length === subTotalValues.length &&
      subTotalValues.every((v) => v > 0)
    ) {
      return subTotalValues.reduce((acc, subTotal) => acc + subTotal, 0);
    }

    return 0;
  }, [requestUnits?.length, unitsSubtotal]);

  return {
    roomRentalPrices,
    unitsSubtotal,
    totalPrice,
    removeUnitSubtotalPrice,
    setRoomPrice,
  };
}
