import React from 'react';
import styled from 'styled-components';
import {useAppSelector} from 'store/hooks';

import {StyledLink} from 'view/common/NW2Footer/NW2Footer.styles';

import {
  fontWeightExtraBold,
  NW2Gray50Color,
  NW2Gray900Color,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXXLg,
  sectionInsetSm,
  whiteColor,
  borderRadiusLg,
} from 'constants/styleVars';

import PreFooterSubscribeForm from 'view/components/PreFooter/PreFooterSubscribeForm';
import {StyledH6} from 'view/components/Typography';
import CustomList from 'view/components/CustomList';

import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {usePopularLocations} from './usePopularLocations';
import {ISSUES_NEWWORK_EMAIL} from 'constants/app';
import {useOpenFAQModal} from '../../common/NW2MultiModal/hooks/useOpenInfoPageModal';

const PreFooterContainer = styled.section`
  padding: ${sectionInsetSm} 0;
  background: ${NW2Gray900Color};
  color: ${whiteColor};
`;

const PreFooterRow = styled.div`
  display: grid;
  grid-gap: ${offsetXXXLg} ${offsetXLg};
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
`;

const Column = styled.div`
  padding: ${offsetXLg};
  background: ${NW2Gray50Color};
  border-radius: ${borderRadiusLg};
`;

const ColumnWrapper = styled.div<{offset?: string}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(0, auto);
  gap: ${({offset}) => (offset ? offset : offsetDef)} ${offsetDef};
`;

const aboutList = ['Our Story', 'Contact', 'Press'];

export function PreFooter() {
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  const listItemOffset = isDesktop ? offsetXSm : offsetDef;
  const columnWrapperOffset = isDesktop ? offsetDef : offsetXLg;

  const {popularLocations} = usePopularLocations();
  const openFAQModal = useOpenFAQModal();

  const onClickHandler = (name: string) => () => {
    switch (name) {
      case 'Contact':
        window.open(
          `mailto:${ISSUES_NEWWORK_EMAIL}?subject=${encodeURIComponent(
            'Contact to HRS',
          )}`,
        );
        break;
      case 'FAQ':
        openFAQModal();
        break;
      case 'About':
        window.open('https://www.hrs.com/enterprise/', '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <PreFooterContainer>
      <NW2Container>
        <PreFooterRow>
          <div>
            <ColumnWrapper offset={columnWrapperOffset}>
              <StyledH6 white>Popular Locations</StyledH6>
              <StyledH6 white>About HRS</StyledH6>
              <CustomList
                listStyle='noStyles'
                offset={listItemOffset}
                NW2Gray200
                size='small'
                list={popularLocations}
              />
              <CustomList
                listStyle='noStyles'
                offset={listItemOffset}
                NW2Gray200
                size='small'
                list={aboutList.map((name) => ({
                  value: (
                    <StyledLink
                      fontWeight={fontWeightExtraBold}
                      onClick={onClickHandler(name)}
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      {name}
                    </StyledLink>
                  ),
                }))}
              />
            </ColumnWrapper>
          </div>
          <Column>
            <PreFooterSubscribeForm />
          </Column>
        </PreFooterRow>
      </NW2Container>
    </PreFooterContainer>
  );
}
