import {TContainer} from 'app';
import {AppDispatch, RootState} from 'store/types';
import {
  setOfferDetails,
  setOffersError,
  setOffersLoading,
  setRequestedUnits,
  setSupplierOfferRequests,
  setSupplierOfferRequest,
  setSupplierOffers,
  setRequestLoading,
  setRequestsCount,
  addCompareList,
  setCompareListLoading,
  setGroupDetails,
  setGroups,
  setGroupsLoading,
  setGroupDetailsLoading,
} from './offersSlice';
import {
  EGroupStatus,
  EOfferStatus,
  ERequestStatus,
  IGroupDetails,
  IOffer,
  IOfferV2,
  IRequestDay,
  IRequestFormPayload,
  IRequestV2,
  TOfferDetails,
} from 'types/offer';
import {ICreateOfferPayload} from './types';
import {ISearchRequestOfferDto} from 'types/dto/ISearchRequestOfferDto.type';
import {IVenue} from 'types/venue';
import {setOfferCreator} from 'store/customer/customerSlice';
import {ISearchGroupDto} from 'types/dto/ISearchGroupDto.type';

export const getOfferRequestById =
  ({
    requestId,
    onSuccess,
    onError,
  }: {
    requestId: string;
    onSuccess?: () => void;
    onError?: () => void;
  }) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    offersContainer.getOfferRequestById({
      payload: requestId,
      onSuccess: (response: IRequestV2) => {
        dispatch(setOfferDetails(response));
        dispatch(setRequestedUnits(response.days));
        onSuccess?.();
      },
      onError: (error: any) => {
        dispatch(setOffersError(error));
        onError?.();
      },
    });
  };

export const getOfferById =
  ({
    id,
    onSuccess,
    onError,
  }: {
    id: string;
    onSuccess?: (orderNumber: string, venueId: number) => void;
    onError?: (error: any) => void;
  }) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setOffersLoading(true));

    offersContainer.getOfferById({
      payload: id,
      onSuccess: (response: IOfferV2) => {
        dispatch(setOfferDetails(response));
        dispatch(setRequestedUnits(response.days));

        if (
          response.status === EOfferStatus.OFFER_CONFIRMED &&
          response.number &&
          onSuccess
        ) {
          onSuccess(response.number, response.accommodationId);
        }
      },
      onError: (error: any) => {
        dispatch(setOffersError(error));
        onError?.(error);
      },
    });
  };

export const requestOffer =
  ({
    payload,
    onSuccessHandler,
  }: {
    payload: Partial<IRequestV2>[];
    onSuccessHandler: (id: string) => void;
  }) =>
  async (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setOffersLoading(true));

    await offersContainer.requestOffer({
      payload,
      onSuccess: (response: IGroupDetails) => {
        dispatch(setGroupDetails(response));

        dispatch(setOfferDetails(response.requests[0]));
        dispatch(setRequestedUnits(response.requests[0].days));

        onSuccessHandler(response.requests[0].groupId);
      },
      onError: (error: any) => dispatch(setOffersError(error)),
    });
  };

export const sendRequestForm =
  ({
    payload,
    onSuccessHandler,
  }: {
    payload: IRequestFormPayload;
    onSuccessHandler: () => void;
  }) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setOffersLoading(true));

    offersContainer.sendRequestForm({
      payload,
      onSuccess: () => {
        onSuccessHandler();
        dispatch(setOffersLoading(false));
      },
      onError: (error: any) => dispatch(setOffersError(error)),
    });
  };

export const getGroupDetails =
  (groupId: string, isOfferDetailsSet?: boolean) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setGroupDetailsLoading(true));
    offersContainer.getGroupById({
      payload: groupId,
      onSuccess: (response: IGroupDetails) => {
        dispatch(setGroupDetails(response));

        if (isOfferDetailsSet) {
          const firstRequest = response.requests[0] ?? response.offers[0];

          dispatch(setOfferDetails(firstRequest));
          dispatch(setRequestedUnits(firstRequest.days));
        }
      },
      onError: (error: any) => {
        dispatch(setOffersError(error));
        dispatch(setGroupDetailsLoading(false));
      },
    });
  };

export const requestGroupCancel =
  ({groupId, onSuccess}: {groupId: string; onSuccess?: () => void}) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    offersContainer.requestGroupCancel({
      payload: groupId,
      onSuccess: (response: IGroupDetails) => {
        const state = getState();
        const groupsState = state.offers.groups;

        const updatedGroups = groupsState.filter(
          (group) => group.groupId !== groupId,
        );
        dispatch(setGroups(updatedGroups));
        dispatch(setGroupDetails(response));
        dispatch(setOfferDetails(response.requests[0])); // todo check do we need it?
        onSuccess?.();
      },
      onError: (error: any) => dispatch(setOffersError(error)),
    });
  };

export const getRequests =
  (id: number, status: ERequestStatus[], isCustomer?: boolean) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setRequestLoading(true));

    return offersContainer.getOfferRequests({
      payload: {id, status, isCustomer},
      onSuccess: (response: ISearchRequestOfferDto[]) => {
        dispatch(setSupplierOfferRequests(response));
        dispatch(setRequestLoading(false));
      },
      onError: (error: any) => {
        dispatch(setOffersError(error));
        dispatch(setRequestLoading(false));
      },
    });
  };

export const getOffers =
  (id: string | number, status: EOfferStatus[], isCustomer?: boolean) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setOffersLoading(true));

    return offersContainer.getOffers({
      payload: {id, status, isCustomer},
      onSuccess: (response: ISearchRequestOfferDto[]) => {
        dispatch(setSupplierOffers(response));
        dispatch(setOffersLoading(false));
      },
      onError: (error: any) => {
        dispatch(setOffersError(error));
        dispatch(setOffersLoading(false));
      },
    });
  };

export const getGroupsByStatus =
  (payload: {customerId: number; status: EGroupStatus[]}) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setGroupsLoading(true));

    return offersContainer.getGroupsByStatus({
      payload,
      onSuccess: (response: ISearchGroupDto[]) => {
        dispatch(setGroups(response));
        dispatch(setGroupsLoading(false));
      },
      onError: (error: any) => {
        dispatch(setOffersError(error));
        dispatch(setGroupsLoading(false));
      },
    });
  };

export const requestOfferDecline =
  (id: string, onSuccessHandler?: () => void) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    offersContainer.requestDecline({
      payload: id,
      onSuccess: (response: IRequestV2) => {
        dispatch(setOfferDetails(response));
        dispatch(setOffersLoading(false));
        onSuccessHandler && onSuccessHandler();
      },
      onError: (error: any) => dispatch(setOffersError(error)),
    });
  };

export const getOfferRequestByToken =
  (token: string) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    dispatch(setOffersLoading(true));

    offersContainer.getOfferRequestByToken({
      payload: token,
      onSuccess: (response: IRequestV2) => {
        dispatch(setSupplierOfferRequest(response));
      },
      onError: (error: any) => dispatch(setOffersError(error)),
    });
  };

export const setOfferRequestedDetails =
  (payload: TOfferDetails) => (dispatch: AppDispatch) => {
    dispatch(setOfferDetails(payload));
  };

export const setOfferRequestedUnits =
  (payload: IRequestDay[]) => (dispatch: AppDispatch) => {
    dispatch(setRequestedUnits(payload));
  };

export const createOffer =
  (
    payload: ICreateOfferPayload,
    onSuccess?: () => void,
    onError?: (error: unknown) => void,
  ) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {offersContainer}: TContainer,
  ) =>
    offersContainer.createOffer({
      payload,
      onSuccess: (response: IOffer) => {
        const state = getState();
        const offerNumber = response.number;

        // remove created offer from list
        dispatch(
          setSupplierOfferRequests(
            state.offers.requests.filter(({number}) => number !== offerNumber),
          ),
        );

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      },
      onError: (error: unknown) => {
        dispatch(setOffersError(error));

        if (typeof onError === 'function') {
          onError(error);
        }
      },
    });

export const getRequestsCount =
  (id: number, onSuccess?: (count: number) => void) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    offersContainer.getRequestsCount({
      payload: id,
      onSuccess: (response: number) => {
        dispatch(setRequestsCount(response));

        if (typeof onSuccess === 'function') {
          onSuccess(response);
        }
      },
      onError: () => {},
    });
  };

export const getCompareList =
  (ids: number[], onSuccess?: () => void) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(setOffersLoading(true));
    dispatch(setCompareListLoading(true));

    venuesContainer.getVenuesByIds({
      payload: ids,
      onSuccess: (response: IVenue[]) => {
        dispatch(addCompareList(response));

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
        dispatch(setOffersLoading(false));
      },
    });
  };

export const getOfferCreator =
  (orderId: string) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {offersContainer}: TContainer,
  ) => {
    offersContainer.getOfferCreator({
      payload: orderId,
      onSuccess: (response: string) => {
        dispatch(setOfferCreator(response));
      },
    });
  };
