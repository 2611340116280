import {ChangeEvent, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import _debounce from 'lodash/debounce';

import {
  EPointsOfInterest,
  IPointOfInterest,
  TPointsOfInterestType,
} from 'types/venue';
import {distanceConverter} from 'utils/venueUtils';
import {setVenuePointsOfInterest} from 'store/venue/actions';

interface IProps {
  data: IPointOfInterest[];
  type: EPointsOfInterest;
}

const DEBOUNCE_TIME = 150;

export function usePointsOfInterestFormItem({data, type}: IProps) {
  const dispatch = useDispatch();

  const itemData: IPointOfInterest | undefined = data.find(
    (dataItem) => dataItem.type === type,
  );

  const [kmValue, setKmValue] = useState<number | undefined>(
    itemData?.distance || 0,
  );
  const [miValue, setMiValue] = useState<number | undefined>(
    distanceConverter('mi', itemData?.distance || 0),
  );

  const handleFormFieldDistance = useCallback(
    (path: TPointsOfInterestType, value: number) => {
      const currentObject = data?.filter(
        (item: IPointOfInterest) => item.type === path,
      )[0];

      dispatch(
        setVenuePointsOfInterest({
          key: path,
          value: {
            type: path,
            name: currentObject?.name || '',
            description: currentObject?.description || null,
            distance: value || 0,
          },
        }),
      );
    },
    [data, dispatch],
  );

  const onDistanceChangeKm = _debounce((value: number) => {
    setKmValue(value);
    setMiValue(distanceConverter('mi', value));

    handleFormFieldDistance(type, value);
  }, DEBOUNCE_TIME);

  const onDistanceChangeMi = _debounce((value: number) => {
    setMiValue(value);
    setKmValue(distanceConverter('km', value));

    handleFormFieldDistance(type, distanceConverter('km', value));
  }, DEBOUNCE_TIME);

  const handleFormFieldName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const currentObject = data.filter(
        (item: IPointOfInterest) => item.type === type,
      )[0];

      dispatch(
        setVenuePointsOfInterest({
          key: type,
          value: {
            type,
            name: event.target.value,
            distance: currentObject?.distance || 0,
            description: currentObject?.description,
          },
        }),
      );
    },
    [data, dispatch, type],
  );

  return {
    kmValue,
    miValue,
    name: itemData?.name || '',
    onDistanceChangeKm,
    onDistanceChangeMi,
    handleFormFieldName,
  };
}
