import {getCountries} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import {editedCountriesForBE} from 'constants/countries';

export const getFilteredOption = (
  input: string,
  option?: {props: {children?: string}},
) => {
  return (option?.props?.children || '')
    .toLowerCase()
    .includes(input.toLowerCase());
};

export const countriesObject = {
  ...en,
  ...editedCountriesForBE,
} as Record<string, string>;

export function getCountriesList() {
  return getCountries()
    .map((countryCode) => ({
      countryCode,
      country: countriesObject[countryCode],
    }))
    .sort((a, b) => {
      if (a.country < b.country) return -1;
      if (a.country > b.country) return 1;
      return 0;
    });
}
