import {useAppDispatch, useAppSelector} from 'store/hooks';
import {venuesContainer} from 'app';
import {
  setCorporateBillingAddress,
  setIsPaymentInProcess,
} from 'store/payment/paymentSlice';
import {
  getCustomerBookingOrder,
  handleOnSuccessPostCustomerBookingOrder,
  postCustomerBookingOrder,
} from 'store/customer/apiActions';
import {IBookingOrderResponse} from 'types/dto/IBooking.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {useVenueDetailsData} from './useVenueDetailsData';
import {useVenueDetailsId} from './useVenueDetailsId';
import {IBookingPreviewFormData} from 'store/venues/types';
import {getPaymentDataPayload} from '../helpers/helpers';
import {EFeatureToggles} from 'constants/featureToggles';
import {useFeatureToggle} from 'hooks/useFeatureToggle';

function useOrderSubmit() {
  const dispatch = useAppDispatch();

  const userId = useAppSelector(({app}) => app.user.id);
  const corporateBillingAddress = useAppSelector(
    ({payment}) => payment.corporateBillingAddress,
  );
  const paymentType = useAppSelector(({payment}) => payment.paymentType);
  const accountReferences = useAppSelector(
    ({payment}) => payment.accountReferences,
  );

  const {venueDetails} = useVenueDetailsData();
  const [venueId] = useVenueDetailsId();

  const accommodationType = venueDetails.accommodationType;

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const isMeetingRoom = [ERoomType.MEETING_ROOM, ERoomType.GROUPS].includes(
    roomType,
  );

  const {kKey, fKey} = accountReferences[0] ?? {};

  const clearPaymentProcess = () => {
    dispatch(setCorporateBillingAddress(null));
  };

  const onStatusConfirmed = (bookingResponse: IBookingOrderResponse) => {
    dispatch(handleOnSuccessPostCustomerBookingOrder(bookingResponse));
    dispatch(getCustomerBookingOrder(bookingResponse.orderNumber));
    clearPaymentProcess();
  };

  const [isHrsPayEnabled] = useFeatureToggle(EFeatureToggles.HRS_PAY);

  const confirmBooking = async (formData: IBookingPreviewFormData) => {
    dispatch(setIsPaymentInProcess(true));

    const data = getPaymentDataPayload({
      userId,
      venueId: Number(venueId),
      formData,
      isOffice,
      isMeetingRoom,
      isHrsPayEnabled,
      ...(isHrsPayEnabled
        ? {corporateBillingAddress, fKey, kKey, paymentType}
        : {}),
    });

    await venuesContainer.confirmBooking({
      payload: data,
      onSuccess: (response: IBookingOrderResponse) => {
        onStatusConfirmed(response);
      },
    });
  };

  const onFormSubmit = async (formData: IBookingPreviewFormData) => {
    if (userId && isOffice) {
      const data = getPaymentDataPayload({
        userId,
        venueId: Number(venueId),
        formData,
        isOffice,
        isHrsPayEnabled,
        ...(isHrsPayEnabled ? {paymentType} : {}),
      });

      await dispatch(postCustomerBookingOrder(data));
    } else {
      await confirmBooking(formData);
    }
  };

  return {
    onFormSubmit,
  };
}

export default useOrderSubmit;
