import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {isJpegOrJpgOrPng, minSize, maxSize} from './helpers';
import {maximumImageFileSize, minimumImageFileSize} from 'constants/app';
import {AxiosResponse} from 'axios';

import {isEmailValid} from '../infra/common/venueValidation';
import api from '../infra/common/http.service';
import venueRepository from '../infra/repository/VenueRepository';
import {errorColor, fontWeightBold} from 'constants/styleVars';
import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';

const validateConsistOnlySpaces = async (rule: any, value: string) => {
  if (!!value && !value.replace(/\s/g, '').length) {
    return Promise.reject("Can't consist only from spaces");
  } else {
    return Promise.resolve();
  }
};

const imageValidator = (rule: any, value: any[]) => {
  const errorMessagesArr = value?.reduce((total: any, current: any) => {
    const isInvalidFormat = !isJpegOrJpgOrPng(current);
    const isInvalidMinSize = !minSize(current);
    const isInvalidMaxSize = maxSize(current);

    if (isInvalidFormat) {
      return [...total, `${current.name} has invalid format \n`];
    } else if (isInvalidMinSize) {
      return [
        ...total,
        `${current.name} must be bigger than ${minimumImageFileSize} \n`,
      ];
    } else if (isInvalidMaxSize) {
      return [
        ...total,
        `${current.name} must be smaller than ${maximumImageFileSize} \n`,
      ];
    } else {
      return total;
    }
  }, []);

  if (value?.length && errorMessagesArr?.length) {
    return Promise.reject(errorMessagesArr.join(''));
  } else {
    return Promise.resolve();
  }
};

export const textRules = [
  {
    max: 300,
    message: 'Maximum number of symbols 300',
  },
  {
    validator: validateConsistOnlySpaces,
  },
];

export const venueCharactersFieldRules = [
  (form: any) => ({
    validator() {
      const venueCharacters = form.getFieldValue(`venueCharacters`);
      if (venueCharacters && venueCharacters.length) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error('At least one venue character should be chosen'),
      );
    },
  }),
];

export const nameFieldRules = [
  {
    required: true,
    message: 'Name is required',
  },
  {
    min: 2,
    message: 'Minimum number of symbols 2',
  },
  {
    max: 200,
    message: 'Maximum number of symbols 200',
  },

  {
    validator: validateConsistOnlySpaces,
  },
];
export const dateBirthFieldRules = [
  {required: true, message: 'Date is required'},
];

export const descriptionFieldRules = [
  {
    max: 1000,
    message: 'Maximum number of symbols 1000',
  },
  {
    validator: validateConsistOnlySpaces,
  },
  {
    required: true,
    message: 'Description is required',
  },
  {
    min: 150,
    message: 'Minimum number of symbols 150',
  },
];

export const venueTypeFieldRules = [
  {
    required: true,
    message: 'Venue type is required',
  },
];

export const imageFieldRules = [
  {
    required: true,
    message: 'Image is required',
  },
  {
    validator: imageValidator,
  },
];

export const countryFieldRules = [
  {
    required: true,
    message: 'Country name is required',
  },
];
export const stateOfProvinceFieldRules = [
  {
    required: true,
    message: 'State or province is required',
  },
];

export const cityFieldRules = [
  {
    required: true,
    message: 'City name is required',
  },
];

export const streetFieldRules = [
  {
    required: true,
    message: 'Street name is required',
  },
];

export const buildingFieldRules = [
  {
    required: true,
    message: 'Building number is required',
  },
];

export const postalCodeFieldRules = [
  {
    required: true,
    message: 'Postal code is required',
  },
  {
    max: 15,
    message: 'Maximum number of symbols 15',
  },
];
export const taxIdFieldRules = [
  {
    required: true,
    message: 'Tax ID is required',
  },

  {
    max: 20,
    message: 'Maximum number of symbols 20',
  },
];
export const regNumFieldRules = [
  {
    required: true,
    message: 'Registration number is required',
  },

  {
    max: 20,
    message: 'Maximum number of symbols 20',
  },
];
export const legalNameFieldRules = [
  {
    required: true,
    message: 'Legal business name is required',
  },

  {
    max: 250,
    message: 'Maximum number of symbols 250',
  },
];

export const floorsFieldRules = [
  (form: any) => ({
    validator() {
      const floors = form.getFieldValue(`floors`);
      if (floors && floors.length) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Specify at least one floor'));
    },
  }),
];

export const currencyFieldRules = [
  {
    required: true,
    message: 'Currency is required',
  },
];

const emailRegexp =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailFieldRules = [
  {
    required: true,
    message: 'Email is required',
    pattern: emailRegexp,
  },
];

const StyledLinkErrorValidation = styled(Link)`
  font-weight: ${fontWeightBold};
  text-decoration: underline;
  color: ${errorColor};
`;

export const emailFieldRulesWithUniqEmailValidator = [
  ...emailFieldRules,
  {
    validator: async (rule: any, value: string) => {
      let response: AxiosResponse;
      if (!!value && isEmailValid(value)) {
        response = await venueRepository(api).getIsEmailAvailable(value);
        if (!response) {
          await Promise.reject(
            <p>
              This email is already in use. Please use another one, or{' '}
              <StyledLinkErrorValidation to='/login'>
                login
              </StyledLinkErrorValidation>{' '}
              if you already have an account
            </p>,
          );
        }
      }
    },
  },
];

export const termsFieldRules = [
  {
    required: true,
    message:
      'You need to agree/check with Terms and Conditions & Privacy Policy',
  },
];

export const subscriptionFieldRules = [
  {
    required: true,
    message: 'You need to agree/check',
  },
];

export const phoneFieldRules = [{required: true, message: 'Phone is required'}];

export const legalPhoneFieldRules = [
  {
    required: true,
    async validator(rule: any, value: string) {
      const isPhoneNumberValid = isPossiblePhoneNumber(value);
      const parsedPhoneNumber = parsePhoneNumber(value);
      if (!value) await Promise.reject(`Phone is required`);
      if (!isPhoneNumberValid || !parsedPhoneNumber?.country) {
        await Promise.reject(`The phone number you entered isn't valid`);
      }
    },
  },
];

export const passwordFieldRules = [
  {required: true, message: 'Password is required'},
];

export const hKeyRules = [
  {
    required: false,
    message: 'H-key must be more than 1 and less than 20 digits',
    pattern: /^[0-9]{0,20}$/,
  },
];
