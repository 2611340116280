import React from 'react';

import {
  StatusTag,
  CanceledIcon,
  CompletedIcon,
  ConfirmedIcon,
  DeclinedIcon,
  DisabledIcon,
  InProgressIcon,
} from './StatusLabel.styles';
import {TStatusLabel} from 'types/supplier';
import {EBookingOrderStatus, EOrderShowStatus} from 'types/bookingOrders';
import {EServiceStatus} from 'view/venue/NW2VenueProfile/components/NMMResources/types';
import {EGroupStatus, EOfferStatus, ERequestStatus} from 'types/offer';
import {EBookingStatus} from 'types/booking';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {useAppSelector} from 'store/hooks';

function StatusLabel({
  status,
  isCustomer,
  showStatus,
}: {
  status: TStatusLabel;
  isCustomer?: boolean;
  showStatus?: EOrderShowStatus | null;
}) {
  const isAgentRole =
    useAppSelector(({app}) => app.user.role) === EUserRoleCognito.ROLE_AGENT;

  const getStatusLabel = () => {
    switch (status) {
      case EBookingOrderStatus.CONFIRMED:
        return showStatus === EOrderShowStatus.PENDING ? (
          <>
            <InProgressIcon /> Pending
          </>
        ) : (
          <>
            <ConfirmedIcon /> Confirmed
          </>
        );
      case EBookingOrderStatus.PASSED:
        return (
          <>
            <CompletedIcon /> Completed
          </>
        );
      case EBookingOrderStatus.CANCELED:
      case EBookingOrderStatus.RFP_CANCELLED:
      case ERequestStatus.REQUEST_CANCELED:
      case EGroupStatus.GROUP_CANCELLED:
      case EBookingStatus.RTC_CANCELLED:
      case EBookingStatus.EDIT_MODE_CANCELED:
      case EBookingStatus.EDIT_CANCELED_BY_CONFIRM:
        return (
          <>
            <CanceledIcon /> Cancelled
          </>
        );
      case EBookingOrderStatus.RFP_DECLINED:
      case ERequestStatus.REQUEST_DECLINED:
        return (
          <>
            <DeclinedIcon /> Declined
          </>
        );
      case EBookingOrderStatus.RFP_EXPIRED:
      case EBookingStatus.RTC_EXPIRED:
        return (
          <>
            <CanceledIcon /> Expired
          </>
        );
      case ERequestStatus.REQUEST_EXPIRED:
      case EGroupStatus.GROUP_EXPIRED:
        return isCustomer ? (
          <>
            <CanceledIcon /> Declined
          </>
        ) : (
          <>
            <CompletedIcon /> Expired
          </>
        );
      case EBookingOrderStatus.RFP_PENDING:
      case ERequestStatus.REQUEST_PENDING:
      case EGroupStatus.GROUP_REQUEST_PENDING:
        return (
          <>
            <InProgressIcon /> Pending
          </>
        );
      case EServiceStatus.ACTIVE:
        return (
          <>
            <ConfirmedIcon /> Active
          </>
        );
      case EServiceStatus.DISABLED:
        return (
          <>
            <DisabledIcon /> Disabled
          </>
        );
      case EServiceStatus.IN_PROGRESS:
        return (
          <>
            <InProgressIcon /> In progress
          </>
        );
      case EOfferStatus.OFFER_PENDING:
      case EGroupStatus.GROUP_OFFER_PENDING:
        return isAgentRole ? (
          <>
            <InProgressIcon /> Pending
          </>
        ) : (
          <>
            <InProgressIcon /> Offer sent
          </>
        );
      case EOfferStatus.OFFER_DECLINED:
        return (
          <>
            <DeclinedIcon /> Offer declined
          </>
        );
      case EOfferStatus.OFFER_CONFIRMED:
      case EGroupStatus.GROUP_PROCESSED:
        return (
          <>
            <ConfirmedIcon /> Offer confirmed
          </>
        );
      case EOfferStatus.OFFER_ACCEPTING_EXPIRED:
        return (
          <>
            <CompletedIcon /> Offer expired
          </>
        );
      case EBookingStatus.RTC_PENDING:
        return (
          <>
            <InProgressIcon /> Change request pending
          </>
        );

      default:
        return <></>;
    }
  };

  return <StatusTag>{getStatusLabel()}</StatusTag>;
}

export default StatusLabel;
