export const SET_CREATE_VENUE_STEP = 'SET_CREATE_VENUE_STEP';
export const SET_COVER_VENUE_PHOTO = 'SET_COVER_VENUE_PHOTO';
export const SET_VENUE_PHOTO_TO_LIST = 'SET_VENUE_PHOTO_TO_LIST';
export const SET_VENUE_IMAGES_LIST = 'SET_VENUE_IMAGES_LIST';
export const SET_VENUE_IMAGES_LIST_FROM_RESPONSE =
  'SET_VENUE_IMAGES_LIST_FROM_RESPONSE';
export const REMOVE_VENUE_PHOTO_FROM_LIST = 'REMOVE_VENUE_PHOTO_FROM_LIST';
export const SET_VENUE_PHOTO_ERROR = 'SET_VENUE_PHOTO_ERROR';
export const REMOVE_VENUE_PHOTO_ERROR = 'REMOVE_VENUE_PHOTO_ERROR';
export const SET_VENUE_FIELDS = 'SET_VENUE_FIELDS';
export const SET_SAME_LEGAL_CONTACT_VENUE_FIELDS =
  'SET_SAME_LEGAL_CONTACT_VENUE_FIELDS';
export const SET_IS_VENUE_INFORMATION_CONFIRMED =
  'SET_IS_VENUE_INFORMATION_CONFIRMED';
export const SET_VENUE_POINTS_OF_INTEREST = 'SET_VENUE_POINTS_OF_INTEREST';
export const CLEAR_VENUE_POINTS_OF_INTEREST = 'CLEAR_VENUE_POINTS_OF_INTEREST';

export const GET_VENUE_TYPES_REQUEST = 'GET_VENUE_TYPES_REQUEST';
export const GET_VENUE_TYPES_SUCCESS = 'GET_VENUE_TYPES_SUCCESS';
export const GET_VENUE_TYPES_FAILURE = 'GET_VENUE_TYPES_FAILURE';
export const GET_VENUE_CHARACTERS_REQUEST = 'GET_VENUE_CHARACTERS_REQUEST';
export const GET_VENUE_CHARACTERS_SUCCESS = 'GET_VENUE_CHARACTERS_SUCCESS';
export const GET_VENUE_CHARACTERS_FAILURE = 'GET_VENUE_CHARACTERS_FAILURE';
export const GET_VENUE_CURRENCIES_REQUEST = 'GET_VENUE_CURRENCIES_REQUEST';
export const GET_VENUE_CURRENCIES_SUCCESS = 'GET_VENUE_CURRENCIES_SUCCESS';
export const GET_VENUE_CURRENCIES_FAILURE = 'GET_VENUE_CURRENCIES_FAILURE';
export const SET_VENUE_ENTITY_TYPE = 'SET_VENUE_ENTITY_TYPE';

export const SET_VENUE = 'SET_VENUE';
export const SET_VENUE_AMENITY = 'SET_VENUE_AMENITY';
export const SET_VENUE_ALREADY_EXISTS = 'SET_VENUE_ALREADY_EXISTS';

export const GET_AMENITIES_REQUEST = 'GET_AMENITIES_REQUEST';
export const GET_AMENITIES_SUCCESS = 'GET_AMENITIES_SUCCESS';
export const GET_AMENITIES_FAILURE = 'GET_AMENITIES_FAILURE';

export const SET_SPACE_REQUEST = 'SET_SPACE_REQUEST';
export const SET_SPACE_SUCCESS = 'SET_SPACE_SUCCESS';
export const SET_SPACE_FAILURE = 'SET_SPACE_FAILURE';
export const EDIT_SPACE_REQUEST = 'EDIT_SPACE_REQUEST';
export const EDIT_SPACE_FAILURE = 'EDIT_SPACE_FAILURE';
export const GET_SPACES_REQUEST = 'GET_SPACE_REQUEST';
export const GET_SPACES_SUCCESS = 'GET_SPACE_SUCCESS';
export const GET_SPACES_FAILURE = 'GET_SPACE_FAILURE';

export const GET_BEDROOMS_SUCCESS = 'GET_BEDROOMS_SUCCESS';
export const GET_BEDROOMS_LOADING = 'GET_BEDROOMS_LOADING';

// WORK IN PROGRESS
export const GET_WORK_IN_PROGRESS_REQUEST = 'GET_WORK_IN_PROGRESS_REQUEST';
export const GET_WORK_IN_PROGRESS_SUCCESS = 'GET_WORK_IN_PROGRESS_SUCCESS';
export const GET_WORK_IN_PROGRESS_FAILURE = 'GET_WORK_IN_PROGRESS_FAILURE';

// SPACE DELETION STATUSES
export const GET_SPACE_DELETION_STATUS_REQUEST =
  'GET_SPACE_DELETION_STATUS_REQUEST';
export const GET_SPACE_DELETION_STATUS_SUCCESS =
  'GET_SPACE_DELETION_STATUS_SUCCESS';
export const GET_SPACE_DELETION_STATUS_FAILURE =
  'GET_SPACE_DELETION_STATUS_FAILURE';
export const CLEAR_SPACE_DELETION_STATUS = 'CLEAR_SPACE_DELETION_STATUS';

// SPACE DELETION
export const DELETE_SPACE_REQUEST = 'DELETE_SPACE_REQUEST';
export const DELETE_SPACE_FAILURE = 'DELETE_SPACE_FAILURE';
export const SET_SPACE_SELECTED_TAB = 'SET_SPACE_SELECTED_TAB';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_VENUE_DATA = 'CLEAR_VENUE_DATA';
export const GET_EXTRAS_OPTION_REQUEST = 'GET_EXTRAS_OPTION_REQUEST';
export const GET_EXTRAS_OPTION_SUCCESS = 'GET_EXTRAS_OPTION_SUCCESS';
export const GET_EXTRAS_OPTION_FAILURE = 'GET_EXTRAS_OPTION_FAILURE';
export const GET_AVAILABLE_EXTRAS_REQUEST = 'GET_AVAILABLE_EXTRAS_REQUEST';
export const GET_AVAILABLE_EXTRAS_SUCCESS = 'GET_AVAILABLE_EXTRAS_SUCCESS';
export const GET_AVAILABLE_EXTRAS_FAILURE = 'GET_AVAILABLE_EXTRAS_FAILURE';
export const ADD_EXTRA_SUCCESS = 'ADD_EXTRA_SUCCESS';
export const ADD_EXTRA_ITEM_SUCCESS = 'ADD_EXTRA_ITEM_SUCCESS';
export const UPDATE_EXTRA_SUCCESS = 'UPDATE_EXTRA_SUCCESS';
export const GET_ANNOUNCEMENT_SUCCESS = 'GET_ANNOUNCEMENT_SUCCESS';
