import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import DateUtils from 'utils/dateUtils';
import {
  DEFAULT_GOOGLE_MAP_ZOOM,
  INITIAL_RADIUS,
  MINIMAL_GROUP_QTY,
} from 'constants/app';
import {
  DEFAULT_CHECK_IN,
  DEFAULT_CHECK_OUT,
  DEFAULT_ROOM_TYPE,
  DEFAULT_TIME_DATA,
} from 'constants/defaultOperationalTimes';
import {ERoomType} from 'types/dto/ERoomType.type';
import {TInitialState} from './types';
import {ITimeData} from 'types/dto/ISearch.types';
import {TInitialLocation} from 'types/app';
import {
  TDay,
  TGroupRooms,
  TSearchCriteria,
  TVenuesQuerySearchData,
} from 'types/search';
import {ERoomSchemaNames} from 'types/venue';
import LocalStorageService from 'infra/common/localStorage.service';

export const INITIAL_SEATING_STYLE = ERoomSchemaNames.BLOCK;

export const INITIAL_SEARCH_CRITERIA = {
  searchString: '',
  location: false,
  startDate: DEFAULT_CHECK_IN,
  endDate: DEFAULT_CHECK_OUT,
  page: 0,
  cost: 0,
  latitude: '',
  longitude: '',
  roomType: DEFAULT_ROOM_TYPE,
  meetingRoomCapacity: 1,
  radius: INITIAL_RADIUS,
  zoom: DEFAULT_GOOGLE_MAP_ZOOM,
  multiRooms: false, // todo check why we need it
  seatingStyle: INITIAL_SEATING_STYLE,
  filterDays: [],
};

const INITIAL_GROUPS_ROOMS: TGroupRooms = {
  single: {
    isEnabled: true,
    qty: MINIMAL_GROUP_QTY,
    withBreakfast: true,
  },
  double: {
    isEnabled: false,
    qty: 1,
    withBreakfast: true,
  },
};

const initialState: TInitialState = {
  searchCriteria: INITIAL_SEARCH_CRITERIA,
  searchPredictionLoading: '',
  initialTimeData: DEFAULT_TIME_DATA,
  summaryData: [],
  meetingRequestData: [],
  searchFocusedInput: '',
  initialLocation: {
    pathname: '',
    search: '',
  },
  storedSearchCriteria: {} as TVenuesQuerySearchData,
  isSearchListLoaded: false,
  isFastSearchEnabled: false,
  groups: {
    rooms: INITIAL_GROUPS_ROOMS,
    isInitialized: false,
  },
  tabsRoomType: DEFAULT_ROOM_TYPE,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchCriteria(state, action: PayloadAction<Partial<TSearchCriteria>>) {
      state.searchCriteria = {...state.searchCriteria, ...action.payload};
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchCriteria.searchString = action.payload;
    },
    setSearchCriteriaRoomType(state, action: PayloadAction<ERoomType>) {
      state.searchCriteria.roomType = action.payload;
    },
    setSearchCriteriaMeetingRoomCapacity(state, action: PayloadAction<number>) {
      state.searchCriteria.meetingRoomCapacity = action.payload;
    },
    setSearchCriteriaSeatingStyle(
      state,
      action: PayloadAction<ERoomSchemaNames>,
    ) {
      state.searchCriteria.seatingStyle = action.payload;
    },
    setLatitudeLongitude(
      state,
      action: PayloadAction<{
        latitude: string | null | undefined;
        longitude: string | null | undefined;
      }>,
    ) {
      state.searchCriteria.latitude = action.payload.latitude || '';
      state.searchCriteria.longitude = action.payload.longitude || '';
    },
    setSearchCriteriaVisibleRadius(state, action: PayloadAction<number>) {
      state.searchCriteria.radius = action.payload;
    },
    setSearchCriteriaZoom(state, action: PayloadAction<number>) {
      state.searchCriteria.zoom = action.payload;
    },
    setPageForVenuesSearch(state, action: PayloadAction<number>) {
      state.searchCriteria.page = action.payload;
    },
    setStartDateForVenuesSearch(state, action: PayloadAction<string>) {
      state.searchCriteria.startDate = DateUtils.normalizeDateToBackendFormat(
        action.payload,
      );
    },
    setEndDateForVenuesSearch(state, action: PayloadAction<string>) {
      state.searchCriteria.endDate = DateUtils.normalizeDateToBackendFormat(
        action.payload,
      );
    },
    searchPredictionLoading(state, action: PayloadAction<string>) {
      state.searchPredictionLoading = action.payload;
    },
    clearSearchCriteria(state) {
      state.searchCriteria = INITIAL_SEARCH_CRITERIA;
    },
    setMeetingRequestData(state, action: PayloadAction<TDay[]>) {
      state.meetingRequestData = action.payload;
    },
    setMultiSearchTimeData(state, action: PayloadAction<ITimeData[]>) {
      state.initialTimeData = action.payload;
    },
    clearMultiSearchData(state) {
      state.meetingRequestData = [];
      state.initialTimeData = DEFAULT_TIME_DATA;

      LocalStorageService.removeItemByKey('multiSearchData');
      LocalStorageService.removeItemByKey('multiVenuesPayload');
      LocalStorageService.removeItemByKey('multiVenueOfferPayload');
    },
    setInitialLocation(state, action: PayloadAction<TInitialLocation>) {
      state.initialLocation = {
        ...state.initialLocation,
        ...action.payload,
      };
    },
    setSearchFocusedInput(state, action: PayloadAction<string>) {
      state.searchFocusedInput = action.payload;
    },
    setStoredSearchCriteria(
      state,
      action: PayloadAction<TVenuesQuerySearchData>,
    ) {
      state.storedSearchCriteria = action.payload;
    },
    setSearchListLoaded(state, action: PayloadAction<boolean>) {
      state.isSearchListLoaded = action.payload; // todo remove after merge
    },
    setIsFastSearchEnabled(state, action: PayloadAction<boolean>) {
      state.isFastSearchEnabled = action.payload;
      LocalStorageService.setByKey(
        'isFastSearchEnabled',
        String(action.payload),
      );
    },
    setGroupsRooms(state, action: PayloadAction<TGroupRooms>) {
      state.groups.rooms = action.payload;

      LocalStorageService.setByKey(
        'groupsRooms',
        JSON.stringify(action.payload),
      );
    },
    clearGroupsRooms(state) {
      state.groups.rooms = INITIAL_GROUPS_ROOMS;
      LocalStorageService.removeItemByKey('groupsRooms');
      LocalStorageService.removeItemByKey('bedroomsCatering');
      state.groups.isInitialized = false;
    },
    setGroupsInitialized(state, action: PayloadAction<boolean>) {
      state.groups.isInitialized = action.payload;
    },
    setTabsRoomType(state, action: PayloadAction<ERoomType>) {
      state.tabsRoomType = action.payload;
    },
  },
});

export const {
  setSearchCriteria,
  setSearchText,
  setSearchCriteriaRoomType,
  setSearchCriteriaMeetingRoomCapacity,
  setSearchCriteriaSeatingStyle,
  setLatitudeLongitude,
  setSearchCriteriaVisibleRadius,
  setSearchCriteriaZoom,
  setPageForVenuesSearch,
  setStartDateForVenuesSearch,
  setEndDateForVenuesSearch,
  searchPredictionLoading,
  clearSearchCriteria,
  setMeetingRequestData,
  setMultiSearchTimeData,
  clearMultiSearchData,
  setInitialLocation,
  setSearchFocusedInput,
  setStoredSearchCriteria,
  setSearchListLoaded,
  setIsFastSearchEnabled,
  setGroupsRooms,
  clearGroupsRooms,
  setGroupsInitialized,
  setTabsRoomType,
} = searchSlice.actions;

export default searchSlice.reducer;
