import React from 'react';

import NW2Button from 'view/components/NW2Button';
import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';

import {useAppSelector} from 'store/hooks';
import {
  borderRadiusDef,
  fontSizeSm,
  fontWeightExtraBold,
  lineHeightMd,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';

import {useRedirectToRequest} from '../../../hooks/useNavigateToRequestOffer';
import {useShortList} from '../../../hooks/useShortList';
import {ENW2PopoverDrawerPlacement} from 'view/components/NW2PopoverDrawer/types';
import styled from 'styled-components';
import {useGoBackToStoredSearch} from '../../../../hooks/search/useGoBackToStoredSearch';
import {Routes} from 'constants/routes';

export const ModalHeaderTitle = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  padding-top: ${offsetXXSm};
`;

export const ModalActions = styled.div`
  display: flex;
  gap: ${offsetXSm};
  justify-content: center;
`;

interface IProps {
  open: boolean;
  onClose?: () => void;
}

export function SelectMoreVenuePopup({open, onClose}: IProps) {
  const {shortList, isMultiRequest} = useShortList();

  const {isMobile} = useAppSelector(({app}) => app.deviceType);

  const {redirectToRequest} = useRedirectToRequest({
    shortList,
    isMultiRequest,
  });

  const [onGoToSearchResults] = useGoBackToStoredSearch();

  const onRedirectToList = () => {
    if (location.pathname === Routes.venuesList) {
      return onClose && onClose();
    }
    onGoToSearchResults();
  };

  return (
    <NW2PopoverDrawer
      header={
        <ModalHeaderTitle>Insufficient hotels Shortlisted</ModalHeaderTitle>
      }
      body={
        <>
          <p>
            For the best chances of receiving an offer, select at least three
            venues. We recommend selecting five venues to ensure a sufficient
            number of compliant offers.
          </p>

          <ModalActions>
            <NW2Button
              onClick={redirectToRequest}
              size='small'
              style={{whiteSpace: 'nowrap', letterSpacing: '0'}}
            >
              skip and go to request
            </NW2Button>
            <NW2Button
              onClick={onRedirectToList}
              buttonType='primary'
              size='small'
              style={{whiteSpace: 'nowrap', letterSpacing: '0'}}
            >
              Back to venue list
            </NW2Button>
          </ModalActions>
        </>
      }
      isMobile={false}
      popoverWidth={isMobile ? '100%' : '400px'}
      popoverBodyStyle={{
        padding: `${offsetDef} ${offsetSm}`,
        position: 'fixed',
      }}
      overlayInnerStyle={{
        borderRadius: borderRadiusDef,
      }}
      placement={ENW2PopoverDrawerPlacement.TOP_RIGHT}
      open={open}
      onClose={onClose}
      overlayClassName='insufficient-popover'
      clickable
    />
  );
}
