import queryString from 'query-string';
import _get from 'lodash/get';

import {TSearchCriteria, TVenuesQuerySearchData} from 'types/search';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {ERoomSchemaNames} from '../types/venue';

export const getVenuesListQuerySearchData = ({
  searchCriteria,
  locationSearch = '?',
}: {
  searchCriteria: TSearchCriteria;
  locationSearch?: string;
}): TVenuesQuerySearchData => {
  const queryData = queryString.parse(locationSearch.slice(1), {
    parseBooleans: true,
  });
  const unitIds = queryData['unitIds']
    ? (queryData['unitIds'] as string).split(',')
    : [];
  const multiRooms =
    (queryData['multiRooms'] as boolean) ||
    unitIds?.length > 1 ||
    searchCriteria.multiRooms;

  return {
    searchString:
      (queryData['searchString'] as string) || searchCriteria.searchString, // Currently used only on front end, but backend needs it for future purposes
    latitude: (queryData['latitude'] as string) || searchCriteria.latitude,
    longitude: (queryData['longitude'] as string) || searchCriteria.longitude,
    startDate: (queryData['startDate'] as string) || searchCriteria.startDate,
    endDate: (queryData['endDate'] as string) || searchCriteria.endDate,
    page: searchCriteria.page || Number(queryData['page'] as string) || 0,
    roomType: (queryData['roomType'] as ERoomType) || searchCriteria.roomType,
    meetingRoomCapacity: Number(
      (queryData['meetingRoomCapacity'] as string) ||
        searchCriteria.meetingRoomCapacity,
    ),
    seatingStyle:
      (queryData['seatingStyle'] as ERoomSchemaNames) ||
      searchCriteria.seatingStyle,
    radius: queryData['radius']
      ? Number(queryData['radius'])
      : searchCriteria.radius,
    zoom: queryData['zoom'] ? Number(queryData['zoom']) : searchCriteria.zoom,
    multiRooms,
  };
};

export const getMessageFromResponseError = (error: any): string => {
  const errorMsg = _get(error, 'response.data.errors'); // deprecated backend error format
  const newFormatErrorMsg = _get(error, 'response.data.reasonDescription'); // new backend error format
  if (Array.isArray(errorMsg) && errorMsg.length) {
    return (errorMsg as string[]).join('; ');
  }
  return newFormatErrorMsg || errorMsg || '';
};

export const objectToQueryString = (obj: any): string =>
  Object.entries(obj)
    .map(([k, v]) => k + '=' + v)
    .join('&');

// helper function to split by comma the role query param and return the first element
export const parseRoles = (roles?: string | null) => {
  if (roles) {
    return roles.split(',')[0] as EUserRoleCognito;
  }
  return EUserRoleCognito.ROLE_CUSTOMER;
};
