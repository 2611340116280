import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2Button from 'view/components/NW2Button/NW2Button';
import MeetingRoomTotalPrice from './components/MeetingRoomTotalPrice';
import {MeetingRoomSpaceItem} from './components/MeetingRoomSpaceItem';
import WhatIsNextBlock from './components/WhatIsNextBlock';
import AccommodationBlockItem from './components/AccommodationBlock/AccommodationBlockItem';
import PrePostAccommodationBlock from './components/AccommodationBlock/PrePostAccommodationBlock';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import useSearchData from 'view/venue/hooks/search/useSearchData';
import DateUtils, {DateFormats} from 'utils/dateUtils';

import {
  filterUnitsBySeatingPlan,
  getPrePostAccommodation,
} from 'view/venue/NW2VenueDetails/components/SpacesListBlock/helpers';

import {
  ButtonWrapper,
  HeadingParticipants,
  SpacesListBlockInner,
  SpacesListBlockWrapper,
  StyledDiv,
  StyledList,
  StyledParagraph,
  StyledRoomSetupFitting,
} from './MeetingRoomSpacesListBlock.styles';
import {EEventType} from 'types/venue';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {useIsVenueFromUSA} from 'view/venue/hooks/useIsUSA';
import {useSelectedRoomId} from 'view/venue/hooks/useSelectedRoomId';
import {
  IDayResponse,
  IMultiDayPublicVenue,
  IUnitResponse,
} from 'types/dto/IPublicVenue';
import {EResourcesCode} from 'types/dto/IExtras.type';
import {setSelectedRoom} from 'store/venueDetails/venueDetailsSlice';
import {getDayTimeRangeText} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {ERoomType} from 'types/dto/ERoomType.type';

const INIT_ITEMS_TO_SHOW = 4;

type TProps = {
  hasBedroom: boolean;
};

export function MeetingRoomSpacesListBlock({hasBedroom}: TProps) {
  const dispatch = useAppDispatch();

  const selectedMeetingRoomSeatingPlan = useSelector((state) =>
    _get(state, 'venuesReducer.selectedMeetingRoomSeatingPlan'),
  );

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const locale = useAppSelector(({app}) => app.locale);

  const {searchData} = useSearchData();
  const {venueDetails, venueUnits, isAlternative, isRfp} =
    useVenueDetailsData();

  const accommodationType = venueDetails.accommodationType;
  const currency = venueDetails.currency;
  const [isUSA] = useIsVenueFromUSA(venueDetails?.location?.country);
  const {multiRooms, roomType} = searchData;
  const [itemsToShow, setItemsToShow] = useState(INIT_ITEMS_TO_SHOW);
  const [getSelectedVenueUnitId] = useSelectedRoomId();

  const isGroups = roomType === ERoomType.GROUPS;

  const isShowAllItems = useMemo(() => {
    const spaceFilteredBySeatingPlan = filterUnitsBySeatingPlan(
      venueUnits,
      selectedMeetingRoomSeatingPlan,
    );

    return itemsToShow < spaceFilteredBySeatingPlan.length;
  }, [itemsToShow, selectedMeetingRoomSeatingPlan, venueUnits]);

  const spacesTotalCount = venueUnits.length;

  const [isDefaultRoomSelected, setDefaultRoomSelected] = useState(false);
  const venueId = venueDetails?.accommodationId;
  const storedRoomId = getSelectedVenueUnitId({
    venueId,
    checkIn: venueDetails?.days?.[0]?.checkIn,
  });

  useEffect(() => {
    if (isDefaultRoomSelected) return;

    if (storedRoomId) {
      // init stored selected room
      const matchedVenueUnit = (venueUnits.find(
        (venueUnit) => venueUnit.unitId === storedRoomId,
      ) || venueUnits[0]) as unknown as IUnitResponse;

      dispatch(
        setSelectedRoom({
          unit: matchedVenueUnit,
        }),
      );
    } else {
      // init default selected room
      const optimalPriceUnit = ((venueUnits as unknown as IUnitResponse[]).find(
        ({isOptimalPrice}) => isOptimalPrice,
      ) || venueUnits[0]) as unknown as IUnitResponse;

      dispatch(
        setSelectedRoom({
          unit: optimalPriceUnit,
        }),
      );
    }

    setDefaultRoomSelected(true);
  }, [dispatch, isDefaultRoomSelected, storedRoomId, venueUnits]);

  const showMoreHandler = () => {
    setItemsToShow(spacesTotalCount);
  };

  useEffect(() => {
    if (isMobile) {
      setItemsToShow(INIT_ITEMS_TO_SHOW);
    }
  }, [isMobile]);

  const filteredSearchDataMeetingRoomDays: IDayResponse[] =
    getFilteredUnitsByEventType(venueDetails.days);

  const isMultiDay = venueDetails.days.length > 1;
  const isMultiRoom = (venueDetails as IMultiDayPublicVenue).days.some(
    ({rooms}) => rooms.length > 1,
  );

  const preArrivals = getPrePostAccommodation(
    venueDetails.days,
    EEventType.PRE_ARRIVAL,
  );

  const postEvents = getPrePostAccommodation(
    venueDetails.days,
    EEventType.POST_EVENT,
  );

  return (
    <SpacesListBlockWrapper>
      <div>
        {!!preArrivals.length && (
          <PrePostAccommodationBlock data={preArrivals} isPreArrival />
        )}

        {filteredSearchDataMeetingRoomDays.map((item, dayIdx) => {
          const singleBedrooms = item.bedrooms.filter(({code}) =>
            [
              EResourcesCode.SINGLE_BEDROOM,
              EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST,
            ].includes(code),
          );

          const doubleBedrooms = item.bedrooms.filter(({code}) =>
            [
              EResourcesCode.DOUBLE_BEDROOM,
              EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST,
            ].includes(code),
          );

          const flatUnits = item.rooms.flatMap(({units}) => units);
          const units =
            isMultiDay || (!isMultiDay && isMultiRoom)
              ? flatUnits.filter(({isOptimalPrice}) => isOptimalPrice)
              : flatUnits;

          const hoursTimeRange = getDayTimeRangeText(
            units.map(({checkIn, checkOut}) => ({
              timeStart: checkIn,
              timeEnd: checkOut,
            })),
            locale,
          );

          const startDate = DateUtils.getDateWithoutHours(
            item.checkIn,
            DateFormats['1 Jan 2023'],
          );

          const heading = (
            <>
              {isMultiDay || isGroups ? (
                <div>
                  <StyledParagraph>Day {dayIdx + 1}</StyledParagraph>
                  {startDate}
                </div>
              ) : (
                <>
                  <StyledParagraph>room setup</StyledParagraph>
                  <div>{startDate}</div>({hoursTimeRange})
                </>
              )}
              {!isGroups && (
                <HeadingParticipants>
                  Participants: <b>{item.maxParticipants}</b>
                </HeadingParticipants>
              )}
            </>
          );

          return (
            <SpacesListBlockInner key={item.checkIn}>
              <StyledRoomSetupFitting>{heading}</StyledRoomSetupFitting>

              {/*<SetupSeatingPlanFilter*/}
              {/*  availableSeatingPlan={availableSeatingPlans}*/}
              {/*/>*/}

              <StyledList>
                {units.map((unit, index, array) => (
                  <MeetingRoomSpaceItem
                    key={`${unit.unitId}-${index}`}
                    isMobile={isMobile}
                    currency={currency}
                    unit={unit}
                    accommodationType={accommodationType}
                    isMultiDay={isMultiDay}
                    index={index}
                    arrayLength={array.length}
                    isRfp={hasBedroom || isRfp}
                    isAlternative={isAlternative}
                    isSelectUnitHidden={
                      (!isMultiDay && isMultiRoom) || array.length < 2
                    }
                  />
                ))}

                {
                  // multiRooms &&
                  (singleBedrooms.length > 0 || doubleBedrooms.length > 0) && (
                    <AccommodationBlockItem
                      singleBedrooms={singleBedrooms}
                      doubleBedrooms={doubleBedrooms}
                    />
                  )
                }
              </StyledList>
              {isShowAllItems && !multiRooms && (
                <ButtonWrapper>
                  <NW2Button
                    size='responsiveAdaptive'
                    inverted
                    buttonType='secondary'
                    onClick={showMoreHandler}
                  >
                    show more
                  </NW2Button>
                </ButtonWrapper>
              )}
            </SpacesListBlockInner>
          );
        })}

        {!!postEvents.length && <PrePostAccommodationBlock data={postEvents} />}

        {isAlternative && isMobile && (
          <StyledDiv>
            <WhatIsNextBlock />
          </StyledDiv>
        )}
      </div>

      {!isMobile && (
        <MeetingRoomTotalPrice
          currency={currency}
          hasBedroom={hasBedroom}
          isUSA={isUSA}
        />
      )}
    </SpacesListBlockWrapper>
  );
}
