import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {DateTime} from 'luxon';
import {useDeepCompareEffect} from 'use-deep-compare';

import {IExtrasOption} from 'types/dto/IExtras.type';
import {getPreselectedFoodBeverage} from '../components/AddFoodBeverageRequest/helpers';
import {
  getPriorityRoom,
  getAccommodationParticipants,
  getPickerDate,
  getGroupEndDate,
} from '../components/utils';
import {ITimeData} from 'types/dto/ISearch.types';
import {
  getDayCopy,
  getTimeDataItem,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';

import DateUtils, {getISOString} from 'utils/dateUtils';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getPreselectedAccommodation} from '../components/AddAccommodationRequest/helpers';
import {
  TBedroomsCatering,
  THandleSetBedroomsCatering,
} from '../components/AddAccommodationRequest/types';
import {
  setEndDateForVenuesSearch,
  setMeetingRequestData as setMultiMeeting,
  setMultiSearchTimeData,
  setStartDateForVenuesSearch,
} from 'store/search/searchSlice';
import {
  searchTemplateConfig,
  TDay,
  TMeetingRoomItem,
  IUpdateParticipantsArgs,
} from 'types/search';
import {useMultiSearchData} from 'view/venue/hooks/search/useMultiSearchData';
import LocalStorageService from 'infra/common/localStorage.service';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EEventType} from 'types/venue';
import {minimumParticipantsNumber} from 'constants/app';
import {useInitialMeetingRequestData} from 'view/venue/hooks/search/useInitialMeetingRequestData';

const useSearchTemplateData = (
  timeData: ITimeData[],
  isVenuesListPage?: boolean,
) => {
  const dispatch = useAppDispatch();

  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );
  const meetingRequestDataFromStore = useAppSelector(
    ({search}) => search.meetingRequestData,
  );
  const tabsRoomType = useAppSelector(({search}) => search.tabsRoomType);
  // const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const allExtrasOptions: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const firstItemTimeData = timeData[0];
  const lastItemTimeData = timeData[timeData.length - 1];

  const meetDaysOnly = meetingRequestDataFromStore.filter(
    ({isPostMeet, isPreMeet}) => !isPostMeet && !isPreMeet,
  );

  const startDateMeet = meetDaysOnly[0]?.startDate;
  const endDateMeet = meetDaysOnly[meetDaysOnly?.length - 1]?.endDate;

  // check if initial date has been changed, except changing date range by adding pre-post events, it means we check change by date picker only
  const isDateChanged =
    (startDateMeet !== firstItemTimeData?.timeStart &&
      firstItemTimeData?.eventType === EEventType.DAY) ||
    (endDateMeet !== lastItemTimeData?.timeEnd &&
      lastItemTimeData?.eventType === EEventType.DAY);

  // reset local state meetingRequestData if date changed & preload from store if not
  const [meetingRequestData, setMeetingRequestData] = useState<TDay[]>(
    isDateChanged ? [] : [...meetingRequestDataFromStore],
  );

  const [bedroomsCatering, setBedroomsCatering] = useState<TBedroomsCatering>(
    {},
  );

  const {defaultEquipmentData, defaultSeatsSetup, equipmentFilteredOptions} =
    useMultiSearchData();

  // Constants & Functions

  const isGroupSearch = tabsRoomType === ERoomType.GROUPS;

  const preMeetDaysCount = meetingRequestData.reduce(
    (count, {isPreMeet}) => (isPreMeet ? count + 1 : count),
    0,
  );

  const maxParticipants = Math.max(
    ...meetingRequestData.flatMap(({rooms}) =>
      rooms.map((room) => room.participants),
    ),
    minimumParticipantsNumber,
  );

  const updateMultiSearchData = (
    updatedState: TDay[],
    updatedTimeData: ITimeData[],
  ) => {
    LocalStorageService.setByKey(
      'multiSearchData',
      JSON.stringify({
        meetingRequestData: updatedState,
        timeData: updatedTimeData,
      }),
    );

    dispatch(setMultiSearchTimeData(updatedTimeData));
    dispatch(setMultiMeeting(updatedState));
  };

  const getUpdatedFoodBeverageData = useCallback(
    (
      rooms: TMeetingRoomItem[],
      dayIndex: number,
      isPreselectedCase?: boolean,
    ) => {
      const {participants, timeStart, timeEnd} = getPriorityRoom(rooms);
      const duration = DateUtils.getTotalHours(timeStart, timeEnd);
      const currentFoodAndBeverageData =
        meetingRequestData[dayIndex].foodBeverageData;

      const {ids, data, options} = getPreselectedFoodBeverage(
        duration,
        participants,
        allExtrasOptions,
      );

      return {
        ids: isPreselectedCase ? ids : currentFoodAndBeverageData?.ids || [],
        options,
        data: isPreselectedCase
          ? data
          : {
              ...data,
              ...currentFoodAndBeverageData?.ids.reduce(
                (prev, curr) => ({
                  ...prev,
                  [`${curr}_`]:
                    currentFoodAndBeverageData?.data[`${curr}_`] ||
                    participants,
                }),
                {},
              ),
              foodAndBeverage: currentFoodAndBeverageData?.data.foodAndBeverage,
            },
      };
    },
    [allExtrasOptions, meetingRequestData],
  );
  // Handlers

  const addDay = (isPreMeet: boolean) => () => {
    const dayIndex = isPreMeet ? 0 : meetingRequestData.length - 1;
    const updatedTimeData = structuredClone(timeData);
    let updatedState: TDay[];

    const updatedMeetingRequestData = meetingRequestData.reduce<TDay[]>(
      (acc, item) => {
        if (item) {
          acc.push({
            ...item,
            isSectionExpanded: false,
          });
        }

        return acc;
      },
      [],
    );

    if (isPreMeet) {
      updatedState = [
        getDayCopy(updatedMeetingRequestData, dayIndex, -1),
        ...updatedMeetingRequestData,
      ];

      dispatch(
        setStartDateForVenuesSearch(getPickerDate(updatedState, 'start')),
      );
      updatedTimeData.unshift(getTimeDataItem(timeData[dayIndex], -1));
    } else {
      updatedState = [
        ...updatedMeetingRequestData,
        getDayCopy(updatedMeetingRequestData, dayIndex, 1),
      ];

      const endDate = getGroupEndDate(updatedState, isGroupSearch);

      dispatch(setEndDateForVenuesSearch(endDate));
      updatedTimeData.push(getTimeDataItem(timeData[dayIndex], 1));
    }

    setMeetingRequestData(updatedState);
    updateMultiSearchData(updatedState, updatedTimeData);
  };

  const addRoom = (dayIndex: number) => () => {
    const lastIndexOfRoom = meetingRequestData[dayIndex].rooms.length - 1;
    const copyOfLastRoom = {
      ...meetingRequestData[dayIndex].rooms[lastIndexOfRoom],
      timeStart: timeData[dayIndex].timeStart,
      timeEnd: timeData[dayIndex].timeEnd,
    };

    const updatedState = meetingRequestData.map((day) => {
      const updatedRooms = [...day.rooms, {...copyOfLastRoom}];

      const updatedDay = {
        ...day,
        rooms: updatedRooms,
      };

      return day.startDate === meetingRequestData[dayIndex].startDate
        ? updatedDay
        : day;
    });

    setMeetingRequestData(updatedState);
  };

  const deleteRoom = (dayIndex: number) => (roomIndex: number) => {
    const updatedTimeData = structuredClone(timeData);

    const updatedState = meetingRequestData.map((day, index) => {
      const filteredRooms = day.rooms.filter((_, index) => index !== roomIndex);

      const updatedFoodBeverage =
        !filteredRooms.length || !day.foodBeverageData
          ? null
          : getUpdatedFoodBeverageData(filteredRooms, index);

      return day.startDate === meetingRequestData[dayIndex].startDate
        ? {
            ...day,
            rooms: filteredRooms,
            foodBeverageData: updatedFoodBeverage,
          }
        : day;
    });

    const isDayDelete = updatedState[dayIndex].rooms.length === 0;

    if (isDayDelete) {
      const getFilteredArray = <T,>(array: T[]) => {
        return array.filter((_, index) => dayIndex !== index);
      };

      const filteredState = getFilteredArray(updatedState);
      const filteredTimeData = getFilteredArray(updatedTimeData);

      dispatch(
        setStartDateForVenuesSearch(getPickerDate(filteredState, 'start')),
      );
      dispatch(setEndDateForVenuesSearch(getPickerDate(filteredState, 'end')));

      updateMultiSearchData(filteredState, filteredTimeData);
      setMeetingRequestData(filteredState);
      return;
    }

    updateMultiSearchData(updatedState, updatedTimeData);
    setMeetingRequestData(updatedState);
  };

  const deleteAllRoom = (dayIndex: number) => () => {
    const updatedState = meetingRequestData.map((day) => {
      const participants = getAccommodationParticipants(day);

      return day.startDate === meetingRequestData[dayIndex].startDate
        ? {...day, participants, rooms: []}
        : day;
    });

    setMeetingRequestData(updatedState);
  };

  const deleteDay = (dayIndex: number, isDayToDelete: boolean) => () => {
    if (!isDayToDelete) return;

    const isPreMeet = dayIndex === 0;
    const updatedTimeData = structuredClone(timeData);

    const updatedState = meetingRequestData.filter(
      (_, index) => index !== dayIndex,
    );

    if (isPreMeet) {
      dispatch(
        setStartDateForVenuesSearch(getPickerDate(updatedState, 'start')),
      );
      updatedTimeData.shift();
    } else {
      const endDate = getGroupEndDate(updatedState, isGroupSearch);
      dispatch(setEndDateForVenuesSearch(endDate));

      updatedTimeData.pop();
    }

    updateMultiSearchData(updatedState, updatedTimeData);
    setMeetingRequestData(updatedState);
  };

  const deleteFoodBeverage = (dayIndex: number) => () => {
    const updatedState = meetingRequestData.map((day) =>
      day.startDate === meetingRequestData[dayIndex].startDate
        ? {...day, foodBeverageData: null}
        : day,
    );
    setMeetingRequestData(updatedState);
  };

  const setDefaultRoomList = (dayIndex: number) => () => {
    const updatedState = meetingRequestData.map((day) =>
      day.startDate === meetingRequestData[dayIndex].startDate
        ? {
            ...day,
            rooms: [
              {
                timeStart: timeData[dayIndex].timeStart,
                timeEnd: timeData[dayIndex].timeEnd,
                participants,
                equipmentData: defaultEquipmentData,
                seatsSetup: defaultSeatsSetup,
              },
            ],
            participants,
            isSectionExpanded: true,
          }
        : day,
    );

    setMeetingRequestData(updatedState);
  };

  const setSelectedFoodBeverage = useCallback(
    (dayIndex: number, checkAccommodationDayOnly?: boolean) =>
      (formData?: Record<string, any>, dayParticipants?: number) => {
        const currentDay = meetingRequestData[dayIndex];

        const isRoomsEmpty = !currentDay.rooms.length;

        const isAccommodationDayOnly = checkAccommodationDayOnly
          ? isRoomsEmpty
          : false;

        const {timeStart, timeEnd} = getPriorityRoom(currentDay.rooms);

        const participants = dayParticipants || currentDay.participants;

        const duration = DateUtils.getTotalHours(timeStart, timeEnd);

        const {ids, data, options} = getPreselectedFoodBeverage(
          duration,
          participants,
          allExtrasOptions,
          isAccommodationDayOnly,
        );

        const updatedFoodBeverageState = !formData
          ? {options, ids, data}
          : formData.foodAndBeverage?.length
          ? {
              options,
              ids: formData.foodAndBeverage,
              data: formData,
            }
          : null;

        const updatedState = meetingRequestData.map((day) =>
          day.startDate === currentDay.startDate
            ? {...day, participants, foodBeverageData: updatedFoodBeverageState}
            : day,
        );

        setMeetingRequestData(updatedState);
      },
    [allExtrasOptions, meetingRequestData],
  );

  const setIsSectionExpanded =
    (dayIndex: number) => (isSectionExpanded: boolean) => {
      const updatedState = meetingRequestData.map((day) =>
        day.startDate === meetingRequestData[dayIndex].startDate
          ? {
              ...day,
              isSectionExpanded,
            }
          : day,
      );

      setMeetingRequestData(updatedState);
    };

  const {initialMeetingRequestData, initAccommodationData} =
    useInitialMeetingRequestData();

  // accommodation
  const addAccommodation = useCallback(
    (dayIndex: number, isPreOrPostMeet: boolean, dayParticipants: number) =>
      (formData?: Record<string, any>) => {
        const {accommodationData, participants} = initAccommodationData(
          isPreOrPostMeet,
          dayParticipants,
          formData,
        );

        //count total number of rooms, and is default to participants count when there are no rooms
        const participantsFromBedrooms: number = formData?.accommodation.reduce(
          (sum: number, id: number) => {
            const key = `${id}_`;
            return sum + (formData[key] as number);
          },
          0,
        );

        setMeetingRequestData((prev) =>
          prev.map((day) =>
            day.startDate === meetingRequestData[dayIndex].startDate
              ? {
                  ...day,
                  participants: day.rooms.length
                    ? participants
                    : participantsFromBedrooms,
                  accommodationData,
                }
              : day,
          ),
        );
      },
    [initAccommodationData, meetingRequestData],
  );

  const removeAccommodation = (dayIndex: number) => () => {
    setMeetingRequestData((prev) =>
      prev.map((day) =>
        day.startDate === meetingRequestData[dayIndex].startDate
          ? {...day, accommodationData: null}
          : day,
      ),
    );
  };

  const setSelectedEquipment = useCallback(
    (dayIndex: number) =>
      (
        roomIndex: number,
        formData?: Record<string, any>,
        isAccommodationEnabled?: boolean,
        isCustomerChangedFoodAndBeverage?: boolean,
        isCustomerChangedAccommodation?: boolean,
      ) => {
        const updatedEquipment =
          formData?.equipment && roomIndex !== undefined
            ? {
                options: equipmentFilteredOptions,
                ids: formData.equipment,
                data: formData,
              }
            : defaultEquipmentData;

        const updatedParticipants = updatedEquipment.data.participants;
        const updatedState = meetingRequestData.map((day, index) => {
          let isTimeChanged = false;
          let isParticipantsChanged = false;
          const updatedRooms = day.rooms.map((room, index) => {
            if (
              room.timeEnd !== updatedEquipment.data.timeRange.timeEnd ||
              room.timeStart !== updatedEquipment.data.timeRange.timeStart
            ) {
              isTimeChanged = true;
            }

            if (
              roomIndex === index &&
              updatedParticipants !== room.participants
            ) {
              isParticipantsChanged = true;
            }

            return roomIndex === index
              ? {
                  timeStart: getISOString(
                    DateTime.fromJSDate(
                      updatedEquipment.data.timeRange.timeStart,
                    ),
                  ),
                  timeEnd: getISOString(
                    DateTime.fromJSDate(
                      updatedEquipment.data.timeRange.timeEnd,
                    ),
                  ),
                  participants: updatedParticipants,
                  equipmentData: updatedEquipment,
                  seatsSetup: updatedEquipment.data.seatsSetup,
                }
              : room;
          });

          const dayParticipants =
            updatedRooms.length && getPriorityRoom(updatedRooms).participants;
          const updatedFoodBeverageData =
            day.foodBeverageData && (isTimeChanged || isParticipantsChanged)
              ? getUpdatedFoodBeverageData(
                  updatedRooms,
                  index,
                  !isCustomerChangedFoodAndBeverage,
                )
              : day.foodBeverageData;

          const updatedAccommodationData =
            isAccommodationEnabled && !isCustomerChangedAccommodation
              ? getPreselectedAccommodation(dayParticipants)
              : day.accommodationData;

          const updatedStartDate = DateUtils.findMinMaxTime(
            updatedRooms,
            'timeStart',
            false,
          );
          const updatedEndDate = DateUtils.findMinMaxTime(
            updatedRooms,
            'timeEnd',
            true,
          );

          return day.startDate === meetingRequestData[dayIndex].startDate
            ? {
                ...day,
                participants: dayParticipants,
                accommodationData: updatedAccommodationData,
                foodBeverageData: updatedFoodBeverageData,
                startDate: updatedStartDate,
                endDate: updatedEndDate,
                rooms: updatedRooms,
              }
            : day;
        });

        setMeetingRequestData(updatedState);
      },
    [
      meetingRequestData,
      setMeetingRequestData,
      defaultEquipmentData,
      equipmentFilteredOptions,
      getUpdatedFoodBeverageData,
    ],
  );

  const handleSetBedroomsCatering: THandleSetBedroomsCatering = ({
    roomType,
    name,
    preName,
    qty,
    isEnabled,
    date,
  }) => {
    setBedroomsCatering((prev) => {
      const updatedBedroomsCatering = {
        ...prev,
        [date]: {
          ...prev[date],
          [roomType]: {
            ...prev[date]?.[roomType],
            name: name || prev[date]?.[roomType]?.name,
            preName: preName || prev[date]?.[roomType]?.preName,
            qty: qty ?? prev[date]?.[roomType]?.qty,
            isEnabled: isEnabled ?? prev[date]?.[roomType]?.isEnabled,
          },
        },
      };

      LocalStorageService.setByKey(
        'bedroomsCatering',
        JSON.stringify(updatedBedroomsCatering),
      );

      return updatedBedroomsCatering;
    });
  };

  const removeBedroomsCatering = (date: string) => {
    //update localStorage bedroomsCatering according to day delete
    const bedroomsCatering: TBedroomsCatering = JSON.parse(
      LocalStorageService.getByKey('bedroomsCatering') as string,
    );
    delete bedroomsCatering[date];

    LocalStorageService.setByKey(
      'bedroomsCatering',
      JSON.stringify(bedroomsCatering),
    );

    setBedroomsCatering((prev) => {
      const newState = {...prev};
      delete newState[date];
      return newState;
    });
  };

  const updateParticipants = useCallback(
    ({
      dayIndex,
      participants,
      isFoodBeverageEnabled,
      isCustomerChangedFoodAndBeverage,
      isAccommodationEnabled,
      isCustomerChangedAccommodation,
      formData,
    }: IUpdateParticipantsArgs) => {
      const updatedState = meetingRequestData.map((day, index) =>
        index === dayIndex
          ? {
              ...day,
              participants,
            }
          : day,
      );
      setMeetingRequestData(updatedState);
      if (isFoodBeverageEnabled && !isCustomerChangedFoodAndBeverage) {
        setSelectedFoodBeverage(dayIndex)(undefined, participants);
      }
      if (isAccommodationEnabled && !isCustomerChangedAccommodation) {
        addAccommodation(dayIndex, false, participants)(formData);
      }
    },
    [
      meetingRequestData,
      setMeetingRequestData,
      setSelectedFoodBeverage,
      addAccommodation,
    ],
  );

  //provide initial timeData for component on first render, with fetched equipmentData

  useDeepCompareEffect(() => {
    if (meetingRequestData.length || !allExtrasOptions.length) return;

    // display current search data in template after edit
    // TODO: we only consider dates changes in date picker here and we are not providing previous
    // TODO: values for groups. Need to provide real search values from ResultList picker

    if (
      isVenuesListPage &&
      !isDateChanged &&
      meetingRequestDataFromStore.length &&
      !isGroupSearch
    ) {
      setMeetingRequestData(meetingRequestDataFromStore);
      return;
    }

    const config = searchTemplateConfig[tabsRoomType];

    if (config) {
      setMeetingRequestData(initialMeetingRequestData);
    }
  }, [
    allExtrasOptions.length,
    initialMeetingRequestData,
    isDateChanged,
    isGroupSearch,
    isVenuesListPage,
    meetingRequestData.length,
    meetingRequestDataFromStore,
    tabsRoomType,
  ]);

  return {
    addDay,
    addRoom,
    deleteDay,
    deleteRoom,
    deleteAllRoom,
    deleteFoodBeverage,
    setDefaultRoomList,
    setSelectedEquipment,
    setSelectedFoodBeverage,
    setIsSectionExpanded,
    meetingRequestData,
    addAccommodation,
    removeAccommodation,
    bedroomsCatering,
    maxParticipants,
    preMeetDaysCount,
    isGroupSearch,
    handleSetBedroomsCatering,
    removeBedroomsCatering,
    updateParticipants,
  };
};

export default useSearchTemplateData;
