import React, {useEffect, useState, Fragment} from 'react';
import styled from 'styled-components';

import {useAppSelector} from 'store/hooks';
import {SpaceItem} from './components/SpaceItem';
import NW2Button from 'view/components/NW2Button/NW2Button';
import useSearchData from 'view/venue/hooks/search/useSearchData';

import {IUnit} from 'types/dto/IUnit.types';

import {
  mdBp,
  lgBp,
  offsetXLg,
  offsetXXLg,
  NW2Gray500Color,
} from 'constants/styleVars';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const StyledList = styled.div`
  position: relative;

  & + * {
    margin: auto 0;
  }

  @media (min-width: ${mdBp}px) {
    margin-bottom: ${offsetXXLg};

    & + * {
      max-width: 282px;
      margin: 0 auto ${offsetXLg};
    }
  }

  @media (min-width: ${lgBp}px) {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    gap: 0 ${offsetXLg};
    margin-bottom: 0;
  }
`;

const Separator = styled.span`
  width: 1px;
  height: 100%;
  display: block;
  background-color: ${NW2Gray500Color};
`;

export function SpacesListBlock() {
  const {isDesktop, isMobile} = useAppSelector(({app}) => app.deviceType);

  const {venueDetails, venueUnits} = useVenueDetailsData();

  const {searchData} = useSearchData();

  const {meetingRoomCapacity, roomType: searchedRoomType} = searchData;
  const initItemsToShow = 8;
  const [itemsToShow, setItemsToShow] = useState(initItemsToShow);

  const allSpacesByType = venueUnits.filter(
    ({roomType}) => roomType === searchedRoomType,
  );

  const spacesTotalCount = allSpacesByType.length;
  const isShowAllItems = itemsToShow < spacesTotalCount;

  const currentSpacesData = allSpacesByType.slice(0, itemsToShow);

  const showMoreHandler = () => {
    setItemsToShow(spacesTotalCount);
  };

  useEffect(() => {
    if (isMobile) {
      setItemsToShow(initItemsToShow);
    }
  }, [isMobile]);

  if (!venueUnits.length) return null;

  return (
    <>
      <StyledList>
        {currentSpacesData.map((unit: IUnit, index, array) => {
          const isNoSingle = array.length !== 1;
          const isOdd = !(index % 2);
          return (
            <Fragment key={unit.id}>
              <SpaceItem
                isMobile={isMobile}
                currency={venueDetails.currency}
                unit={unit}
                accommodationType={venueDetails.accommodationType}
                searchedRoomCapacity={meetingRoomCapacity}
              />
              {isOdd && isNoSingle && <Separator />}
            </Fragment>
          );
        })}
      </StyledList>
      {isShowAllItems && (
        <NW2Button
          size='responsiveAdaptive'
          inverted
          fullWidth={!isDesktop}
          buttonType='secondary'
          onClick={showMoreHandler}
        >
          show more
        </NW2Button>
      )}
    </>
  );
}
