import React, {memo, useCallback} from 'react';
import {Field, useForm, useFormState} from 'react-final-form';
import styled from 'styled-components';

import {COUNTRIES} from 'constants/countries';

import {debouncedSearch} from 'view/venue/NW2VenueProfile/helpers';
import {ErrorMessage, Input} from 'view/components/NW2FormItem/components';
import {
  NW2FormItemInput,
  NW2FormItemSelect,
  NW2FormItemPhoneNumber,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  ceoNameFieldRules,
  legalPhoneFieldRules,
  emailFieldRules,
  companyNameFieldRules,
  requiredFieldRules,
} from 'utils/finalFormFieldRules';
import {ESupplierLegalDetailsFormFields, IProps} from './types';

import {
  NW2Gray600Color,
  offsetDef,
  errorColor,
  fontSizeXSm,
  fontWeightBold,
} from 'constants/styleVars';

export const FormGroup = styled.div<{columnNumber: number; gap: number}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  grid-gap: ${({gap}) => gap}px;

  > div {
    margin: 0;
  }
`;

export const StyledDiv = styled.div`
  color: ${errorColor};
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightBold};
`;

export const StyledNW2FormItemInput = styled(NW2FormItemInput)`
  margin-top: ${offsetDef} !important;
`;

const CUSTOMER_EXISTS_EMAIL_ERROR = 'Email already exists';

const countryOptions = COUNTRIES.map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const NW2SupplierLegalDetailsForm = memo(
  ({
    disabledItems,
    adaptiveColumnNumber = 1,
    gap = parseInt(offsetDef),
  }: IProps) => {
    const form = useForm();
    const state = useFormState();

    const validateEmail = useCallback(
      async (value: any, _: any, meta: any) => {
        const errors = emailFieldRules(value, _, meta);

        if (
          !errors &&
          !meta.pristine &&
          state.active === ESupplierLegalDetailsFormFields.EMAIL
        ) {
          form.pauseValidation();
          const isAlreadyExists = !(await debouncedSearch(value));
          form.resumeValidation();

          return isAlreadyExists ? CUSTOMER_EXISTS_EMAIL_ERROR : errors;
        }

        return errors;
      },
      [form, state.active],
    );

    return (
      <FormGroup columnNumber={1} gap={gap}>
        <NW2FormItemInput
          type='text'
          name={ESupplierLegalDetailsFormFields.CEO_NAME}
          placeholder='CEO*'
          label='CEO*'
          labelColor={NW2Gray600Color}
          rules={ceoNameFieldRules}
          disabled={disabledItems?.includes(
            ESupplierLegalDetailsFormFields.CEO_NAME,
          )}
          data-testid={ESupplierLegalDetailsFormFields.CEO_NAME}
        />
        <NW2FormItemPhoneNumber
          type='text'
          name={ESupplierLegalDetailsFormFields.LEGAL_PHONE}
          placeholder='+49 1234 123456'
          label='Phone number*'
          labelColor={NW2Gray600Color}
          rules={legalPhoneFieldRules}
          disabled={disabledItems?.includes(
            ESupplierLegalDetailsFormFields.LEGAL_PHONE,
          )}
          data-testid={ESupplierLegalDetailsFormFields.LEGAL_PHONE}
        />
        <Field
          name={ESupplierLegalDetailsFormFields.EMAIL}
          validate={validateEmail}
          data-testid={ESupplierLegalDetailsFormFields.EMAIL}
        >
          {({input, meta}) => {
            const isCustomerAlreadyExists =
              meta.error === CUSTOMER_EXISTS_EMAIL_ERROR;
            const hasError = meta.error && meta.touched;
            const disabled = disabledItems?.includes(
              ESupplierLegalDetailsFormFields.EMAIL,
            );

            return (
              <div>
                <Input
                  name={ESupplierLegalDetailsFormFields.EMAIL}
                  type='email'
                  label='Email address*'
                  labelColor={`${NW2Gray600Color}`}
                  placeholder='Email address'
                  hasError={hasError}
                  value={input.value}
                  onChange={input.onChange}
                  disabled={disabled}
                  inputProps={{...input, disabled}}
                />
                {!isCustomerAlreadyExists ? (
                  <ErrorMessage
                    hasError={hasError}
                    errorData={meta.error}
                    showAllValidationErrors={false}
                  />
                ) : (
                  <StyledDiv>
                    This email is already in use. Please try another email
                    address.
                  </StyledDiv>
                )}
              </div>
            );
          }}
        </Field>
        <NW2FormItemInput
          type='text'
          name={ESupplierLegalDetailsFormFields.COMPANY_NAME}
          placeholder='Managing company*'
          label='Managing company*'
          labelColor={NW2Gray600Color}
          rules={companyNameFieldRules}
          disabled={disabledItems?.includes(
            ESupplierLegalDetailsFormFields.COMPANY_NAME,
          )}
          data-testid={ESupplierLegalDetailsFormFields.COMPANY_NAME}
        />
        <NW2FormItemInput
          type='text'
          name={ESupplierLegalDetailsFormFields.TAX_NUMBER}
          placeholder='Tax number*'
          label='Tax number*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('tax number')}
          disabled={disabledItems?.includes(
            ESupplierLegalDetailsFormFields.TAX_NUMBER,
          )}
          data-testid={ESupplierLegalDetailsFormFields.TAX_NUMBER}
        />
        <StyledNW2FormItemInput
          type='text'
          name={ESupplierLegalDetailsFormFields.STREET_HOUSE_NUMBER}
          placeholder='Street and house number*'
          label='Street and house number*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('street and house number')}
          disabled={disabledItems?.includes(
            ESupplierLegalDetailsFormFields.STREET_HOUSE_NUMBER,
          )}
          data-testid={ESupplierLegalDetailsFormFields.STREET_HOUSE_NUMBER}
        />
        <NW2FormItemInput
          type='text'
          name={ESupplierLegalDetailsFormFields.POSTAL_CODE}
          placeholder='Post code*'
          label='Post code*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('post code')}
          disabled={disabledItems?.includes(
            ESupplierLegalDetailsFormFields.POSTAL_CODE,
          )}
          data-testid={ESupplierLegalDetailsFormFields.POSTAL_CODE}
        />

        <FormGroup columnNumber={adaptiveColumnNumber} gap={gap}>
          <NW2FormItemInput
            type='text'
            name={ESupplierLegalDetailsFormFields.CITY}
            placeholder='City*'
            label='City*'
            labelColor={NW2Gray600Color}
            rules={requiredFieldRules('city')}
            disabled={disabledItems?.includes(
              ESupplierLegalDetailsFormFields.CITY,
            )}
            data-testid={ESupplierLegalDetailsFormFields.CITY}
          />
          <NW2FormItemSelect
            name={ESupplierLegalDetailsFormFields.COUNTRY}
            label='Country*'
            labelColor={NW2Gray600Color}
            placeholder='Country*'
            rules={requiredFieldRules('country')}
            showAllValidationErrors
            options={countryOptions}
            data-testid={ESupplierLegalDetailsFormFields.COUNTRY}
          />
        </FormGroup>
      </FormGroup>
    );
  },
);
