import {useMemo, useCallback} from 'react';
import queryString from 'query-string';

import useSearchData from 'view/venue/hooks/search/useSearchData';

import {useNavigate} from 'react-router-dom';
import {TShortList} from 'types/offer';

interface IProps {
  shortList: TShortList;
  isMultiRequest?: boolean;
}

const useRedirectToRequest = ({shortList, isMultiRequest}: IProps) => {
  const navigate = useNavigate();

  const {querySearchData} = useSearchData();

  const multiVenueIds = useMemo(
    () => shortList.items.map(({id}) => id),
    [shortList.items],
  );

  const redirectToRequest = useCallback(() => {
    const pathname = `/venue/${String(multiVenueIds)}/request`;

    navigate({
      pathname,
      search: `${queryString.stringify(
        querySearchData,
      )}&isMultiRequest=${isMultiRequest}`,
    });
  }, [isMultiRequest, multiVenueIds, navigate, querySearchData]);

  return {redirectToRequest};
};

export {useRedirectToRequest};
