import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDeepCompareEffect} from 'use-deep-compare';
import {notification} from 'antd';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getMultiVenueDetailsMultiRequest} from 'store/venues/apiActions';
import {useActions} from 'hooks/useActions';
import {ERoomType} from 'types/dto/ERoomType.type';
import useSearchData from '../hooks/search/useSearchData';
import {
  setMeetingRequestData,
  setMultiSearchTimeData,
  setSearchCriteria,
} from 'store/search/searchSlice';
import {useSelectedRoomId} from '../hooks/useSelectedRoomId';
import {getVenueDetailsWorkDesksAction} from 'store/workDesks/apiActions';
import {setWorkDesksError} from 'store/workDesks/workDesksSlice';
import {useSearchFilterDays} from '../hooks/search/useSearchFilterDays';
import {Routes} from 'constants/routes';

interface IProps {
  venueId: number | string;
  withoutPackages?: boolean;
  withoutSearchData?: boolean;
}
export function useMultiDayVenueDetailsData({
  venueId,
  withoutPackages,
  withoutSearchData,
}: IProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {getVenueDetails, setVenueDetails} = useActions();

  const venueDetails = useAppSelector(({venueDetails}) => venueDetails.details);
  const isLoading = useAppSelector(({venueDetails}) => venueDetails.isLoading);
  const isWorkSpaceSearchLoading = useAppSelector(
    ({workDesks}) => workDesks.isLoading,
  );
  const venueDetailsWorkDeskId = useAppSelector(
    ({workDesks}) => workDesks.workDesksDetails?.id || 0,
  );
  const workSpaceError = useAppSelector(({workDesks}) => workDesks.error);
  const error = useAppSelector(({venueDetails}) => venueDetails.error);

  const {initialSearchData, querySearchData} = useSearchData();
  const {filterDays} = useSearchFilterDays();

  const roomType =
    useAppSelector(({search}) => search.searchCriteria.roomType) ||
    initialSearchData.roomType;

  const isWorkSpace = roomType === ERoomType.WORK_SPACE;

  useDeepCompareEffect(() => {
    if (withoutSearchData || isWorkSpace) return;

    // Set initial Data for search
    if (initialSearchData) {
      const {initialTimeData, meetingRequestData, ...restSearchData} =
        initialSearchData;

      dispatch(setMultiSearchTimeData(initialTimeData));
      dispatch(setMeetingRequestData(meetingRequestData));
      dispatch(setSearchCriteria(restSearchData));
    }
  }, [dispatch, initialSearchData, isWorkSpace, withoutSearchData]);

  useEffect(() => {
    if (
      isWorkSpace &&
      !isWorkSpaceSearchLoading &&
      !venueDetailsWorkDeskId &&
      !workSpaceError
    ) {
      // use search params either from query, or from redux store
      const id = Number(venueId);

      const data = {
        id,
        ...querySearchData,
      };

      dispatch(getVenueDetailsWorkDesksAction(data));
    }

    return () => {
      if (workSpaceError) {
        dispatch(setWorkDesksError(null));
      }
    };
  }, [
    dispatch,
    isWorkSpace,
    isWorkSpaceSearchLoading,
    querySearchData,
    venueDetailsWorkDeskId,
    venueId,
    workSpaceError,
  ]);
  // WORD DESK STUFF END

  const [getSelectedVenueUnitId] = useSelectedRoomId();

  useDeepCompareEffect(() => {
    if (isWorkSpace || isLoading || error || !venueId) return;

    const accommodationIds =
      typeof venueId === 'string' && venueId.includes(',')
        ? venueId.split(',').map((id) => Number(id))
        : [Number(venueId)];

    if (
      !venueDetails ||
      accommodationIds[0] !== venueDetails?.accommodationId
    ) {
      if (accommodationIds.length === 1) {
        getVenueDetails({
          payload: {
            accommodationIds: [Number(venueId)],
            filterData: filterDays.map((item) => ({
              ...item,
              roomFilters: item.roomFilters?.map((room) => {
                const selectedUnitId = getSelectedVenueUnitId({
                  venueId: Number(venueId),
                  checkIn: room.checkIn,
                });

                return {
                  ...room,
                  ...(selectedUnitId ? {unitId: selectedUnitId} : {}),
                };
              }),
            })),
            withoutPackages,
          },
          onErrorCallback(error: Error) {
            navigate({
              pathname: Routes.mainLanding,
            });
            notification.error({
              message: error?.message || 'Something went wrong',
              duration: 4,
            });
          },
        });
      } else {
        if (!filterDays.length) return;

        const multiData = accommodationIds.map((id) => ({
          accommodationIds: [id],
          filterData: filterDays,
          withoutPackages,
        }));

        dispatch(getMultiVenueDetailsMultiRequest(multiData));
      }
    }
  }, [
    dispatch,
    error,
    filterDays,
    getSelectedVenueUnitId,
    getVenueDetails,
    isLoading,
    isWorkSpace,
    navigate,
    venueDetails,
    venueId,
    withoutPackages,
  ]);

  useEffect(() => {
    return () => {
      setVenueDetails(null);
    };
  }, [setVenueDetails]);
}
