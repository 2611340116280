import {useMemo} from 'react';

import {setInitialTimeRange} from 'view/components/NW2Datepicker';
import {useQuery} from 'hooks/useQuery';
import {
  DEFAULT_CHECK_IN,
  DEFAULT_CHECK_OUT,
} from 'constants/defaultOperationalTimes';
import {useBedroomsCatering} from './useBedroomsCatering';
import {useStorageMultiSearchData} from './useStorageMultiSearchData';
import {useSearchFilterDays} from './useSearchFilterDays';

function useInitialMultiRoomData() {
  const {multiRooms, startDate, endDate} = useQuery();

  const [bedroomsCatering] = useBedroomsCatering();
  const [parsedMultiSearchData] = useStorageMultiSearchData();

  const {meetingRequestData = [], timeData} = parsedMultiSearchData;

  const initialTimeData = useMemo(
    () =>
      setInitialTimeRange({
        startTime: (startDate as string) || DEFAULT_CHECK_IN,
        endTime: (endDate as string) || DEFAULT_CHECK_OUT,
        isMultiSearchRoom: Boolean(multiRooms),
        timeData,
      }),
    [endDate, multiRooms, timeData, startDate],
  );

  const {filterDays} = useSearchFilterDays();

  return {
    multiSearchPayload: filterDays,
    meetingRequestData,
    isMultiSearchRoom: multiRooms,
    initialTimeData,
    bedroomsCatering,
  };
}

export default useInitialMultiRoomData;
