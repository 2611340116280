import React, {memo} from 'react';
import {IBookedExtra} from 'types/venue';

import {getExtraNumericInputVisibility} from 'utils/helpers';

import {
  Extras,
  ExtrasContainer,
  ExtrasName,
  ExtrasTitle,
  PricePerRoom,
  StyledSpan,
} from '../BookingDetails.styles';
import {offsetDef} from 'constants/styleVars';
import {EBedroomExtrasNames, EResourcesType} from 'types/dto/IExtras.type';
import {BEDROOMS_NAMES_FROM_BE} from 'constants/bedrooms';
import {ISummaryExtra} from 'types/dto/IBooking.types';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

export const BookingUnitExtras = memo(
  ({
    data,
    currency,
    isCustomerBookedPackage,
    isBedroom,
  }: {
    data: IBookedExtra[] | ISummaryExtra[];
    currency?: string;
    isCustomerBookedPackage?: boolean;
    isBedroom?: boolean;
  }) => (
    <>
      {isCustomerBookedPackage && data.length !== 0 && (
        <ExtrasTitle margin={`${offsetDef} 0`}>
          Additional Equipment
        </ExtrasTitle>
      )}
      {data.map((extra) => {
        const {bookedQuantity, extraName, totalPrice, extraType, code} = extra;

        const isQuantityVisible = getExtraNumericInputVisibility(extraName);

        const pricePerRoom =
          totalPrice && bookedQuantity && totalPrice / bookedQuantity;
        const isPricePerRoom = isBedroom && pricePerRoom !== 0;

        return (
          <ExtrasContainer isPricePerRoom={isPricePerRoom} key={code}>
            <Extras
              isQuantityVisible={isQuantityVisible}
              isPricePerRoom={isPricePerRoom}
            >
              {isQuantityVisible && (
                <>
                  <span>{bookedQuantity}</span>
                  <span>x</span>
                </>
              )}
              <ExtrasName isQuantityVisible={isQuantityVisible}>
                {extraType === EResourcesType.BEDROOM
                  ? BEDROOMS_NAMES_FROM_BE[extraName as EBedroomExtrasNames]
                  : extraName}
              </ExtrasName>
              <StyledSpan>
                {totalPrice !== 0 || isBedroom ? (
                  <PriceDisplay price={totalPrice} currency={currency} />
                ) : (
                  'Free'
                )}
              </StyledSpan>
            </Extras>
            {isPricePerRoom && (
              <PricePerRoom>
                <PriceDisplay price={pricePerRoom} currency={currency} /> / room
              </PricePerRoom>
            )}
          </ExtrasContainer>
        );
      })}
    </>
  ),
);
