import React from 'react';

import {EBookingDetailsType, IUnitBookingDayData} from 'types/bookingOrders';
import {EBookingStatus} from 'types/booking';
import {EGroupStatus, EOfferStatus, ERequestStatus} from 'types/offer';
import {EXPIRES_IN_DAYS} from 'view/venue/Offer/constants';
import {IDetailsObject} from './components/Status/types';
import {IOrderDay} from 'types/dto/IBooking.types';
import {FULL_FREE_OF_CHARGE} from 'constants/app';

export const getMinMaxDates = (dates: string[]) => {
  if (!dates.length) return {min: '', max: ''};
  const min = dates.reduce((a, b) => (a < b ? a : b));
  const max = dates.reduce((a, b) => (a > b ? a : b));

  return {
    min,
    max,
  };
};

export const getDays = (dates: string[]) => {
  return [
    ...new Set(
      dates
        .map((item: string) => item.split('').splice(0, 10).join(''))
        .sort((a: string, b: string) => Date.parse(a) - Date.parse(b)),
    ),
  ];
};

export const getDateDay = (date: string): string =>
  date.split('').slice(0, 10).join('');

export const getArrayOfUnitsFromOrderDays = (orderDays: IOrderDay[]) => {
  return orderDays.reduce(
    (result: Record<string, IUnitBookingDayData>, orderDay) => {
      const {date, foodAndBeverage, bedrooms, unitBookings, totalPriceForDay} =
        orderDay;
      const day = getDateDay(date);

      return {
        ...result,
        [day]: {
          accommodations: bedrooms,
          units: unitBookings,
          daySubTotal: totalPriceForDay,
          foodAndBeverage,
        },
      };
    },
    {},
  );
};

export const getBookingDetailsType = (
  isSingle: boolean,
  isRfp: boolean,
  bookingStatus: EBookingStatus,
) => {
  if (isRfp) {
    if (isSingle) {
      if (bookingStatus === EBookingStatus.RFP_DECLINED)
        return EBookingDetailsType.SINGLE_REQUEST_DECLINED;
      if (bookingStatus === EBookingStatus.RFP_CANCELLED)
        return EBookingDetailsType.SINGLE_REQUEST_CANCELLED_BOOKER;
      if (bookingStatus === EBookingStatus.RFP_EXPIRED)
        return EBookingDetailsType.SINGLE_REQUEST_EXPIRED;
      return EBookingDetailsType.SINGLE_REQUEST;
    } else {
      if (bookingStatus === EBookingStatus.RFP_DECLINED)
        return EBookingDetailsType.MULTI_REQUEST_DECLINED;
      if (bookingStatus === EBookingStatus.RFP_CANCELLED)
        return EBookingDetailsType.MULTI_REQUEST_CANCELLED_BOOKER;
      if (bookingStatus === EBookingStatus.RFP_EXPIRED)
        return EBookingDetailsType.MULTI_REQUEST_EXPIRED;
      return EBookingDetailsType.MULTI_REQUEST;
    }
  }

  return isSingle ? EBookingDetailsType.SINGLE : EBookingDetailsType.MULTI;
};

export const hiddenBillingAddressReasons: Partial<
  Record<EBookingStatus, string>
> = {
  [EBookingStatus.RFP_CANCELLED]: `The customer has cancelled this request before it could be confirmed by you.
  This means that the cancellation policy applied was not binding and no invoice needs to be generated.`,
  [EBookingStatus.RFP_DECLINED]: `You have declined this request. This means that the cancellation policy
  applied was not binding and no invoice needs to be generated.`,
  [EBookingStatus.RFP_EXPIRED]: `You have never responded to this request.
  This means that the cancellation policy applied was not binding and no invoice needs to be generated.`,
  [EBookingStatus.RFP_PENDING]: `We have captured and saved customer’s billing address. This information will become
  visible once, and only if, this request is confirmed.`,
};

export const getRoomStatusDetails = (
  isHrsPolicy?: boolean,
  freeOfCharge?: number,
): Partial<
  Record<EBookingStatus | ERequestStatus | EOfferStatus, IDetailsObject>
> => ({
  [EBookingStatus.CONFIRMED]: {
    title: 'Confirmed',
    description: `Please arrange payment directly with the booker.
    Extras beyond the booked services can and should be charged separately on-site.`,
    totalTitle: 'Booking total',
  },
  [EBookingStatus.PASSED]: {
    title: 'Past booking',
    description: `This event is now completed. You can use booker’s billing address to generate an invoice.`,
    totalTitle: 'Total charged',
  },
  [EBookingStatus.CANCELED]: {
    title: 'Booking Cancelled',
    totalTitle: 'Modification and Cancellation Conditions',
    description: isHrsPolicy
      ? freeOfCharge === FULL_FREE_OF_CHARGE
        ? 'As the cancellation was made within the free cancellation period, no fee will be charged.'
        : 'You may charge a cancellation fee, as the booking was cancelled outside the free cancellation period. Please use the booker’s billing address for invoicing.'
      : 'You may charge a cancellation fee, if the booking was cancelled outside the free cancellation period. Please use the booker’s billing address for invoicing.',
  },
  [EBookingStatus.RFP_CANCELLED]: {
    title: 'Request cancelled',
    description:
      'Customer has cancelled this request. Since this request was never confirmed no cancellation fee can be applied.',
    totalTitle: 'Time from requested to cancelled:',
  },
  [EBookingStatus.RFP_DECLINED]: {
    title: 'Declined',
    description:
      'Since this request was never confirmed the customer was not charged.',
    totalTitle: 'Time from requested to cancelled:',
  },
  [EBookingStatus.RFP_EXPIRED]: {
    title: 'Request expired',
    description: `You did not respond to this request in time. Make sure to respond to booking 
      requests, as this has a direct affect on your average response time. Your average response 
      time is displayed to bookers on your venue’s profile.`,
    totalTitle: 'Business lost',
  },
  [EBookingStatus.RTC_PENDING]: {
    title: 'Booking change request',
    description: `The booker has requested some changes to your booking. Please check the changes carefully and confirm. View booking change request`,
    totalTitle: 'New total',
  },

  // Request Alternatives
  [ERequestStatus.REQUEST_DECLINED]: {
    title: 'Request Declined',
  },
  [ERequestStatus.REQUEST_CANCELED]: {
    title: 'Request cancelled',
    description:
      'Customer has cancelled this request. Since this request was never confirmed the customer was not charged.',
    totalTitle: 'Time from requested to cancelled:',
  },
  [ERequestStatus.REQUEST_EXPIRED]: {
    title: 'Request expired',
    description: (
      <>
        <p>
          Unfortunately this request has been cancelled because you were unable
          to respond within <b>{EXPIRES_IN_DAYS}</b> of receiving the request.
        </p>
        <p>
          We understand that you can’t accommodate everyone and so do the
          bookers.
        </p>
        <p>
          In the future please decline requests you’re unable to accommodate,
          this will help bookers make alternative plans and help your venue keep
          a positive response time.
        </p>
      </>
    ),
    totalTitle: 'Business lost',
  },

  // Offers
  [EOfferStatus.OFFER_PENDING]: {
    title: 'Offer sent',
    description: `Good news! This offer has now been sent to the customer. The customer
      has up until their option date to respond. If they do not respond within
      this time, the offer will expire and will no longer be bookable.`,
    totalTitle: 'Offer total',
  },
  [EOfferStatus.OFFER_DECLINED]: {
    title: 'Offer declined',
  },
  [EOfferStatus.OFFER_ACCEPTING_EXPIRED]: {
    title: 'Offer expired',
    description: `Unfortunately, the booker has not responded to the offer in time and the offer is now invalid. 
    If the booker changes their mind, they can request the same offer again. You can then choose to confirm or decline this new request.`,
  },
});

export const checkHistoryOffersByStatus = (
  status: ERequestStatus | EOfferStatus | EGroupStatus,
) =>
  [EOfferStatus.OFFER_DECLINED, EOfferStatus.OFFER_ACCEPTING_EXPIRED].includes(
    status as EOfferStatus,
  );

export const checkUpcomingOffersByStatus = (
  status: ERequestStatus | EOfferStatus | EGroupStatus,
) =>
  [EOfferStatus.OFFER_PENDING, EOfferStatus.OFFER_CONFIRMED].includes(
    status as EOfferStatus,
  );
