import React from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Navbar from 'view/common/Navbar';

import linkedin from 'img/icons/linkedin-in.svg';
import youtube from 'img/icons/youtube.svg';
import withBridgeBTC from 'utils/bridgeBTC/withBridgeBTC';
import {HRS_LOGO_URL} from 'constants/app';
import {ENW2MultiModalActiveComponentId} from 'types/app';
import {Routes} from 'constants/routes';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {getIsExactPath} from 'view/common/Header/helpers';
import {
  NW2FooterBlock,
  NW2FooterContainer,
  StyledNavLink,
  FooterRow,
  NW2FooterLinkBlock,
  StyledLink,
} from 'view/common/NW2Footer/NW2Footer.styles';
import {fontWeightBold, sectionInsetXs} from 'constants/styleVars';
import {setNW2MultiModalComponent} from 'store/app/appSlice';
import {useAppSelector} from 'store/hooks';
import {
  useOpenFAQModal,
  useOpenPrivacyPolicyModal,
  useOpenTermsAndConditionsModal,
} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {RouteValues} from '../Header/types';

const NW2FooterHiddenRoutes = [
  Routes.requestDecline,
  Routes.requestDeclineSuccess,
  Routes.shortListOverview,
  Routes.compareOverview,
];
const NW2NavbarHiddenRoutes = [
  Routes.venueDetails,
  Routes.bookVenuePreview,
  Routes.bookEdit,
  Routes.venuesList,
  Routes.offerRequest,
  Routes.offerRequestOverview,
  Routes.offerReview,
  Routes.shortListOverview,
  Routes.compareOverview,
];
const NW2MobileFooterHiddenRoutes = [Routes.venuesList];
const NW2RoleSupplierRoutes = [Routes.registerVenue, Routes.createPublicVenue];

const currentYear = new Date().getFullYear();

type TProps = {
  showFooter?: boolean;
  showNavbar?: boolean;
};
function NW2Footer({showFooter, showNavbar = true}: TProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname as RouteValues;
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const userRole = useAppSelector(({app}) => app.user.role);

  const shouldHideFooter = getIsExactPath(NW2FooterHiddenRoutes, pathname);
  const shouldHideNavbar = getIsExactPath(NW2NavbarHiddenRoutes, pathname);
  const shouldHideFooterOnMobile =
    getIsExactPath(NW2MobileFooterHiddenRoutes, pathname) && isMobile;

  const openCookieSettings = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    (window as any).Optanon && (window as any).Optanon.ToggleInfoDisplay();
  };

  const shouldShowNavbarByRole =
    userRole === EUserRoleCognito.ROLE_CUSTOMER ||
    userRole === EUserRoleCognito.ROLE_GUEST;

  const bottomOffset =
    shouldShowNavbarByRole && !showNavbar ? sectionInsetXs : '';

  const openImprint = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    dispatch(
      setNW2MultiModalComponent({
        id: ENW2MultiModalActiveComponentId.IMPRINT_MODAL,
      }),
    );
  };

  const role = getIsExactPath(NW2RoleSupplierRoutes, pathname)
    ? EUserRoleCognito.ROLE_SUPPLIER
    : undefined;

  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal(role);
  const openPrivacyPolicyModal = useOpenPrivacyPolicyModal(role);
  const openFAQModal = useOpenFAQModal(role);

  // Set visibility variable for footer and mobile navbar
  const isShowed =
    showFooter || (!shouldHideFooter && !shouldHideFooterOnMobile);
  const isNavbarOnMobile =
    isMobile && !shouldHideNavbar && shouldShowNavbarByRole && showNavbar;

  return (
    <>
      {isShowed && (
        <NW2FooterContainer bottomOffset={bottomOffset} id='footer'>
          <NW2Container>
            <FooterRow>
              {/* Left block */}
              <NW2FooterBlock>
                <StyledNavLink
                  to={HRS_LOGO_URL}
                  target='_blank'
                  rel='noreferrer noopener'
                  fontWeight={fontWeightBold}
                >
                  <span>© HRS GmbH {currentYear}</span>
                </StyledNavLink>
              </NW2FooterBlock>

              {/* Center block */}
              <NW2FooterLinkBlock>
                <StyledLink onClick={openPrivacyPolicyModal}>
                  Privacy Policy
                </StyledLink>

                <StyledLink onClick={openTermsAndConditionsModal}>
                  Terms and conditions
                </StyledLink>

                <StyledLink onClick={openCookieSettings}>Cookies</StyledLink>

                <StyledLink onClick={openImprint}>Imprint</StyledLink>

                <StyledLink onClick={openFAQModal}>FAQ</StyledLink>
              </NW2FooterLinkBlock>

              {/* Right block */}
              <NW2FooterBlock>
                <a
                  href='https://www.linkedin.com/showcase/hrs'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img alt='LinkedIn' src={linkedin} />
                </a>

                <a
                  href='https://www.youtube.com/channel/UCBaSB7S1QnvoJdPXC67FMxA'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img alt='YouTube' src={youtube} />
                </a>
              </NW2FooterBlock>
            </FooterRow>
          </NW2Container>
        </NW2FooterContainer>
      )}

      {isNavbarOnMobile && <Navbar />}
    </>
  );
}

export default withBridgeBTC(NW2Footer);
