import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import _get from 'lodash/get';
import styled from 'styled-components';

import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import {
  AnchorContentBlock,
  ShareButton,
  VenueImages,
} from '../NW2VenueDetails/components';

import useSearchData from '../hooks/search/useSearchData';
import {useQuery} from 'hooks/useQuery';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {
  getVenueDetailsFailure,
  setIsVenueDetailsMapVisible,
} from 'store/venues/venuesSlice';
import {getAnchorContentSections} from '../NW2VenueDetails/components/AnchorBlock/helpers';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {
  HeroHeaderContainer,
  SectionDark,
  SectionLight,
} from '../NW2VenueDetails/NW2VenueDetails.styles';
import {EFrontendErrors} from 'types/errors';
import {EIgnoreInterceptorCodes} from 'utils/axiosInterceptors/types';
import {useStoredSearchCriteria} from '../hooks/search/useStoredSearchCriteria';
import {useVenueDetailsId} from '../hooks/useVenueDetailsId';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {useMultiDayVenueDetailsData} from '../NW2VenueDetails/useMultiDayVenueDetails';
import {
  AddressBlock,
  Header,
  StyledLink,
  VenueOffersContainer,
  VenueOfferTitle,
} from '../NW2VenueDetails/components/VenueOffers/VenueOffers.styles';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {EMPTY_OBJECT} from 'constants/app';
import {offsetLg} from 'constants/styleVars';

const PreviewTittleWrapper = styled.div`
  margin-bottom: ${offsetLg};
`;

// const NWLinkContainer = styled.div`
//   position: absolute;
//   top: ${offsetXLg};
//   left: ${offsetXXLg};
//   z-index: 1;
// `;

// Back button is hidden, because of issue with loop
function NW2VenuePreview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {paramsVenueId} = useParamsVenueId();
  useMultiDayVenueDetailsData({venueId: paramsVenueId});

  // const {search} = useLocation();
  const {showWhatsAround} = useQuery();

  const error: unknown = useSelector((state) =>
    _get(state, 'venuesReducer.error'),
  );

  const {venueDetails, isVenueDetailsLoading} = useVenueDetailsData();
  const [venueDetailsId] = useVenueDetailsId();

  const amenities = venueDetails.amenities;

  const {querySearchData} = useSearchData();
  useStoredSearchCriteria({querySearchData});

  // const onBackClick = () => {
  //   const path = groupId
  //     ? `/compare-overview/${groupId}`
  //     : `/shortlist-overview`;
  //   const querySearch = groupId ? '' : search;
  //   navigate({pathname: path, search: querySearch});
  // };

  const anchorContentSections = getAnchorContentSections({
    isAlternativeSectionVisible: false,
    amenities,
    isDetailsSectionVisible: !!venueDetailsId,
    alternativeList: [],
  });

  // Open what's around map
  useEffect(() => {
    if (showWhatsAround) {
      dispatch(setIsVenueDetailsMapVisible(true));
    }
  }, [dispatch, showWhatsAround]);

  // Navigate back if user has no access to this venue
  useEffect(() => {
    if (
      error ===
        EIgnoreInterceptorCodes.FORBIDDEN_ACCESS_TO_VENUE_DETAILS_ERROR ||
      error === EIgnoreInterceptorCodes.UNAUTHORIZED_NO_ACCESS_DATA_ERROR
    ) {
      // Set ACCESS_TO_VENUE_DENIED_ERROR error to show no access block in venues list page
      dispatch(
        getVenueDetailsFailure(EFrontendErrors.ACCESS_TO_VENUE_DENIED_ERROR),
      );

      navigate(-1);
    }
  }, [error, dispatch, navigate, querySearchData]);

  const {isAlternative} = useVenueDetailsData();

  const address = getAddressStringFromLocation(
    venueDetails.location || EMPTY_OBJECT,
    true,
  );

  const searchData = useAppSelector(({search}) => search.searchCriteria);

  const openVenueDetailsMap = () => dispatch(setIsVenueDetailsMapVisible(true));

  if (!venueDetailsId || isVenueDetailsLoading)
    return <NW2Loader height='100%' />;

  return (
    <>
      <SectionDark>
        <HeroHeaderContainer>
          {/* <NWLinkContainer>
            <NW2Button
              minimized
              inverted
              buttonType='primary'
              size='small'
              onClick={onBackClick}
              icon={<Icon transparent icon='NW2_MOVE_BACK' />}
            />
          </NWLinkContainer> */}
          <VenueImages />
        </HeroHeaderContainer>

        <NW2Container>
          <VenueOffersContainer>
            <Header>
              <PreviewTittleWrapper>
                <VenueOfferTitle>{venueDetails.name}</VenueOfferTitle>
                <AddressBlock isAlternative={isAlternative}>
                  <StyledLink onClick={openVenueDetailsMap}>
                    {address}
                  </StyledLink>
                  <ShareButton
                    venueDetails={venueDetails}
                    searchData={searchData}
                  />
                </AddressBlock>
              </PreviewTittleWrapper>
            </Header>
          </VenueOffersContainer>
        </NW2Container>
      </SectionDark>

      <SectionLight>
        <AnchorContentBlock anchorContentSections={anchorContentSections} />
      </SectionLight>
    </>
  );
}

export default NW2VenuePreview;
