export enum EFrontendErrors {
  'ACCESS_TO_VENUE_DENIED_ERROR' = 'ACCESS_TO_VENUE_DENIED_ERROR',
}
export enum ECognitoErrorsCodes {
  BAD_REQUEST_DECODE_JWT_TOKEN = 'OFR-401', //401.An error occurred while attempting to decode Jwt
}

export interface ICognitoError {
  code: string;
  name: string;
  message: string;
}

export enum ICognitoExceptionCode {
  'NotAuthorizedException' = 'NotAuthorizedException',
}
