import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {StepExtrasPrices} from './components/StepExtrasPrices';
import {StepHint} from './components/StepHint';
import {OfferCreateLayout} from './components/OfferCreateLayout';
import {LastStep} from './components/LastStep';

import {PageContent} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {
  ECreateOfferFormFields,
  ICommonProps,
  TCapacityType,
  TPreviewState,
} from './types';
import {getRoomRentalPriceByDateRange} from 'utils/venueUtils';
import {nameFieldRules} from 'utils/finalFormFieldRules';
import {EEventType} from 'types/venue';
import {IExtrasOption} from 'types/dto/IExtras.type';
import useSpaces from 'view/venue/hooks/useSpaces';
import DateUtils from 'utils/dateUtils';
import {offsetDef} from 'constants/styleVars';
import {StepBedroomsPrices} from './components/StepBedroomsPrices';
import {getBedroomsFromState} from './helpers';
import {useInitialData} from './hooks/useInitialData';
import {usePaidDurationOptions} from '../../../NW2VenueProfile/components/AddSpace/hooks/usePaidDurationOptions';
import {getFlatMapUnits} from 'view/customer/helpers';
import {getCoverImage} from 'view/venue/helpers/spaces';
import {IRequestDayItem} from 'types/offer';
import {useOfferCreateData} from './hooks/useOfferCreateData';
import {parseStringToNumber} from 'utils/stringUtils';
import {useEmptyExtrasList} from './hooks/useEmptyExtrasList';
import {useOfferSubmit} from './hooks/useOfferSubmit';

/**
 * step titles
 * we handle the active step outside the form wizard by the title of this step
 */
const EQUIPMENT_PRICES_TITLE = 'What’s the price of the following equipment?';
const CATERING_PRICES_TITLE = 'What’s the price of the following services?';
const BEDROOMS_PRICES_TITLE =
  'How much do you want to charge per room for accommodation?';
const LAST_STEP_TITLE =
  'Almost there! Just review terms & conditions and submit your offer';
const SUPPLIER_TITLE = 'Let your customer know who created this offer';

export function OfferCreateOnboardedVenue({
  activeUnitId,
  activeForm,
  formState,
  formStatus,
  handleClose,
  handleScrollToUnit,
  handleSetFiles,
  handleSetItemsToOffer,
  itemsToOffer,
  page,
  setActiveUnitId,
  setFormState,
  setPage,
  setItemsToOffer,
  setFormStatus,
  postEvents,
  preArrivals,
  requestDays,
  requestMeetings,
  requestUnits,
  requestRooms,
  prices,
  hasEquipment,
  hasCatering,
  onRequestDecline,
}: ICommonProps) {
  const allExtrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  // initializers
  const [previewState, setPreviewState] = useState<TPreviewState>({});

  const firstMeetingRoomId = requestMeetings[0]?.id;
  const isFirstUnit = activeUnitId === firstMeetingRoomId;
  const isLastUnit = activeUnitId === requestUnits[requestUnits.length - 1]?.id;
  const lastMeetingRoomId = requestMeetings[requestMeetings.length - 1]?.id;
  const isLastUnitMeeting =
    !postEvents.length && activeUnitId === lastMeetingRoomId;

  const {
    activeUnitBedroomExtras,
    currency,
    isCurrentDayWithoutMeetings,
    isPreOrPostEvent,
    requestedUnitDetails,
    requestedSeatingPlan,
  } = useOfferCreateData({
    activeUnitId,
    requestUnits,
  });

  const {
    emptyEquipmentExtras,
    emptyCateringExtras,
    unitsExtras,
    isSomePricesMissed,
    updatedPricesEquipment,
    setUpdatedPricesEquipment,
    updatedPricesCatering,
    setUpdatedPricesCatering,
  } = useEmptyExtrasList({
    requestMeetings,
    requestUnits,
  });

  const paidDurationOptions = usePaidDurationOptions();

  // handle active steps
  const [activeStep, setActiveStep] = useState('');

  // init units
  const {handleInitUnit, handleSetInitialState} = useInitialData({
    handleSetFiles,
    initialExtras: unitsExtras.initialExtras,
    paidDurationOptions,
    requestedSeatingPlan,
  });

  const [spaces] = useSpaces();
  const venueSpaces = getFlatMapUnits(spaces);

  // const requestMeetingRooms = requestRooms.flatMap(({items}) => items);
  const requestMeetingRooms = requestRooms
    .map((day) => {
      const {items} = day;

      if (items?.length) {
        return items;
      }

      return day as unknown as IRequestDayItem;
    })
    .flat();

  // NAVIGATION
  /**
   * navigation statuses
   * active - unit filling in progress
   * done - unit filling complete
   * hold - unit already prefilled & haven't any additional steps to fill (equipment or FB for first unit or bedrooms for any day)
   */
  const [navStatus, setNavStatus] = useState<
    Record<
      string,
      {
        status: 'done' | 'active' | 'hold' | '';
        prevId?: string;
        direction: 'fwd' | 'back';
      }
    >
  >({});

  // go to configure next item
  const handleGoNextRequestedItem = useCallback(() => {
    if (isLastUnit) return;

    const unitIdx = requestUnits.findIndex(({id}) => id === activeUnitId);
    const nextUnitId = requestUnits[unitIdx + 1]?.id;

    if (nextUnitId) {
      setPage(0);
      setActiveUnitId(nextUnitId);
      handleScrollToUnit(nextUnitId);

      setNavStatus((prev) => ({
        ...prev,
        [activeUnitId]: {
          ...prev[activeUnitId],
          status: prev[activeUnitId]?.status === 'hold' ? 'hold' : 'done',
          direction: 'fwd',
        },
        [nextUnitId]: {status: 'active', direction: 'fwd'},
      }));

      const currDay = requestMeetingRooms.find(
        (room) => room?.id === nextUnitId,
      );

      if (currDay) {
        const unit = venueSpaces.find(({id}) => id === currDay.unitId);
        if (unit) {
          handleInitUnit(unit);
        }
      }
    }
  }, [
    activeUnitId,
    handleInitUnit,
    handleScrollToUnit,
    isLastUnit,
    requestMeetingRooms,
    requestUnits,
    setActiveUnitId,
    setPage,
    venueSpaces,
  ]);

  const unitIdx = requestUnits.findIndex(({id}) => id === activeUnitId);
  const prevUnit = requestUnits[unitIdx - 1];

  const isDay = requestedUnitDetails?.eventType === EEventType.DAY;

  const setActiveFormDone = useCallback(() => {
    setNavStatus((prev) => ({
      ...prev,
      [activeUnitId]: {status: 'done', direction: 'fwd'},
    }));
  }, [activeUnitId]);

  const goToStartPage = useCallback(() => {
    setFormStatus('start');
    setNavStatus({});

    if (!isDay) {
      setFormState((prev) => ({...prev, [activeUnitId]: {}}));
    }
  }, [activeUnitId, isDay, setFormState, setFormStatus]);

  const onGoPrevItem = useCallback(
    (prevId: string) => {
      setNavStatus((prev) => ({
        ...prev,
        [activeUnitId]: {status: '', direction: 'back'},
        [prevId]: {status: 'active', direction: 'back'},
      }));

      const previousUnit = requestUnits.find(({id}) => id === prevId);

      if (!isDay) {
        setFormState((prev) => ({...prev, [activeUnitId]: {}}));
        setPreviewState((prev) => ({...prev, [activeUnitId]: {}}));
      }

      if (previousUnit?.eventType === EEventType.DAY) {
        // reset meeting bedrooms
        // @ts-ignore
        setFormState((prev) => ({
          ...prev,
          [prevId]: {...prev[prevId], [prevId]: {}},
        }));
      }

      setItemsToOffer((prev) =>
        prev.filter(({requestItemId}) => requestItemId !== prevId),
      );

      setActiveUnitId(prevId);
      handleScrollToUnit(prevId);
    },
    [
      activeUnitId,
      handleScrollToUnit,
      requestUnits,
      isDay,
      setActiveUnitId,
      setFormState,
      setItemsToOffer,
    ],
  );

  const handlePrevUnitSelection = useCallback(
    (prevUnitIdFromProps?: string) => {
      if (requestUnits[0]?.id === activeUnitId) {
        // go to start page if it 1st unit selection
        goToStartPage();
      }

      if (prevUnitIdFromProps) {
        onGoPrevItem(prevUnitIdFromProps);
        return;
      }

      if (prevUnit) {
        const prevUnitId = prevUnit.id;

        if (navStatus[prevUnitId].status === 'hold') {
          const prevNavId = navStatus[prevUnitId].prevId;

          if (prevNavId) {
            onGoPrevItem(prevNavId);
          } else {
            goToStartPage();
          }

          return;
        }

        onGoPrevItem(prevUnitId);
        setActiveUnitId(prevUnitId);
      }
    },
    [
      activeUnitId,
      goToStartPage,
      navStatus,
      onGoPrevItem,
      prevUnit,
      requestUnits,
      setActiveUnitId,
    ],
  );

  useEffect(() => {
    /**
     * redirect to next space selection page if not all days are filled
     */
    if (activeStep === SUPPLIER_TITLE) {
      if (isLastUnit || isLastUnitMeeting) {
        return;
      }

      if (
        (!isFirstUnit ||
          (isFirstUnit &&
            !emptyEquipmentExtras.current?.length &&
            !emptyCateringExtras.current?.length)) &&
        !activeUnitBedroomExtras.length
      ) {
        setNavStatus((prev) => ({
          ...prev,
          [activeUnitId]: {
            status: 'hold',
            prevId: prevUnit?.id,
            direction: prev[activeUnitId]?.direction,
          },
        }));
      }

      if (navStatus[activeUnitId]?.status === 'active') return;

      if (
        navStatus[activeUnitId]?.status === 'hold' &&
        navStatus[activeUnitId]?.direction === 'back'
      ) {
        handlePrevUnitSelection(navStatus[activeUnitId]?.prevId);
        return;
      }

      // get next item in the requestUnits
      handleGoNextRequestedItem();
    }
  }, [
    activeStep,
    activeUnitBedroomExtras.length,
    activeUnitId,
    emptyCateringExtras,
    emptyEquipmentExtras,
    handleGoNextRequestedItem,
    handlePrevUnitSelection,
    isFirstUnit,
    isLastUnit,
    isLastUnitMeeting,
    navStatus,
    prevUnit?.id,
  ]);

  const [isUnitsLoaded, setUnitsLoaded] = useState(false);

  const {removeUnitSubtotalPrice} = prices;
  const handleResetBedroomItem = useCallback(() => {
    removeUnitSubtotalPrice(activeUnitId);

    if (isFirstUnit) {
      setActiveStep('');
    }

    if (isCurrentDayWithoutMeetings) {
      setPreviewState((prev) => ({...prev, [activeUnitId]: {}}));
      // @ts-ignore
      setFormState((prev) => ({
        ...prev,
        [activeUnitId]: {...prev[activeUnitId], [activeUnitId]: {}},
      }));
    }

    setItemsToOffer((prev) =>
      prev.filter(({requestItemId}) => requestItemId !== activeUnitId),
    );
  }, [
    activeUnitId,
    isCurrentDayWithoutMeetings,
    isFirstUnit,
    removeUnitSubtotalPrice,
    setFormState,
    setItemsToOffer,
  ]);
  // NAVIGATION END

  useEffect(() => {
    if (isUnitsLoaded || !requestDays.length) return;
    requestMeetingRooms.forEach((item) => {
      if (!item) return;

      const isFirstItem = item.id === firstMeetingRoomId;

      // @ts-ignore
      const isDayWithoutMeetings = item?.items && !item.items.length;

      if (isDayWithoutMeetings && isFirstItem) {
        // @ts-ignore
        handleSetInitialState(unitsExtras.initialExtras);
        // @ts-ignore
        setFormState({[item.id]: unitsExtras.initialExtras[item.id]});
        return;
      }

      const unit = venueSpaces.find(({id}) => id === item.unitId);

      if (unit) {
        if (isFirstItem) {
          handleInitUnit(unit);
        }

        const {priceHour, priceHalfDay, priceDay} = unit;

        // set room rental prices
        prices.setRoomPrice(
          item.id,
          getRoomRentalPriceByDateRange({
            priceHour,
            priceHalfDay,
            priceDay,
            checkIn: item.checkIn,
            checkOut: item.checkOut,
          }),
        );

        // @ts-ignore
        setFormState((prev) => ({
          ...prev,
          [item.id]: {
            [ECreateOfferFormFields.NAME]: unit.name,
            [ECreateOfferFormFields.IMAGES]: unit.documents,
            [ECreateOfferFormFields.DESCRIPTION]: unit.description,
            [ECreateOfferFormFields.FLOOR]: String(unit.floor),
            [ECreateOfferFormFields.CAPACITY]: unit.unitCapacities?.reduce(
              (obj, item) => {
                obj[item.setupStyle] = {
                  capacity: item.capacity,
                };
                return obj;
              },
              {} as TCapacityType,
            ),
            [ECreateOfferFormFields.AMENITIES]: unit.amenities,
            [ECreateOfferFormFields.DIMENSIONS]: unit.unitDimensions,
            [ECreateOfferFormFields.COVER_IMAGE]: getCoverImage(unit.documents)
              ?.url,
            [ECreateOfferFormFields.PRICE_HOUR]: unit.priceHour,
            [ECreateOfferFormFields.PRICE_HALF_DAY]: unit.priceHalfDay,
            [ECreateOfferFormFields.PRICE_DAY]: unit.priceDay,
            [ECreateOfferFormFields.PAID_DURATION]: unit.minimumPaidDuration,
            ...unitsExtras.initialExtras[item.id],
          },
        }));

        setPreviewState((prev) => ({
          ...prev,
          [item.id]: {
            assignRoomType: 'existed',
            isEquipmentInitialised: true,
            isCateringInitialised: true,
            isAmenitiesHidden: false,
            isAmenitiesFilled: true,
            isCapacityInitialised: true,
            isFloorsInitialised: true,
            isDimensionsInitialised: true,
          },
        }));
      }
    });

    setUnitsLoaded(true);
  }, [
    firstMeetingRoomId,
    handleInitUnit,
    handleSetInitialState,
    unitsExtras.initialExtras,
    isUnitsLoaded,
    prices,
    requestDays.length,
    requestMeetingRooms,
    setFormState,
    venueSpaces,
  ]);

  const handlePrevUnitSelectionFromBedrooms = () => {
    handleResetBedroomItem();

    if (
      isFirstUnit &&
      (emptyEquipmentExtras.current?.length ||
        emptyCateringExtras.current?.length)
    ) {
      return;
    }

    handlePrevUnitSelection();
  };

  // submit
  const [onSubmit, isSubmitting] = useOfferSubmit({
    requestRooms,
    updatedPricesEquipment,
    updatedPricesCatering,
    itemsToOffer,
    requestDays,
    requestedUnitDetails,
    setPage,
    setFormStatus,
  });

  const bedroomsExtras = useMemo(
    () => getBedroomsFromState(formState, activeUnitId),
    [activeUnitId, formState],
  );

  const stepPages = [
    // 10
    {
      title: EQUIPMENT_PRICES_TITLE,
      subTitle: `(price per item/day in ${currency})`,
      fieldName: ECreateOfferFormFields.PRICES_EQUIPMENT,
      isHidden:
        !emptyEquipmentExtras.current?.length ||
        !isFirstUnit ||
        isPreOrPostEvent,
      onNextClick: async () => {
        if (
          !emptyCateringExtras.current?.length ||
          !activeUnitBedroomExtras.length
        ) {
          // go to next unit
          setActiveFormDone();
        }

        setUpdatedPricesEquipment(
          activeForm[ECreateOfferFormFields.PRICES_EQUIPMENT],
        );
      },
      onBackClick: handlePrevUnitSelection,
      content: (
        <PageContent width={346}>
          <StepHint
            title='Pro tip!'
            text={`If a third party is providing these services, please consider
               that HRS' 10% commission is calculated from the total price.`}
            isCentered={false}
          />

          <StepExtrasPrices
            fieldName={ECreateOfferFormFields.PRICES_EQUIPMENT}
            extras={emptyEquipmentExtras.current || []}
          />
        </PageContent>
      ),
    },

    // 11
    {
      title: CATERING_PRICES_TITLE,
      subTitle: `(price per item/day in ${currency})`,
      fieldName: ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE,
      isHidden:
        !emptyCateringExtras.current?.length ||
        !isFirstUnit ||
        isPreOrPostEvent,
      onNextClick: () => {
        setUpdatedPricesCatering(
          activeForm[ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE],
        );

        if (!activeUnitBedroomExtras.length) {
          // go to next unit
          setActiveFormDone();
        }
      },
      onBackClick: () => {
        if (!emptyEquipmentExtras.current?.length) {
          handlePrevUnitSelection();
        }
      },
      content: (
        <PageContent width={368}>
          <StepHint
            title='Pro tip!'
            text={`If a third party is providing these services, please consider
               that HRS' 10% commission is calculated from the total price.`}
            isCentered={false}
          />

          <StepExtrasPrices
            fieldName={ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE}
            extras={emptyCateringExtras.current || []}
          />
        </PageContent>
      ),
    },

    {
      title: BEDROOMS_PRICES_TITLE,
      subTitle: DateUtils.getFormattedDayMonthTime({
        date: requestedUnitDetails?.checkIn,
        showDateOnly: true,
      }),
      fieldName: ECreateOfferFormFields.PRICES_BEDROOMS,
      titleWidth: 422,
      isTitleCentered: true,
      isHidden: !activeUnitBedroomExtras.length,
      stepTotalCount: isFirstUnit
        ? (emptyEquipmentExtras.current?.length || 0) +
            (emptyCateringExtras.current?.length || 0) || 1
        : (isPreOrPostEvent || isCurrentDayWithoutMeetings) && !isLastUnit && 1,
      onNextClick: () => {
        // set bedroom items to payload
        handleSetItemsToOffer({
          bedrooms: Object.entries(
            getBedroomsFromState(formState, activeUnitId) || {},
          ).map(([code, price]) => ({
            extraId: allExtrasOption.find((item) => item.code === code)
              ?.id as number,
            price: {
              value: parseStringToNumber(price),
            },
          })),
        });

        // go to next unit
        setActiveFormDone();
      },
      onBackClick: handlePrevUnitSelectionFromBedrooms,
      content: (
        <PageContent width={346}>
          <StepHint
            title='Pro tip!'
            text={`The accommodation price will be specific to this offer
             and will not be applied to any future requests.`}
            isCentered={false}
          />

          <StepBedroomsPrices
            activeUnitId={activeUnitId}
            bedrooms={activeUnitBedroomExtras}
            isInitialized={previewState[activeUnitId]?.isBedroomsInitialised}
            formStateValue={bedroomsExtras}
          />
        </PageContent>
      ),
    },

    // 12
    {
      title: SUPPLIER_TITLE,
      fieldName: ECreateOfferFormFields.SUPPLIER,
      onBackClick: () => {
        if (isPreOrPostEvent || isCurrentDayWithoutMeetings) {
          handleResetBedroomItem();

          return;
        } else {
          if (isLastUnit) {
            if (
              !isFirstUnit &&
              (emptyEquipmentExtras.current?.length ||
                emptyCateringExtras.current?.length)
            ) {
              handlePrevUnitSelection();
            } else {
              goToStartPage();
            }
          }
        }
      },
      content: (
        <PageContent width={284}>
          <NW2FormItemInput
            name={ECreateOfferFormFields.SUPPLIER}
            type='text'
            label='Your name'
            placeholder='Your name'
            rules={nameFieldRules}
            showAllValidationErrors
          />
        </PageContent>
      ),
    },

    // 13
    {
      title: LAST_STEP_TITLE,
      titleWidth: 446,
      isTitleCentered: true,
      titlePaddingTop: offsetDef,
      isHidden:
        (isPreOrPostEvent || isCurrentDayWithoutMeetings) && !isLastUnit,
      fieldName: '',
      content: <LastStep />,
    },
  ];

  useEffect(() => {
    // init preview
    const showStep = (stepName: string, val: boolean) => {
      setPreviewState((prev) => ({
        ...prev,
        [activeUnitId]: {...prev[activeUnitId], [stepName]: val},
      }));
    };

    if (isFirstUnit) {
      if (activeStep === EQUIPMENT_PRICES_TITLE) {
        showStep('isEquipmentInitialised', true);
      }

      if (activeStep === CATERING_PRICES_TITLE) {
        showStep('isCateringInitialised', true);
      }
    }

    if (activeStep === BEDROOMS_PRICES_TITLE) {
      showStep('isBedroomsInitialised', true);

      if (isCurrentDayWithoutMeetings) {
        // day without meetings
        showStep('isCateringInitialised', true);
        showStep('isEquipmentInitialised', true);
      }
    }
  }, [activeStep, activeUnitId, isCurrentDayWithoutMeetings, isFirstUnit]);

  return (
    <OfferCreateLayout
      activeUnitId={activeUnitId}
      isSubmitting={isSubmitting}
      requestUnits={requestUnits}
      prices={prices}
      page={page}
      setPage={setPage}
      handleClose={handleClose}
      formState={formState}
      formStatus={formStatus}
      onSubmit={onSubmit}
      stepPages={stepPages}
      setFormState={setFormState}
      setFormStatus={setFormStatus}
      setActiveUnitId={setActiveUnitId}
      setActiveStep={setActiveStep}
      previewState={previewState}
      requestDays={requestDays}
      preArrivals={preArrivals}
      postEvents={postEvents}
      hasEquipment={hasEquipment}
      hasCatering={hasCatering}
      currency={currency}
      firstMeetingRoomId={firstMeetingRoomId}
      unitsExtras={unitsExtras}
      isSomePricesMissed={isSomePricesMissed}
      onRequestDecline={onRequestDecline}
    />
  );
}
