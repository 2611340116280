import {useMemo} from 'react';

import LocalStorageService from 'infra/common/localStorage.service';
import {TDay} from 'types/search';
import {ITimeData} from 'types/dto/ISearch.types';

export function useStorageMultiSearchData() {
  const multiSearchData = LocalStorageService.getByKey('multiSearchData');

  const parsedMultiSearchData = useMemo(
    (): {meetingRequestData: TDay[]; timeData: ITimeData[]} =>
      multiSearchData ? JSON.parse(multiSearchData) : {},
    [multiSearchData],
  );

  return [parsedMultiSearchData];
}
