import {Dispatch, SetStateAction, useCallback, useState} from 'react';
import _get from 'lodash/get';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useMappedItemsToOffer} from './useMappedItemsToOffer';
import {
  ECreateOfferFormFields,
  IRequestMeeting,
  TCreateOfferFormValues,
  TCreateOfferItem,
  TFormStatus,
} from '../types';
import {getCreateOfferPayload} from '../helpers';
import {attachCancellationPolicyToOrder} from 'store/admin/apiActions';
import {clearCancellationPolicyFile} from 'store/offers/offersSlice';
import {IOfferDay, IRequestDay} from 'types/offer';
import {createOffer} from 'store/offers/apiActions';

interface IProps {
  requestRooms: IRequestDay[];
  updatedPricesEquipment: any;
  updatedPricesCatering: any;
  itemsToOffer: TCreateOfferItem[];
  requestDays: (IRequestDay | IOfferDay)[];
  requestedUnitDetails: IRequestMeeting;
  setFormStatus: Dispatch<SetStateAction<TFormStatus>>;
  setPage: Dispatch<SetStateAction<number>>;
}
export function useOfferSubmit({
  requestRooms,
  updatedPricesEquipment,
  updatedPricesCatering,
  itemsToOffer,
  requestDays,
  requestedUnitDetails,
  setFormStatus,
  setPage,
}: IProps): [(values: TCreateOfferFormValues) => Promise<void>, boolean] {
  const dispatch = useAppDispatch();

  const cancellationPolicyFile = useAppSelector(
    ({offers}) => offers.cancellationPolicyFile,
  );
  const supplierCancellationPolicy = useAppSelector(
    ({offers}) => offers.supplierCancellationPolicy,
  );
  const requestId = useAppSelector(({offers}) => offers.offerDetails.id);
  const orderNumber = useAppSelector(({offers}) => offers.offerDetails.number);
  const venueId: number = useAppSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`),
  );

  const [isSubmitting, setSubmitting] = useState(false);

  const [getMappedItemsToOffer] = useMappedItemsToOffer();

  const onSubmit = useCallback(
    async (values: TCreateOfferFormValues) => {
      setSubmitting(true);

      const mappedItemsToOffer = getMappedItemsToOffer({
        requestRooms,
        updatedPricesEquipment,
        updatedPricesCatering,
        itemsToOffer,
      });

      const payload = getCreateOfferPayload({
        accommodationId: venueId,
        creatorName: values[ECreateOfferFormFields.SUPPLIER] as string,
        requestId,
        itemsToOffer: mappedItemsToOffer,
        requestDays,
      });

      // create offer
      if (requestedUnitDetails?.id) {
        if (cancellationPolicyFile && supplierCancellationPolicy) {
          await dispatch(
            attachCancellationPolicyToOrder({
              file: cancellationPolicyFile,
              orderNumber,
            }),
          );
        }

        await dispatch(
          createOffer(
            payload,
            () => {
              setSubmitting(false);
              setFormStatus('finish');
              setPage(0);
              dispatch(clearCancellationPolicyFile());
            },
            () => {
              setSubmitting(false);
            },
          ),
        );
      }
    },
    [
      cancellationPolicyFile,
      dispatch,
      getMappedItemsToOffer,
      itemsToOffer,
      orderNumber,
      requestDays,
      requestId,
      requestRooms,
      requestedUnitDetails?.id,
      setFormStatus,
      setPage,
      supplierCancellationPolicy,
      updatedPricesCatering,
      updatedPricesEquipment,
      venueId,
    ],
  );

  return [onSubmit, isSubmitting];
}
