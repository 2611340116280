import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import useInitialMultiRoomData from 'view/venue/hooks/search/useInitialMultiRoomData';
import {getVenuesListQuerySearchData} from 'utils/queryUtils';
import {INITIAL_SEARCH_CRITERIA} from 'store/search/searchSlice';
import {TSearchVenuesDay, TVenuesQuerySearchData} from 'types/search';
import {useAppSelector} from 'store/hooks';

const useSearchData = () => {
  const location = useLocation();

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);

  const {
    isMultiSearchRoom,
    multiSearchPayload,
    initialTimeData,
    meetingRequestData,
    bedroomsCatering,
  } = useInitialMultiRoomData();

  const querySearchData = useMemo(
    () =>
      getVenuesListQuerySearchData({
        searchCriteria,
        locationSearch: location.search,
      }),
    [location.search, searchCriteria],
  );

  const searchData = useMemo(
    (): TVenuesQuerySearchData & {
      filterDays?: TSearchVenuesDay[];
    } => ({
      ...querySearchData,
      ...(isMultiSearchRoom ? {filterDays: multiSearchPayload} : {}),
    }),
    [querySearchData, isMultiSearchRoom, multiSearchPayload],
  );

  const initialSearchData = useMemo(
    () => ({
      ...getVenuesListQuerySearchData({
        searchCriteria: {
          ...INITIAL_SEARCH_CRITERIA,
        },
        locationSearch: location.search,
      }),
      ...(isMultiSearchRoom ? {filterDays: multiSearchPayload} : {}),
      initialTimeData,
      meetingRequestData,
    }),
    [
      initialTimeData,
      isMultiSearchRoom,
      location.search,
      meetingRequestData,
      multiSearchPayload,
    ],
  );

  return {
    initialSearchData,
    searchData,
    initialTimeData,
    querySearchData,
    bedroomsCatering,
  };
};

export default useSearchData;
