import styled, {css} from 'styled-components';

import {
  borderRadiusDef,
  fontWeightBold,
  fontWeightExtraBold,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray600Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
} from 'constants/styleVars';
import Icon from 'view/components/Icon';

const borderStyle = `1px solid ${NW2Gray200Color}`;

export const ItemWrapper = styled.div`
  border: ${borderStyle};
  border-radius: ${borderRadiusDef};

  &:not(&:last-of-type) {
    margin-bottom: ${offsetDef};
  }
`;

export const ItemHead = styled.div`
  padding: ${offsetDef} ${offsetXLg};
  display: grid;

  grid-gap: ${offsetXXLg};
  grid-template-columns: 140px 1fr 20px;
`;

export const ArrowButton = styled(Icon)<{
  isCollapsed: boolean;
}>`
  ${({isCollapsed}) => (isCollapsed ? 'transform: rotate(180deg)' : '')};
  grid-column: 3;
  justify-self: end;
  align-items: center;
  display: inline-flex;
`;

export const ItemContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${offsetXLg};
  background-color: ${NW2Gray100Color};
  border-top: ${borderStyle};
  border-radius: 0 0 ${borderRadiusDef} ${borderRadiusDef};
`;

export const Name = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
`;

export const SpacesContainer = styled.div<{hasSpaces: boolean}>`
  display: flex;
  gap: ${offsetXLg};
  font-weight: ${fontWeightBold};
  color: ${NW2Gray600Color};

  ${({hasSpaces}) =>
    hasSpaces &&
    css`
      padding-left: ${offsetXXLg};
      border-left: 1px solid ${NW2Gray200Color};
    `}
`;

export const SpaceItem = styled.div`
  width: auto;
`;
