import styled, {createGlobalStyle} from 'styled-components';
import {Drawer} from 'antd';

import {
  borderRadiusLg,
  drawerZIndex,
  fontSizeSm,
  fontSizeMd,
  fontWeightExtraBold,
  lineHeightSm,
  offsetXSm,
  offsetSm,
  offsetDef,
  offsetXLg,
  whiteColor,
} from 'constants/styleVars';

export const PopoverGlobalStyle = createGlobalStyle`
    .custom-popover {
        .ant-popover {
            &-inner {
                border-radius: ${borderRadiusLg};
                background-color: ${whiteColor};
                overflow: hidden;
            }

            &-title {
                padding: ${offsetSm} ${offsetDef};
                border-radius: ${borderRadiusLg} ${borderRadiusLg} 0 0;
                border-bottom: 0;
                padding-bottom: ${offsetSm};
                background-color: ${whiteColor};
            }

            &-inner-content {
                font-size: ${fontSizeMd};
                padding: ${offsetDef};
                background-color: ${whiteColor};
            }
        }
    }
`;

export const StyledDrawer = styled(Drawer)`
  z-index: ${drawerZIndex};

  .ant-drawer {
    &-content {
      border-radius: 16px 16px 0 0;
    }

    &-header-title {
      flex-direction: row-reverse;
    }

    &-title {
      // need this for share drawer
      overflow: hidden;
    }

    &-body {
      font-size: ${fontSizeMd};
    }

    &-footer {
      padding: ${offsetSm} ${offsetXLg};
    }

    &-close {
      margin-left: 0;
      margin-right: -${offsetXSm};
      padding: 0 ${offsetXSm};
    }
  }
`;

export const PopoverTitle = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

export const DrawerTitle = styled(PopoverTitle)`
  text-align: center;
`;

export const StyledPopoverTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
