import {useCallback, useMemo} from 'react';
import _get from 'lodash/get';

import {ERoomType} from 'types/dto/ERoomType.type';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {
  makeFastSearchFilterDaysPayload,
  makeGroupsFastSearchFilterDaysPayload,
  makeMultiSearchPayload,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {useIsFastSearchEnabled} from 'view/venue/hooks/search/useIsFastSearchEnabled';
import {useGroupsRoomsData} from 'view/venue/hooks/search/useGroupsRoomsData';
import {useBedroomsCatering} from './useBedroomsCatering';
import {useAppSelector} from 'store/hooks';
import {useStorageMultiSearchData} from './useStorageMultiSearchData';

export function useSearchFilterDays() {
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const extrasOption: IExtrasOption[] = useAppSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const [parsedMultiSearchData] = useStorageMultiSearchData();
  const [bedroomsCatering] = useBedroomsCatering();
  const [isFastSearchEnabled] = useIsFastSearchEnabled();
  const groupsRooms = useGroupsRoomsData();

  const filteredBedroomExtras = useMemo(
    () => extrasOption.filter((item) => item.type === EResourcesType.BEDROOM),
    [extrasOption],
  );

  const getFilterDays = useCallback(
    ({isFastSearch}: {isFastSearch: boolean}) => {
      const isGroups = roomType === ERoomType.GROUPS;
      const {multiSearchPayload} = makeMultiSearchPayload({
        meetingRequestData: parsedMultiSearchData.meetingRequestData,
        bedroomsCatering,
        filteredBedroomExtras,
      });

      const result = isFastSearch
        ? isGroups
          ? makeGroupsFastSearchFilterDaysPayload({
              multiSearchPayload,
              groupsRooms,
            })
          : makeFastSearchFilterDaysPayload(multiSearchPayload)
        : multiSearchPayload;

      return result || [];
    },
    [
      bedroomsCatering,
      filteredBedroomExtras,
      groupsRooms,
      parsedMultiSearchData.meetingRequestData,
      roomType,
    ],
  );

  const filterDays = useMemo(
    () => getFilterDays({isFastSearch: isFastSearchEnabled}),
    [getFilterDays, isFastSearchEnabled],
  );

  return {filterDays, getFilterDays};
}
