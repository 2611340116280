import React, {memo} from 'react';
import {
  EPointsOfInterest,
  IPointOfInterest,
  pointsOfInterestIcons,
  pointsOfInterestMap,
} from 'types/venue';
import {distanceConverter} from 'utils/venueUtils';
import {getDecimalWithCount} from 'view/venue/NW2VenueProfile/helpers';
import {HorizontalDivider} from '../../PointsOfInterest.styles';
import {TFormObject} from '../../types';
import {
  Container,
  PointType,
  ItemsContainer,
  Distance,
  NameIcon,
  StyledIcon,
} from './PointsOfInterestList.styles';

interface IListItemProps {
  data: IPointOfInterest;
  lastElement: boolean;
}
const PointsOfInterestListItem = memo(
  ({data: {distance, name, type}, lastElement}: IListItemProps) => {
    const iconFromType = pointsOfInterestIcons[type];
    const pointType = pointsOfInterestMap[type];
    const distanceMi = distanceConverter('mi', distance);

    return (
      <>
        <Container>
          <NameIcon>
            <StyledIcon icon={iconFromType} />
            <PointType>{pointType}:</PointType>
          </NameIcon>
          <div data-testid={`poi-name-${type}`}>{name}</div>
          <Distance
            data-testid={`poi-distance-${type}`}
          >{`${getDecimalWithCount(distance)}km / ${getDecimalWithCount(
            distanceMi,
          )}mi`}</Distance>
        </Container>
        {!lastElement && <HorizontalDivider />}
      </>
    );
  },
);

interface IListProps {
  poiFormData: TFormObject;
}
const PointsOfInterestList = ({poiFormData}: IListProps) => {
  if (!poiFormData) return null;

  return (
    <ItemsContainer>
      {Object.values(EPointsOfInterest).map((point, index, array) => {
        const data = poiFormData?.[point];
        const lastElement = array.length === index + 1;

        if (!data) return null;

        return (
          <PointsOfInterestListItem
            key={data.type}
            data={data}
            lastElement={lastElement}
            data-testid={`poi-list-row-${data.type}`}
          />
        );
      })}
    </ItemsContainer>
  );
};

export default PointsOfInterestList;
