import {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import queryString from 'query-string';

import {ERoomSchemaNames} from 'types/venue';
import {
  cleanCustomerSelectedPackages,
  setCustomerSelectedPackages,
} from 'store/customer/customerSlice';
import {useQuery} from 'hooks/useQuery';
import {makeInitPreviewUnitsData} from '../helpers';
import {useAppSelector} from 'store/hooks';
import {useShortList} from 'view/venue/Offer/hooks/useShortList';
import LocalStorageService from 'infra/common/localStorage.service';
import {setIsRegistrationAction} from 'store/app/appSlice';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {IUnitResponse} from 'types/dto/IPublicVenue';
import {useSelectedRoomId} from 'view/venue/hooks/useSelectedRoomId';
import {useCustomerOrderUnits} from 'view/venue/hooks/useCustomerOrderUnits';
import {
  setPreviewAttendees,
  setPreviewRooms,
} from 'store/bookingsCustomer/bookingsCustomerSlice';

interface IProps {
  id: number | string;
  isMeetingRoom: boolean;
  isOfferRequest?: boolean;
}
export function useBookingInitialData({
  id,
  isMeetingRoom,
  isOfferRequest,
}: IProps) {
  const dispatch = useDispatch();

  const customerSelectedPackages = useAppSelector(
    ({customer}) => customer.customerSelectedPackages,
  );
  const details = useAppSelector(({venueDetails}) => venueDetails.details);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const filterDays = searchCriteria.filterDays;

  const {previewRooms} = useCustomerOrderUnits();

  const {days} = details || {};

  const {venueUnits: unitsToBook} = useVenueDetailsData<IUnitResponse>();

  const {seatingPlan} = useQuery();

  const navigate = useNavigate();
  const {shortList} = useShortList();
  const onGoToVenueDetails = useCallback(() => {
    if (shortList.items.length) {
      navigate(-1);
      return;
    }

    const stringSearchData = queryString.stringify({...searchCriteria});

    navigate({
      pathname: `/venue/${id}/details`,
      search: stringSearchData,
    });
  }, [id, navigate, searchCriteria, shortList.items.length]);

  const [getSelectedVenueUnitId] = useSelectedRoomId();

  const venueUnits = useMemo(
    () =>
      (unitsToBook as IUnitResponse[])?.filter(
        ({isOptimalPrice, unitId, checkIn}) => {
          const selectedVenueUnitId = getSelectedVenueUnitId({
            venueId: Number(id),
            checkIn,
          });

          if (selectedVenueUnitId) {
            return unitId === selectedVenueUnitId;
          }

          return isOptimalPrice;
        },
      ),
    [getSelectedVenueUnitId, id, unitsToBook],
  );

  const isRfp = venueUnits?.some(({isRtb}) => !isRtb);

  // clear store data on page leave
  useEffect(() => {
    return () => {
      dispatch(setPreviewRooms([]));
      dispatch(setPreviewAttendees([]));
      dispatch(cleanCustomerSelectedPackages());
      dispatch(setIsRegistrationAction(false));
    };
  }, [dispatch]);

  const isInitPreviewUnitsExist = !!previewRooms.length;

  const guestBookingPreviewData =
    LocalStorageService.getByKey('bookingPreviewData');

  const parsedPreviewUnits = JSON.parse(guestBookingPreviewData as string);

  const guestPreviewUnits = parsedPreviewUnits
    ? parsedPreviewUnits?.previewUnits
    : null;

  // Setting array of default package values, initially customer has all packages turned off,
  // it will be [day:[[unitId: null]...]...] for multiDay
  useEffect(() => {
    if (!customerSelectedPackages && previewRooms.length) {
      const defaultMultiDayCustomerPackages = previewRooms.reduce(
        (prev, curr) => {
          const unitsMapByDay = curr.units.map(({unitId}) => [unitId, null]);
          return [...prev, unitsMapByDay];
        },
        [] as any,
      );

      dispatch(setCustomerSelectedPackages(defaultMultiDayCustomerPackages));
    }
  }, [dispatch, previewRooms, customerSelectedPackages]);

  useEffect(() => {
    if (
      isInitPreviewUnitsExist ||
      !venueUnits?.length ||
      !filterDays.length ||
      !isMeetingRoom
    )
      return;

    const initPreviewUnitsData = makeInitPreviewUnitsData({
      venueUnits,
      days,
      seatingPlan: seatingPlan as ERoomSchemaNames,
      filterDays,
    });

    dispatch(
      setPreviewRooms(
        guestPreviewUnits?.length ? guestPreviewUnits : initPreviewUnitsData,
      ),
    );
  }, [
    days,
    dispatch,
    filterDays,
    guestPreviewUnits,
    isInitPreviewUnitsExist,
    isMeetingRoom,
    seatingPlan,
    venueUnits,
  ]);

  const formInitialValues = {
    ...(isOfferRequest ? {optionDate: ''} : {}),
  };

  return {
    isRfp,
    formInitialValues,
    onGoToVenueDetails,
  };
}
