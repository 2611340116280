import React from 'react';
import {useNavigate} from 'react-router-dom';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import queryString from 'query-string';

import {ShortListItem} from './components/ShortListItem/ShortListItem';
import {useActions} from 'hooks/useActions';
import {useAppSelector} from 'store/hooks';
import useToggle from 'hooks/useToggle';
import {pluralize} from 'utils/helpers';
import {
  BackNavBox,
  CartCount,
  CartWrapper,
  ListActions,
  ListBox,
  ListItems,
  ListLabel,
  ListText,
  ListTitle,
  Wrapper,
  PopoverGlobalStyle,
} from './ShortList.styles';
import {SHORTLIST_MAX_ITEMS} from '../../constants';
import {offsetSm} from 'constants/styleVars';
import {Routes} from 'constants/routes';
import {TVenuesQuerySearchData} from 'types/search';
import {useGoBackToStoredSearch} from '../../../hooks/search/useGoBackToStoredSearch';
import {useRedirectToRequest} from '../../hooks/useNavigateToRequestOffer';
import {useShortList} from '../../hooks/useShortList';
import {SelectMoreVenuePopup} from './components/SelectMoreVenuePopup';

interface IProps {
  querySearchData: TVenuesQuerySearchData;
  hasBackNav?: boolean;
  noCompareBtn?: boolean;
}

export function ShortList({hasBackNav, querySearchData, noCompareBtn}: IProps) {
  const navigate = useNavigate();
  const [isInsufficientModalShowed, setInsufficientModalShowed] =
    useToggle(false);

  const {shortList, isMultiRequest} = useShortList();

  const {setShortListMaxItemsModalShowed} = useActions();
  const {isDesktop, isMobile, isTablet} = useAppSelector(
    ({app}) => app.deviceType,
  );
  const isMaxItemsReached = useAppSelector(
    ({offers}) => offers.shortList.isMaxItemsReached,
  );

  const shortListLen = shortList.items.length;

  const onModalClose = () => {
    setShortListMaxItemsModalShowed(false);
  };

  const {redirectToRequest} = useRedirectToRequest({
    shortList,
    isMultiRequest,
  });
  const goToRequestWithValidation = () => {
    if (shortListLen < 3) {
      setInsufficientModalShowed();
      return;
    }
    redirectToRequest();
  };

  const goToCompare = () => {
    if (noCompareBtn) {
      goToRequestWithValidation();
      return;
    }

    navigate({
      pathname: Routes.shortListOverview,
      search: queryString.stringify(querySearchData),
    });
  };

  const [onGoToSearchResults] = useGoBackToStoredSearch();

  const isCounterHidden = hasBackNav && !isMobile;

  return (
    <Wrapper
      isActive={!!shortListLen}
      isDesktop={isDesktop}
      isMobile={isMobile}
    >
      <ListBox isDesktop={isDesktop}>
        {hasBackNav && !isMobile && (
          <BackNavBox>
            <NW2Button
              onClick={onGoToSearchResults}
              buttonType='secondary'
              size='small'
              icon={<Icon transparent icon='NW2_MOVE_BACK' />}
              minimized
            />
            <span>Back to results</span>
          </BackNavBox>
        )}

        {isMobile && (
          <CartWrapper>
            <Icon icon='NW2_SHOP_CART' size={32} NW2Gray900Color />
            <CartCount>{shortListLen}</CartCount>
          </CartWrapper>
        )}

        {!isCounterHidden && (isDesktop || isMobile) && (
          <ListTitle>
            <ListLabel isMobile={isMobile}>
              Select at least 3 to 5 venues{' '}
            </ListLabel>
            <ListText>
              {shortListLen === SHORTLIST_MAX_ITEMS
                ? 'Done! Let’s request offers'
                : isMobile
                ? `${pluralize('Venue', shortListLen)} selected`
                : `You have selected ${pluralize('venue', shortListLen)}`}
            </ListText>
          </ListTitle>
        )}

        {!isMobile && (
          <ListItems>
            {shortList.items.map((item) => (
              <ShortListItem key={`shortlist-item-${item.id}`} {...item} />
            ))}

            {shortListLen < SHORTLIST_MAX_ITEMS &&
              Array.from({length: SHORTLIST_MAX_ITEMS - shortListLen}).map(
                (_, idx) => (
                  <ShortListItem
                    key={`shortlist-item-placeholder-${idx}`}
                    isPlaceholder
                  />
                ),
              )}
          </ListItems>
        )}
      </ListBox>

      <ListActions>
        {isDesktop && (
          <>
            {!noCompareBtn && (
              <NW2Button onClick={goToCompare}>compare</NW2Button>
            )}
            <NW2Button onClick={goToRequestWithValidation} buttonType='primary'>
              go to request
            </NW2Button>
          </>
        )}

        {isTablet && (
          <NW2Button onClick={goToRequestWithValidation} buttonType='primary'>
            {noCompareBtn ? 'go to request' : 'compare & request'}
          </NW2Button>
        )}

        {isMobile && (
          <NW2Button
            onClick={goToRequestWithValidation}
            buttonType='primary'
            fullWidth
          >
            next
          </NW2Button>
        )}

        <SelectMoreVenuePopup
          open={isInsufficientModalShowed}
          onClose={setInsufficientModalShowed}
        />
      </ListActions>
      <PopoverGlobalStyle />
      <NW2ModalDrawer
        header='Maximum selected'
        body={
          <>
            <p>
              You can select up to 5 venues. To add this venue, please remove at
              least 1 venue from your current list.
            </p>
          </>
        }
        isShowed={isMaxItemsReached}
        isMobile={isMobile}
        modalBodyStyle={{
          paddingBottom: offsetSm,
        }}
        onClose={onModalClose}
        modalWidth={400}
        drawerHeight='auto'
      />
    </Wrapper>
  );
}
