import React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

import NW2Button from 'view/components/NW2Button';
import ExtraItem from './ExtraItem';

import {useAppSelector} from 'store/hooks';
import {IExtrasResponse} from 'types/dto/IExtras.type';
import {IExtraState, ISummaryExtra} from 'types/dto/IBooking.types';
import {offsetDef} from 'styles/configs/offset';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {StyledExtrasList} from '../Space.styles';
import useSortedExtras from 'view/venue/hooks/useSortedExtras';
import useToggle from 'hooks/useToggle';
import {maximumCounterNumber} from 'constants/app';
import {TChangeExtraQuantity} from '../../types';

type TProps = {
  availableExtras: IExtrasResponse[];
  chosenExtras: ISummaryExtra[];
  extrasState: IExtraState[];
  initMaxToShow: number;
  isFoodAndBeverage?: boolean;
  participants?: number;
  checkInDate?: string;
  checkOutDate?: string;
  onExtraChangeQuantity: TChangeExtraQuantity;
  isBedrooms?: boolean;
  isDayWithoutMeetings?: boolean;
};

export const StyledButton = styled(NW2Button)`
  margin-top: ${offsetDef};
`;

const ExtrasList = ({
  availableExtras,
  chosenExtras,
  extrasState,
  initMaxToShow,
  onExtraChangeQuantity,
  isDayWithoutMeetings,
  isBedrooms,
}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const currency = useSelector(
    ({venuesReducer}) => venuesReducer.venueDetails.currency,
  );

  const [isShowMore, showMoreHandler] = useToggle(false);

  const {venueDetails} = useVenueDetailsData();

  const {sortedExtras, chosenExtrasQuantity} = useSortedExtras(
    availableExtras,
    chosenExtras,
  );

  const maxToShow =
    initMaxToShow >= chosenExtrasQuantity
      ? initMaxToShow
      : chosenExtrasQuantity;

  const isMoreMax = sortedExtras.length > maxToShow;

  const extrasToShow =
    isMoreMax && !isShowMore ? sortedExtras.slice(0, maxToShow) : sortedExtras;

  // we should leave at least 1 enabled bedroom for day without meeting room
  const minValue =
    isDayWithoutMeetings &&
    extrasState.reduce((curr, next) => curr + (next.bookedQuantity || 0), 0) ===
      1
      ? 1
      : undefined;

  return (
    <StyledExtrasList>
      {extrasToShow.map((extra) => (
        <ExtraItem
          key={extra.accommodationExtraId}
          extra={extra}
          currency={venueDetails.currency || currency}
          onExtraChangeQuantity={onExtraChangeQuantity}
          extrasState={extrasState}
          chosenExtras={chosenExtras}
          isBedrooms={isBedrooms}
          minValue={minValue}
          maxValue={maximumCounterNumber}
        />
      ))}
      {isMoreMax && !isShowMore && (
        <StyledButton
          size='responsiveAdaptive'
          buttonType='tertiary'
          onClick={showMoreHandler}
          fullWidth={isMobile}
        >
          Show more options
        </StyledButton>
      )}
    </StyledExtrasList>
  );
};

export default ExtrasList;
