import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Table} from 'antd';
import _get from 'lodash/get';

import RowMenu from './components/RowMenu';
import TextValue from 'view/components/TextValue';

import {
  ICorporateAccountCompanyUser,
  ICorporateAccountTableSource,
} from 'store/admin/types';
import {RootState} from 'store/types';
import styled from 'styled-components';
import useGetAllCorporateAccounts from 'view/admin/hooks/useGetAllCorporateAccounts';

const StyledTable = styled((props: any) => <Table {...props} />)`
  .ant-table-selection-column {
    display: none;
    opacity: 0;
  }
`;

const columns = [
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    render: (text: string) => <TextValue>{text}</TextValue>,
  },
  {
    title: 'First name',
    dataIndex: 'userFirstName',
    key: 'userFirstName',
    render: (text: string) => <TextValue>{text}</TextValue>,
  },
  {
    title: 'Last name',
    dataIndex: 'userLastName',
    key: 'userLastName',
    render: (text: string) => <TextValue>{text}</TextValue>,
  },
  {
    title: 'Email address',
    dataIndex: 'userEmail',
    key: 'userEmail',
    render: (text: string) => <TextValue>{text}</TextValue>,
  },
  {
    title: 'Phone number',
    dataIndex: 'userPhone',
    key: 'userPhone',
    render: (text: string) => <TextValue>{text}</TextValue>,
  },
  {
    key: 'rowMenu',
    title: '',
    dataIndex: 'rowMenu',
    render: (text: string, user: ICorporateAccountTableSource) => {
      return <RowMenu data={user} />;
    },
  },
];

const UsersTable: FC = () => {
  const onSelectChange = (selectedRowKeys: any) => {
    setRowSelection((prevState) => {
      return {...prevState, selectedRowKeys};
    });
  };

  const users: ICorporateAccountCompanyUser[] = useSelector(
    (state: RootState) => _get(state, 'admin.corporateAccountCompanyUsers'),
  );

  const companies = useGetAllCorporateAccounts();
  const tableDataSource = users.map((item) => ({...item, companies}));

  const [rowSelection, setRowSelection] = useState({
    onChange: onSelectChange,
  });

  useEffect(() => {
    const newUser = users.find((user) => user.isHighlighted);

    if (!newUser) return;
    setRowSelection((prevState) => {
      return {...prevState, selectedRowKeys: [newUser.userId]};
    });
  }, [users]);

  const loading: boolean = useSelector((state: RootState) =>
    _get(state, 'admin.corporateAccountCompanyUsersLoading'),
  );

  return (
    <StyledTable
      rowSelection={{...rowSelection, type: 'radio'}}
      dataSource={tableDataSource}
      columns={columns}
      pagination={false} // we are using custom pagination
      rowKey='userId'
      loading={loading}
    />
  );
};

export default UsersTable;
