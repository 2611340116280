import React, {useState} from 'react';
import {Form} from 'react-final-form';
import _isEqual from 'lodash/isEqual';
import _difference from 'lodash/difference';

import NW2Button from 'view/components/NW2Button';
import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';
import {AddFloorsModal} from '../AddFloorsModal/AddFloorsModal';
import {DeleteModal} from '../DeleteModal/DeleteModal';
import {FloorEditItem} from '../FloorsEditItem/FloorEditItem';

import useUpdateVenue from 'view/venue/hooks/useUpdateVenue';
import {IVenue} from 'types/venue';
import {HorizontalDivider} from './FloorsEdit.styles';
import {EMPTY_OBJECT} from 'constants/app';
import {EDIT_FLOORS_FORM} from '../../types';

interface IProps {
  venue: IVenue;
  isSubmitFailed?: boolean;
  isExternalVenue: boolean;
  onFinishEditing: () => void;
  isLoading?: boolean;
}
export const FloorsEdit = ({
  venue,
  isExternalVenue,
  onFinishEditing,
}: IProps) => {
  const venueFloors = venue.location.floors;
  const [localFloors, setLocalFloors] = useState<number[]>(venueFloors);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedFloors, setDeletedFloors] = useState<number[]>([]);

  const sortedLocalFloors = localFloors.sort((a, b) => a - b);

  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: onFinishEditing,
  });

  const disableSave = _isEqual(localFloors, venueFloors);

  const handleAddNewFloor = () => {
    setShowModal(true);
  };

  const onSaveAddFloorModal = () => {
    setShowModal(false);
  };
  const onCancelAddFloorModal = () => {
    setLocalFloors(venueFloors);
    setShowModal(false);
  };

  const onCloseDeleteFloorModal = () => {
    setShowDeleteModal(false);
  };

  const onAddFloors = (name: string, floor: number) => {
    setLocalFloors((prevValue) => {
      if (prevValue.includes(floor)) {
        return [...prevValue.filter((i) => i !== floor)];
      }
      return [...prevValue, floor];
    });
  };

  const onDeleteFloors = (floorNumber: number) => {
    const updatedFloors = localFloors.filter((floor) => floor !== floorNumber);
    setLocalFloors(updatedFloors);
  };

  const onSubmit = async ({floorsAdd}: {floorsAdd: number[]}) => {
    const floorsToDelete = _difference(venueFloors, floorsAdd);
    const isDeleteModal = !!floorsToDelete.length;

    if (isDeleteModal && !showDeleteModal) {
      setDeletedFloors(floorsToDelete);
      setShowDeleteModal(true);
    } else {
      await updateVenue({
        ...venue,
        location: {...venue.location, floors: floorsAdd},
      });
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={{floorsAdd: localFloors}}>
      {({handleSubmit}) => {
        return (
          <form id={EDIT_FLOORS_FORM} onSubmit={handleSubmit} noValidate>
            <div>
              {sortedLocalFloors.map((floorNumber) => {
                const hasAnyUnits = venue.floorInformation?.some(
                  (item) => item.roomDetailDto.MEETING_ROOM?.length,
                );

                const floorUnits =
                  venue.floorInformation?.filter(
                    (floorItem) => floorItem.floorNumber === floorNumber,
                  )[0] || EMPTY_OBJECT;

                const allowedDelete = localFloors?.length > 1;

                return (
                  <FloorEditItem
                    allowedDelete={allowedDelete}
                    key={floorNumber}
                    venueId={venue.id}
                    data-testid={`floor-edit-item-${floorNumber}`}
                    hasAnyUnits={hasAnyUnits}
                    onDeleteFloors={onDeleteFloors}
                    floorNumber={floorNumber}
                    units={floorUnits}
                    isExternalVenue={isExternalVenue}
                  />
                );
              })}
            </div>

            <NW2Button
              buttonType='secondary'
              size='small'
              onClick={handleAddNewFloor}
              data-testid='add-floors-button'
            >
              Add new floor
            </NW2Button>
            <HorizontalDivider />

            <AddFloorsModal
              venue={venue}
              localFloors={localFloors}
              isSending={isSending}
              showModal={showModal}
              onAddFloors={onAddFloors}
              onSaveAddFloorModal={onSaveAddFloorModal}
              onCancelAddFloorModal={onCancelAddFloorModal}
            />

            <DeleteModal
              deletedFloors={deletedFloors}
              isSending={isSending}
              onCloseDeleteFloorModal={onCloseDeleteFloorModal}
              showDeleteModal={showDeleteModal}
            />

            <NMMSubmitSection
              isLoading={isSending}
              handleCancel={onFinishEditing}
              disabled={disableSave}
              dataTestCancelId='cancel-edit-floors'
              dataTestSubmitId='save-edit-floors'
            />
          </form>
        );
      }}
    </Form>
  );
};
