import styled, {createGlobalStyle, css} from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  offsetDef,
  fontSizeXSm,
  fontSizeLg,
  fontWeightBold,
  offsetXLg,
  offsetSm,
  offsetXSm,
  xLgBp,
  NW2Primary,
  blackColor,
  borderRadiusDef,
  NW2Gray100Color,
  offsetXXSm,
  NW2Gray200Color,
  NW2Green500Color,
  lineHeightXL,
  mdBp,
  lineHeightSm,
  borderRadiusLg,
  whiteColor,
} from 'constants/styleVars';

const SKELETON_BACKGROUND =
  'linear-gradient(90deg, rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%)';

const SKELETON_CONTENT_MIN_HEIGHT = '70px';

const SKELETON_MIXIN = `
  background: ${SKELETON_BACKGROUND};
  background-size: 500% 100%;
  animation: gradient 2s ease infinite;

  ${css`
    @keyframes gradient {
      0% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
  `}
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCard = styled.div<{
  isSelected?: boolean;
  isCorporateVenue?: boolean;
  isDesktop?: boolean;
  isTablet?: boolean;
  isAlternativesSection?: boolean;
}>`
  display: grid;
  grid-gap: ${offsetDef};
  border-radius: ${borderRadiusDef};

  ${({isCorporateVenue}) =>
    isCorporateVenue &&
    css`
      padding: ${offsetDef};
      background-color: ${NW2Gray100Color};
      border-radius: ${borderRadiusDef};
    `}

  ${({isTablet}) => isTablet && 'min-width: 330px;'};

  ${({isDesktop, isAlternativesSection}) =>
    isDesktop &&
    !isAlternativesSection &&
    'grid-template-columns: minmax(100px, 233px) 1fr;'};

  ${({isSelected}) =>
    isSelected &&
    css`
      background: ${NW2Gray100Color};
      position: relative;

      & > * {
        position: relative;
        z-index: 1;
      }

      &:after {
        position: absolute;
        content: '';
        inset: -8px;
        background-color: ${NW2Gray100Color};
        border-radius: ${borderRadiusDef};
        z-index: 0;
      }
    `};
`;

export const ImageWrapper = styled.div<{showSkeletons: boolean}>`
  width: 100%;
  ${({showSkeletons}) => (showSkeletons ? SKELETON_MIXIN : '')};
`;

export const Content = styled(FlexBox)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const ShortDetails = styled.div<{isVisible?: boolean}>`
  display: ${({isVisible}) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: ${offsetSm};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ShortDetailsItem = styled.div`
  display: flex;
  align-items: center;

  & > span {
    margin-left: ${offsetXSm};
  }
`;

export const RightSide = styled.div<{isDesktop: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    min-width: 148px;
  }

  @media (min-width: ${xLgBp}px) {
    justify-content: flex-end;
  }
`;

export const SkeletonLeftSide = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${offsetDef};
`;

export const SkeletonRow = styled.div`
  width: 100%;

  ${SKELETON_MIXIN}
  &:first-child {
    height: ${SKELETON_CONTENT_MIN_HEIGHT};
  }

  &:last-child {
    display: none;
    flex-grow: 1;
    margin-top: ${offsetXLg};

    @media (min-width: ${xLgBp}px) {
      display: block;
    }
  }
`;

export const SkeletonRightSide = styled.div`
  ${SKELETON_MIXIN};
  height: 100%;
  min-height: ${SKELETON_CONTENT_MIN_HEIGHT};
  width: 115px;
  margin-left: ${offsetDef};
`;

export const VenueName = styled.div<{isSelected?: boolean}>`
  position: relative;
  display: block;
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXSm};
  overflow-wrap: anywhere;
  color: ${blackColor};

  &:hover {
    cursor: pointer;
  }

  > span {
    background-image: linear-gradient(${NW2Primary}, ${NW2Primary});
    background-size: ${({isSelected}) => (isSelected ? '100% 9px' : '0 9px')};
    background-repeat: no-repeat;
    background-position: 25% 100%;
  }
`;

export const SubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-left: ${offsetSm};
  }
`;

export const VenueItemBody = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  align-items: ${({isMobile}) => (isMobile ? 'flex-start' : 'center')};
  gap: ${offsetSm};
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const LeftSide = styled.div<{justifyContent: string}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({justifyContent}) => justifyContent};
  height: 100%;
`;

export const VenueItemHeader = styled.div`
  width: 100%;
`;

export const FlexList = styled(FlexBox)`
  flex-wrap: wrap;

  > * + * {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 2px;
      margin: 0 4px;
      background: ${blackColor};
      border-radius: 50%;
    }
  }
`;

export const AvailabilityTag = styled.div<{
  isVisible?: boolean;
  isTablet?: boolean;
}>`
  display: ${({isVisible}) => (isVisible ? 'inline-flex' : 'none')};
  align-items: center;
  padding: 0 ${offsetXXSm};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusDef};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightBold};
  white-space: nowrap;

  ${({isTablet}) =>
    isTablet &&
    css`
      * + & {
        margin-bottom: ${offsetXXSm};
      }
    `}
  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${NW2Green500Color};
    margin-right: ${offsetXSm};
  }
`;

export const ButtonsContainer = styled.div<{
  isInline?: boolean;
  isFullWidth?: boolean;
  topOffset?: string;
  flexDirection?: 'column' | 'row';
}>`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection || 'column'};
  gap: ${offsetXSm};
  justify-content: flex-end;
  width: ${({isFullWidth}) => isFullWidth && '100%'};
  margin-top: ${({topOffset}) => topOffset};

  ${({isInline}) =>
    isInline &&
    css`
      display: grid;
      grid-template-columns: 1.25fr 1fr;
      align-self: stretch;
      margin-top: ${offsetXSm};
    `};
`;

export const StyledButton = styled(NW2Button)`
  padding-left: ${offsetSm};
  padding-right: ${offsetSm};

  > span {
    white-space: nowrap;
  }
`;

export const CustomPopoverGlobalStyle = createGlobalStyle`
    .custom-popover {
        padding-top: ${offsetXSm};

        .ant-popover {
            &-arrow-content:before {
                background-color: ${blackColor};
            }

            &-inner {
                border-radius: ${borderRadiusLg};
            }

            &-inner-content {
                font-size: ${fontSizeXSm};
                padding: ${offsetXSm};
                color: ${whiteColor};
                background-color: ${blackColor};
            }
        }
    }
`;
