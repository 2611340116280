import React, {useCallback, useEffect, useMemo} from 'react';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button';
import NW2DateSection from './NW2DateSection';
import NW2TimeSection from './NW2TimeSection';

import {
  useMultiDatePicker,
  NW2MultiDatePickerMobile,
  formatDateForPicker,
} from 'view/components/NW2Datepicker';

import {useClickOutside} from 'hooks/useClickOutside';
import useToggle from 'hooks/useToggle';
import {useHeaderCollapseForDatePicker} from './useHeaderCollapseForDatePicker';
import {
  StyledColumnTime,
  StyledDropdown,
  StyledDatepickerActions,
  StyledTimeWrapper,
  StyledDateInput,
} from './NW2SearchDatePicker.styles';
import {StyledTimeTitle} from 'view/components/NW2Datepicker/NW2TimePicker/NW2TimePicker.styles';

interface IProps {
  inputId: string;
  onClickHandler: () => void;
  onFocus: () => void;
  onBlur: () => void;
  isMeetingRoom: boolean;
  isDatepickerInitialised: boolean;
  handleSetDatepickerInitialised: (data: boolean) => void;
}

export const NW2SearchDatePicker = ({
  inputId,
  onClickHandler,
  onFocus,
  onBlur,
  isMeetingRoom,
  isDatepickerInitialised,
  handleSetDatepickerInitialised,
}: IProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isTablet = useAppSelector(({app}) => app.deviceType.isTablet);
  const searchFocusedInput = useAppSelector(({app}) => app.searchFocusedInput);
  const [isOpened, toggleOpened] = useToggle(false);
  const roomTypeString = isMeetingRoom ? 'meet' : 'work';
  const onToggleDatePicker = useCallback(() => {
    if (!isOpened) {
      onFocus();
    } else {
      onBlur();
    }

    onClickHandler();
    toggleOpened();
  }, [isOpened, onBlur, onClickHandler, onFocus, toggleOpened]);

  const {
    startDate,
    endDate,
    timeData,
    onDateChange,
    onTimeStartChange,
    onTimeEndChange,
    onChangeTimeComplete,
    onReset,
  } = useMultiDatePicker({
    isDatepickerInitialised,
    handleSetDatepickerInitialised,
  });

  useHeaderCollapseForDatePicker(isOpened);

  const nextHandler = useCallback(() => {
    onChangeTimeComplete(onToggleDatePicker);
  }, [onChangeTimeComplete, onToggleDatePicker]);

  const [containerRef] = useClickOutside(isOpened, () => {
    if (!isMobile) {
      onToggleDatePicker();
    }
  });

  const inputValue = useMemo(() => {
    const start = timeData[0]?.timeStart;
    const end = timeData[timeData.length - 1]?.timeEnd;

    return formatDateForPicker(start, end);
  }, [timeData]);

  useEffect(() => {
    if (searchFocusedInput === inputId && !isOpened) onToggleDatePicker();
  }, [searchFocusedInput, inputId, onToggleDatePicker, isOpened]);

  return (
    <div ref={containerRef}>
      <StyledDateInput
        type='text'
        value={inputValue}
        id={inputId}
        onClick={onToggleDatePicker}
        readOnly
        data-testid='datepicker open picker'
        minWidth={isMeetingRoom ? '200px' : ''}
      />

      {isMobile ? (
        <NW2MultiDatePickerMobile
          startDate={startDate}
          endDate={endDate}
          timeData={timeData}
          isOpened={isOpened}
          onDateChange={onDateChange}
          onTimeStartChange={onTimeStartChange}
          onTimeEndChange={onTimeEndChange}
          onToggleDatePicker={onToggleDatePicker}
          onNextClick={nextHandler}
          onClearClick={onReset}
          roomTypeString={roomTypeString}
        />
      ) : isOpened ? (
        <StyledDropdown isMeetingRoom={isMeetingRoom}>
          <NW2DateSection
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            monthsShown={isMeetingRoom ? (isTablet ? 1 : 2) : undefined}
          />

          {/*{isColTimeShowed && (*/}
          <StyledTimeWrapper>
            <StyledTimeTitle>{`When do you plan to ${roomTypeString}?`}</StyledTimeTitle>

            <StyledColumnTime>
              <div>
                <NW2TimeSection
                  timeData={timeData}
                  onTimeStartChange={onTimeStartChange}
                  onTimeEndChange={onTimeEndChange}
                />
              </div>

              <StyledDatepickerActions>
                <NW2Button
                  type='button'
                  onClick={onReset}
                  data-testid='datepicker clear'
                >
                  clear
                </NW2Button>

                <NW2Button
                  buttonType='primary'
                  fullWidth={false}
                  onClick={nextHandler}
                  data-testid='datepicker next'
                >
                  apply
                </NW2Button>
              </StyledDatepickerActions>
            </StyledColumnTime>
          </StyledTimeWrapper>
          {/*)}*/}
        </StyledDropdown>
      ) : null}
    </div>
  );
};
