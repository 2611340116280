import {useMemo} from 'react';

import LocalStorageService from 'infra/common/localStorage.service';
import {TBedroomsCatering} from '../../../components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddAccommodationRequest/types';

export function useBedroomsCatering() {
  const storageBedroomsCatering =
    LocalStorageService.getByKey('bedroomsCatering');

  const bedroomsCatering: TBedroomsCatering = useMemo(
    () => (storageBedroomsCatering ? JSON.parse(storageBedroomsCatering) : {}),
    [storageBedroomsCatering],
  );

  return [bedroomsCatering];
}
