import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {setHeaderCollapsed, setHeaderFixed} from 'store/app/appSlice';
import {WINDOW_HEIGHT_FOR_HEADER_COLLAPSE} from 'constants/app';

export const useHeaderCollapseForDatePicker = (isOpened: boolean) => {
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const windowHeight = useAppSelector(({app}) => app.deviceType.height) || 0;

  useEffect(() => {
    if (!isMobile && isOpened) {
      const shouldCollapse = windowHeight < WINDOW_HEIGHT_FOR_HEADER_COLLAPSE;
      dispatch(setHeaderCollapsed(shouldCollapse));
      dispatch(setHeaderFixed(shouldCollapse));
    }
  }, [windowHeight, dispatch, isOpened, isMobile]);
};
