import React from 'react';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon';

import {offsetXLg, offsetXXLg} from 'constants/styleVars';
import {useGoBackToStoredSearch} from 'view/venue/hooks/search/useGoBackToStoredSearch';

const NWLinkContainer = styled.div`
  position: absolute;
  top: ${offsetXLg};
  left: ${offsetXXLg};
  z-index: 1;
`;

export function GoBackLink() {
  const [onGoToSearchResults] = useGoBackToStoredSearch();

  return (
    <NWLinkContainer>
      <NW2Button
        minimized
        inverted
        buttonType='primary'
        size='small'
        onClick={onGoToSearchResults}
        icon={<Icon transparent icon='NW2_MOVE_BACK' />}
      />
    </NWLinkContainer>
  );
}
