import {useEffect, useState} from 'react';

import {pushToGoogleAnalytics} from 'utils/helpers';

interface IProps {
  currentStep: number;
}
export const useCreationVenueMetrics = ({currentStep}: IProps) => {
  const [maxSteps, setMaxSteps] = useState(0);

  useEffect(() => {
    if (currentStep > maxSteps) {
      setMaxSteps(currentStep);
      pushToGoogleAnalytics(`venue_creation_step_${currentStep}`);
    }
  }, [currentStep, maxSteps]);

  useEffect(() => {
    pushToGoogleAnalytics(`venue_creation_initial_step`);
  }, []);
};
