import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import SidebarMenu from 'view/components/SupplierLayout/components/SidebarMenu';

import {
  bookingTabsToBookingTypes,
  EBookingTabNames,
  IBookingType,
} from 'types/dto/IBookingType.type';
import {useActions} from 'hooks/useActions';
import {Routes} from 'constants/routes';
import {LEFT_MENU_WIDTH} from '../BookingsList/helpers';

const Container = styled.div`
  width: ${LEFT_MENU_WIDTH};
`;

const sections = [
  {
    title: EBookingTabNames.TODAY,
    href: EBookingTabNames.TODAY.toLowerCase(),
    isShown: true,
  },
  {
    title: EBookingTabNames.TOMORROW,
    href: EBookingTabNames.TOMORROW.toLowerCase(),
    isShown: true,
  },
  {
    title: EBookingTabNames.UPCOMING,
    href: EBookingTabNames.UPCOMING.toLowerCase(),
    isShown: true,
  },
  {
    title: EBookingTabNames.HISTORY,
    href: EBookingTabNames.HISTORY.toLowerCase(),
    isShown: true,
  },
];

function BookingsLeftMenu() {
  const {setActiveBookingsTab} = useActions();
  const navigate = useNavigate();

  const [initialActiveSection, setInitialActiveSection] = useState(
    EBookingTabNames.TODAY,
  );

  //sets workdesk section and Today tab on page refresh
  useEffect(() => {
    setActiveBookingsTab(IBookingType.ARRIVING_TODAY);
    navigate({
      pathname: Routes.bookingsListDesks,
      hash: EBookingTabNames.TODAY.toLowerCase(),
    });
  }, [navigate, setActiveBookingsTab]);

  const handleSectionChange = useCallback(
    (sectionName: EBookingTabNames) => {
      setInitialActiveSection(sectionName);

      setActiveBookingsTab(bookingTabsToBookingTypes[sectionName]);

      navigate({
        pathname: Routes.bookingsListDesks,
        hash: sectionName.toLowerCase(),
      });
    },
    [navigate, setActiveBookingsTab],
  );

  return (
    <Container>
      <SidebarMenu
        activeSection={initialActiveSection}
        sections={sections}
        handleSectionChange={handleSectionChange as (arg: string) => void}
        title='contents'
      />
    </Container>
  );
}

export default BookingsLeftMenu;
