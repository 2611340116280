import React from 'react';
import {Table} from 'antd';
import styled from 'styled-components';

import {TablePaginationConfig} from 'antd/es';
import {
  ColumnsType,
  SorterResult,
  TableRowSelection,
} from 'antd/es/table/interface';
import {borderRadiusDef, lgBp} from 'constants/styleVars';
import {FilterValue} from 'antd/lib/table/interface';
import Icon from '../Icon';

const PaginationShowTotal = styled.div`
  display: none;

  @media (min-width: ${lgBp}px) {
    display: block;
  }
`;

const StyledTable = styled((props: any) => <Table {...props} />)`
  .ant-pagination-total-text {
    margin-right: auto;
  }

  .ant-pagination li,
  .ant-pagination button {
    border-radius: ${borderRadiusDef};
  }
  .ant-table-selection-column > * {
    display: none;
  }
  .ant-table-selection-col {
    width: 0 !important;
  }
  .show-status {
    width: 55px !important;
  }
`;

type TProps<TableData = unknown> = {
  tableData: TableData[];
  columns: ColumnsType<TableData>;
  pagination?: TablePaginationConfig;
  isLoading: boolean;
  isPagination: boolean;
  handleTableChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Record<string, string | any[]>>,
  ) => void;
  rowKey: string;
  tableEntityText?: string;
  rowSelection?: TableRowSelection<TableData>;
};

function NWTable<TableData>({
  tableData,
  columns,
  pagination,
  isLoading,
  isPagination,
  handleTableChange,
  rowKey,
  tableEntityText = '',
  rowSelection,
}: TProps<TableData>) {
  const tablePaginationProps = {
    ...pagination,
    showSizeChanger: pagination && pagination.total && pagination.total > 10,
    responsive: true,
    showTotal: (total: number, range: [number, number]) => (
      <PaginationShowTotal>
        Showing{' '}
        <b>
          {range[0]}-{range[1]}
        </b>{' '}
        out of total {total} {tableEntityText}
      </PaginationShowTotal>
    ),
  };

  const tableProps = {
    rowKey,
    columns,
    onChange: handleTableChange,
    loading: isLoading,
    scroll: {x: 500},
    dataSource: tableData,
    pagination: !isPagination ? isPagination : tablePaginationProps,
    rowSelection,
  };

  return (
    <StyledTable
      locale={{
        emptyText: (
          <>
            <Icon icon='DOCUMENT' disabledColor />
            <div>No bookings</div>
          </>
        ),
      }}
      {...tableProps}
    />
  );
}

export default NWTable;
